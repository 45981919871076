import type { ChakraProps } from "@chakra-ui/react";
import { Circle, Stack, Text, Tooltip, useDisclosure, useToast } from "@chakra-ui/react";
import type { Copy } from "api/copies/models/Copy";
import { formatDistanceToNow } from "date-fns";
import { useAppDispatch, useEntitlementKey } from "hooks";
import { useCopies, useCopiesDictionary, useIsLoadingCopies } from "hooks/useCopies";
import React, { useMemo, useState } from "react";
import { AdminTiles } from "screens/common/components";
import { ConfirmDeleteModal } from "screens/common/components/ConfirmDeleteModal";
import { removeCopy, upsertCopy } from "state/copies/operations";
import { CopiesKeys, defaultCopiesValues, isDefinedCopyKey } from "types/copy";
import type { UpsertCopyForm } from "./components/UpsertCopy";
import { UpsertCopyModal } from "./components/UpsertCopy";

export type CopyWithAdditionalProps = Copy & { isMissing?: boolean };

export const CopiesTab = () => {
  const dispatch = useAppDispatch();
  const isLoading = useIsLoadingCopies();
  const copies = useCopies();
  const copiesDictionary = useCopiesDictionary();
  const [selectedCopy, setSelectedCopy] = useState<CopyWithAdditionalProps | null>(null);
  const { isOpen: isOpenUpsert, onClose: onCloseUpsert, onOpen: onOpenUpsert } = useDisclosure();
  const { isOpen: isOpenDelete, onClose: onCloseDelete, onOpen: onOpenDelete } = useDisclosure();
  const toast = useToast();
  const hasCopiesWrite = useEntitlementKey("manage_copies_write");

  const handleUpsert = async (values: UpsertCopyForm) => {
    const response = await dispatch(
      upsertCopy({
        key: `copy_${values.key}`,
        value: values.type === "string" ? values.stringValue ?? "" : (values.listValue || []).map((v) => v.value),
      })
    );

    if (response.type === upsertCopy.rejected.type) {
      toast({
        title: "Failed to save copy",
        status: "error",
      });
    } else {
      toast({
        title: "Copy saved successfully",
        status: "success",
      });

      onCloseUpsert();
    }
  };

  const handleDelete = async () => {
    if (!selectedCopy) return;

    const response = await dispatch(removeCopy(selectedCopy.key));

    if (response.type === removeCopy.rejected.type) {
      toast({
        title: "Failed to delete copy",
        status: "error",
      });
    } else {
      toast({
        title: "Copy deleted successfully",
        status: "success",
      });

      setSelectedCopy(null);
      onCloseDelete();
    }
  };

  const localAndExistingCopies = useMemo(() => {
    const copiesToCreate = CopiesKeys.flatMap((key) => {
      if (!copiesDictionary[key]) {
        return [
          {
            isMissing: true,
            key,
            value: defaultCopiesValues[key],
            createdAt: new Date().toISOString(),
            createdByUser: "N/A",
          },
        ];
      } else {
        return [];
      }
    });

    return [...copiesToCreate, ...copies];
  }, [copies, copiesDictionary]);

  return (
    <>
      <UpsertCopyModal
        isLoading={isLoading}
        initialValue={selectedCopy ?? undefined}
        isOpen={isOpenUpsert}
        onClose={() => {
          setSelectedCopy(null);
          onCloseUpsert();
        }}
        onSubmit={handleUpsert}
      />

      <ConfirmDeleteModal
        isLoading={isLoading}
        isOpen={isOpenDelete}
        title="Delete Copy"
        body={`Are you sure you want to delete the copy "${selectedCopy?.key}"?`}
        onClose={() => {
          setSelectedCopy(null);
          onCloseDelete();
        }}
        onConfirm={handleDelete}
      />

      <AdminTiles<CopyWithAdditionalProps & { lastUpdatedDate?: Date; lastUpdatedByUserName?: string }>
        items={localAndExistingCopies}
        onClickHelp={() => {
          const url = "https://charliai.atlassian.net/wiki/spaces/CHARLIAI/pages/2146533377/Delivery+Copy+Definitions";
          window.open(url, "_blank");
        }}
        tileTitle="Copy"
        tileTitleKey="key"
        showKeyInList={false}
        keyName="key"
        fieldsToRender={[]}
        filterByFields={["key"]}
        defaultOrderBy={"asc"}
        customFields={[
          {
            field: "Key",
            value(row) {
              const labelAndColor: {
                label: string;
                color: ChakraProps["bg"];
              } = row.isMissing
                ? {
                    label: "Used in webapp but missing",
                    color: "red.500",
                  }
                : !isDefinedCopyKey(row.key)
                ? {
                    label: "Not used in webapp",
                    color: "orange.300",
                  }
                : {
                    label: "Used in webapp",
                    color: "green.500",
                  };

              return (
                <Stack direction="row">
                  <Tooltip label={labelAndColor.label} placement="top">
                    <Circle mt="7px" size="7px" bg={labelAndColor.color} />
                  </Tooltip>
                  <Text maxWidth={"17rem"}>{row.key}</Text>
                </Stack>
              );
            },
          },
          {
            field: "Value",
            value(row) {
              return typeof row.value === "string" ? row.value : row.value.join("\n");
            },
          },
          {
            field: "Updated at",
            value(row) {
              return row.updatedAt
                ? formatDistanceToNow(new Date(row.updatedAt), {
                    addSuffix: true,
                    includeSeconds: true,
                  })
                : "N/A";
            },
          },
          {
            field: "Updated by",
            value(row) {
              return row.updatedByUser || "N/A";
            },
          },
        ]}
        hasRead
        hasWrite={hasCopiesWrite}
        showViewType={false}
        viewType="table"
        isLoading={isLoading}
        showUpdatedInfo={false}
        onClickDelete={(key) => {
          if (isDefinedCopyKey(key)) {
            toast({
              title: "Cannot delete a copy that is used in the webapp",
              status: "error",
            });
          } else {
            const copy = localAndExistingCopies.find((c) => c.key === key);
            if (copy) {
              setSelectedCopy(copy);
              onOpenDelete();
            }
          }
        }}
        onClickEdit={(id) => {
          const copy = localAndExistingCopies.find((c) => c.key === id);
          if (copy) {
            setSelectedCopy(copy);
            onOpenUpsert();
          }
        }}
      />
    </>
  );
};
