import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCollections, getCollectionsQuery } from "api/collection/collection";
import { actions as contentActions } from "state/content/reducer";
import { actions as contextCoordinatesActions } from "state/contextCoordinates/reducer";
import type { RootState } from "state/rootReducer";
import { actions as workflowActions } from "state/workflow/reducer";
import { requestToDownloadWorkflows } from "state/workflow/utils";
import type { ContextCoordinatesDetail } from "types/contextCoordinates";

export const downloadCollections = createAsyncThunk(
  "collections/download-all",
  async (
    { ids, downloadCollectionWorkflows = false }: { ids: string[]; downloadCollectionWorkflows?: boolean; isDetailView?: boolean },
    { dispatch, getState }
  ) => {
    if (ids.length === 0) {
      return [];
    }

    const collections = await getCollections(ids);
    const existingCollectionsSet = collections.reduce((acc: Set<string>, collection) => {
      acc.add(collection.id);
      return acc;
    }, new Set<string>());

    const allAnswersContextCoordinates = collections.reduce((acc: Record<string, ContextCoordinatesDetail[]>, collection) => {
      const questions = collection.questions;

      (questions || []).forEach(({ answers }) => {
        answers.forEach((answer) => {
          acc[answer.id] = answer.contextCoordinatesList ?? [];
        });
      });

      return acc;
    }, {});

    dispatch(contextCoordinatesActions.setCoordinates(allAnswersContextCoordinates));

    ids.forEach((id) => {
      if (!existingCollectionsSet.has(id)) {
        dispatch(removeCollection({ id }));
      }
    });

    if (downloadCollectionWorkflows) {
      const state = getState() as RootState;
      const workflowsIds = collections.flatMap((collection) => (collection.workflowIds ? collection.workflowIds : []));

      requestToDownloadWorkflows(workflowsIds, state.workflow.isLoadingWorkflowMap, dispatch);
    }

    return collections;
  }
);

export const downloadCollectionsByType = createAsyncThunk(
  "collections/download-by-type",
  async ({ collectionType, limit = 100 }: { collectionType: string; limit?: number }, thunkAPI) => {
    const recentCollections = await getCollectionsQuery({ collectionType, limit });

    thunkAPI.dispatch(downloadCollections({ ids: recentCollections.metadataIds, downloadCollectionWorkflows: true }));

    return recentCollections;
  }
);

export const downloadInitialCollections = createAsyncThunk(
  "collections/download-initial-collections",
  async ({ homeLimit = 10, portfolioLimit = 50 }: { limit?: number; homeLimit?: number; portfolioLimit?: number }, thunkAPI) => {
    const [
      recentDDProjects,
      recentRecordManagementProjects,
      recentDataEthicsProjects,
      recentResearchProjects,
      recentPolicyProjects,
      recentPortfolioProjects,
    ] = await Promise.all([
      getCollectionsQuery({ collectionType: "due_diligence", limit: homeLimit }),
      getCollectionsQuery({ collectionType: "records_management", limit: homeLimit }),
      getCollectionsQuery({ collectionType: "data_ethics", limit: homeLimit }),
      getCollectionsQuery({ collectionType: "research", limit: homeLimit }),
      getCollectionsQuery({ collectionType: "policy", limit: homeLimit }),
      getCollectionsQuery({ collectionType: "portfolio", limit: portfolioLimit }),
    ]);

    const recentMetadataIds = [
      ...recentDDProjects.metadataIds,
      ...recentRecordManagementProjects.metadataIds,
      ...recentDataEthicsProjects.metadataIds,
      ...recentResearchProjects.metadataIds,
      ...recentPolicyProjects.metadataIds,
      ...recentPortfolioProjects.metadataIds,
    ];

    const collections = (thunkAPI.getState() as RootState).collection.collections;
    const collectionsToFetch = recentMetadataIds.filter((id) => !collections[id]);

    thunkAPI.dispatch(downloadCollections({ ids: collectionsToFetch, downloadCollectionWorkflows: true }));
  }
);

export const removeCollection = createAsyncThunk("collections/remove", async ({ id }: { id: string }, thunkAPI) => {
  const collection = (thunkAPI.getState() as RootState).collection.collections[id];

  if (collection && collection.workflowIds) {
    collection.workflowIds.forEach((workflowId) => {
      thunkAPI.dispatch(workflowActions.removeWorkflow(workflowId));
    });

    collection.metadataIds.forEach((metadataId) => {
      thunkAPI.dispatch(contentActions.removeContent(metadataId));
    });
  }

  return id;
});
