import "./wdyr";
import { ChakraProvider } from "@chakra-ui/react";
import type analytics from "@segment/analytics.js-core";
import { Analytics } from "api/analytics/reactWrapper";
import React, { useEffect, useMemo } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Logout } from "screens/common/components";
import { PrivateRoute } from "routes/PrivateRoute";
import { Conversation } from "screens/conversation/Conversation";
import { ErrorPage } from "screens/error/ErrorPage";
import { Login } from "screens/login/Login";
import { Verify } from "screens/login/Verify";
import { useDispatch } from "react-redux";
import { downloadFlags } from "state/flags/operations";
import { MaintenanceGate } from "screens/maintenance/Maintenance";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ResetPassword } from "./screens/login/ResetPassword";
import { AstrellaLogin } from "./screens/login/AstrellaLogin";
import { useCustomTheme, useEntitlements, useFeatureFlags } from "hooks";
import { AppContainer } from "screens/common/app";
import { useVersionTile } from "hooks/useVersionTile";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Activities, AdminTabs, Projects, IntegrationsTab, Notifications } from "screens/landing/tabs";
import { ContentCanvas, ContentView } from "screens/content";
import { MarkdownScreen } from "screens/content/contentCanvas/MarkdownScreen";
import { JsonScreen } from "screens/content/contentCanvas/JsonScreen";
import { TaskJsonPayload } from "screens/content/contentCanvas/TaskJsonPayload";
import { ProjectDetailView } from "screens/collection";
import { ResourceLibrary } from "screens/landing/tabs/library/ResourceLibrary";
import { OrganizationAdminWrapper } from "screens/landing/tabs/organization";
import type { AdminPath } from "types/admin";
import { WorkflowStepErrorDetails } from "screens/content/contentCanvas/WorkflowStepErrorDetails";
import { WorkflowContextProvider } from "screens/thread/WorkflowContextProvider";
import { WorkflowPanelAdmin } from "screens/thread/components/WorkflowPanelAdmin";
import { OperationsTabs } from "screens/landing/tabs/operations";
import { WorkflowOperations } from "screens/landing/tabs/operations/tabs/WorkflowOperations";
import { StepsPanel } from "screens/common/components/WorkflowSummary/v2/StepsPanel";
import { WorkflowPayloadPanel } from "screens/thread/components/WorkflowPayloadPanel";
import { Home } from "screens/landing/tabs/dashboard/Home";
import { DeliveryDashboard } from "screens/landing/tabs/delivery/DeliveryDashboard";
import { adminTabsEntitlementsMapping } from "screens/landing/tabs/admin/adminTabsEntitlementsMapping";
import { ProjectContentPDF } from "screens/collection/ProjectContentPDF";

function setDocHeight(): void {
  document.documentElement.style.setProperty("--vh", `${window.innerHeight / 100}px`);
}

window.addEventListener("resize", setDocHeight);
window.addEventListener("orientationchange", setDocHeight);
setDocHeight();

declare global {
  interface Window {
    env: {
      proxyBaseUrl: string;
      environment?: string; // the gitlab environment
      version?: string; // the git sha of this build
      googleAppId: string; // App/project ID, used to authenticate with Google Picker's API
      googleClientId: string; // Google Sign In client ID
      stripePublicKey: string; // Stripe payments public key
      frontendSentryDsn: string; // Sentry config
      astrellaTokenExchangeClient: string; // Astrella Token Exchange Client ID
    };
    analytics: typeof analytics;
  }
}

if (window.env.frontendSentryDsn) {
  console.log(`Initialising Sentry with environment [${window.env.environment ?? "other"}] and release [${window.env.version}]`);

  Sentry.init({
    dsn: window.env.frontendSentryDsn,
    environment: window.env.environment ?? "other",
    release: window.env.version,
    integrations: [new Integrations.BrowserTracing()],
    ignoreErrors: ["ResizeObserver loop limit exceeded"],
    denyUrls: [/apis\.google\.com/i, /instantfox\.co/i, /segment\.com/i, /hotjar\.com/i, /userpilot\.io/i],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });

  console.log(`Initialised Sentry successfully`);
}

const RoutesWrapper = () => {
  const { manage_operations_read: hasReadWorkflowsOperations } = useEntitlements();
  const allEntitlements = useEntitlements();
  const { ui_enable_organization_admin_page: isOrganizationAdminPageEnabled } = useEntitlements();
  const { workflow_summary_2: hasWorkflowSummaryV2 } = useFeatureFlags();

  const indexPath: AdminPath | undefined = useMemo(() => {
    return adminTabsEntitlementsMapping.find(({ entitlement }) => allEntitlements[entitlement])?.path;
  }, [allEntitlements]);

  const { routes: operationsRoutes } = useMemo(() => {
    const routes: { element: JSX.Element; path: AdminPath }[] = [];

    if (hasReadWorkflowsOperations) {
      routes.push({ path: "workflows", element: <WorkflowOperations /> });
    }

    return { routes };
  }, [hasReadWorkflowsOperations]);

  const operationsPath: AdminPath | undefined = useMemo(() => {
    if (hasReadWorkflowsOperations) {
      return "workflows";
    } else {
      return undefined;
    }
  }, [hasReadWorkflowsOperations]);

  return (
    <Analytics>
      <Routes>
        <Route path="/login/*" element={<Login />} />
        <Route path="/register" element={<Login />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route
          path="delivery"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<DeliveryDashboard />} />
        </Route>
        {/* Projects Home Page */}
        <Route
          path="home"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<Home />} />
        </Route>
        <>
          <Route
            path="dashboard"
            element={
              <PrivateRoute>
                <AppContainer />
              </PrivateRoute>
            }>
            <Route index element={<Home />} />
          </Route>
          <Route
            path="library"
            element={
              <PrivateRoute>
                <AppContainer />
              </PrivateRoute>
            }>
            <Route index element={<ResourceLibrary />} />
            <Route path=":conversationId" element={<ProjectDetailView />} />
            <Route path=":conversationId/:contentId" element={<ProjectDetailView />} />
            <Route path="resource/:contentId" element={<ContentView />} />
          </Route>
        </>

        <Route
          path="portfolios/:projectFilter"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<Projects />} />
        </Route>
        <Route
          path="portfolios/:projectFilter/:projectId/panel"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<Projects />} />
        </Route>
        <Route
          path="portfolios/:projectFilter/:projectId"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<ProjectDetailView />} />
        </Route>
        <Route
          path="portfolios/:projectFilter/:projectId/:contentId"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<ProjectDetailView />} />
        </Route>

        <Route
          path="projects/:projectFilter"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<Projects />} />
        </Route>
        <Route
          path="projects/:projectFilter/:projectId/panel"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<Projects />} />
        </Route>
        <Route
          path="projects/:projectFilter/:projectId"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<ProjectDetailView />} />
        </Route>
        <Route
          path="projects/:projectFilter/:projectId/:contentId"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<ProjectDetailView />} />
        </Route>

        {/* Search */}
        <Route
          path="search/:conversationId"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<ContentCanvas />} />
        </Route>
        <Route
          path="search/:conversationId/:contentId"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<ContentCanvas />} />
        </Route>
        {/* Canvas */}
        <Route
          path="canvas/:conversationId"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<ContentCanvas />} />
        </Route>
        <Route
          path="canvas/:conversationId/:contentId"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<ContentCanvas />} />
        </Route>

        <Route
          path="integrations"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<IntegrationsTab />} />
        </Route>
        <Route
          path="integrations/:category"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<IntegrationsTab />} />
        </Route>
        {/* Markdown */}
        <Route
          path="markdown/:conversationId/:buttonId"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<MarkdownScreen />} />
        </Route>
        <Route
          path="json/:conversationId/:buttonId"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<JsonScreen />} />
        </Route>
        <Route
          path="tasks/:taskId/payloads"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<TaskJsonPayload />} />
        </Route>
        <Route
          path="workflows/:workflowId/childworkflows/:childWorkflowId/tasks/:taskId/error"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<WorkflowStepErrorDetails />} />
        </Route>
        <Route
          path="/answer/:answerId/content/:contentId/pdf"
          element={
            <PrivateRoute>
              <ProjectContentPDF />
            </PrivateRoute>
          }
        />

        <Route
          path="activities/:activityType"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<Activities />} />
        </Route>
        <Route
          path="notifications"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<Notifications />} />
        </Route>
        <Route
          path="meeting-projects"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<Projects />} />
        </Route>
        <Route
          path="operations"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route element={<OperationsTabs />}>
            {operationsPath && <Route index element={<Navigate to={operationsPath} />} />}
            {operationsRoutes.map(({ element, path }) => {
              if (path === "workflows") {
                return (
                  <Route key={path} path={path} element={<WorkflowContextProvider>{element}</WorkflowContextProvider>}>
                    <Route
                      path=":workflowId"
                      element={
                        <>
                          <WorkflowPanelAdmin />
                          <WorkflowPayloadPanel />
                        </>
                      }
                    />
                  </Route>
                );
              } else {
                return <Route key={path} path={path} element={element} />;
              }
            })}
          </Route>
        </Route>
        <Route
          path="admin"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route element={<AdminTabs />}>
            {indexPath && <Route index element={<Navigate to={indexPath} />} />}
            {adminTabsEntitlementsMapping.map(({ view: element, entitlement, path }) => {
              if (!allEntitlements[entitlement]) {
                return <React.Fragment key={path} />;
              } else if (path === "workflows") {
                return (
                  <Route key={path} path={path} element={<WorkflowContextProvider>{element}</WorkflowContextProvider>}>
                    <Route
                      path=":workflowId"
                      element={
                        <>
                          <WorkflowPanelAdmin />
                          {hasWorkflowSummaryV2 ? <StepsPanel /> : <WorkflowPayloadPanel />}
                        </>
                      }
                    />
                  </Route>
                );
              } else if (path === "tasks-log") {
                return (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <WorkflowContextProvider>
                        {element}
                        <WorkflowPayloadPanel />
                      </WorkflowContextProvider>
                    }>
                    <Route
                      path=":workflowId"
                      element={
                        <>
                          <WorkflowPanelAdmin />
                          <WorkflowPayloadPanel />
                        </>
                      }
                    />
                  </Route>
                );
              } else {
                return <Route key={path} path={path} element={element} />;
              }
            })}
          </Route>
        </Route>
        {isOrganizationAdminPageEnabled && (
          <Route
            path="organization/:id"
            element={
              <PrivateRoute>
                <AppContainer />
              </PrivateRoute>
            }>
            <Route index element={<OrganizationAdminWrapper />} />
          </Route>
        )}
        <Route
          path="verify"
          element={
            <PrivateRoute>
              <Verify viewType="full" />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <AppContainer />
            </PrivateRoute>
          }>
          <Route index element={<Home />} />
          <Route path="conversation/:slug" element={<Conversation />} />
          <Route path="logout" element={<Logout />} />
        </Route>
        <Route
          path="/no-project"
          element={
            <ErrorPage
              title="No Project Found"
              message="We can't seem to find any projects for this user to use as a landing page."
              redirectTo={{ path: "/logout", buttonText: "Logout" }}
            />
          }
        />
        <Route path="/login/astrella" element={<AstrellaLogin />} />
        <Route path="*" element={<ErrorPage title="Page Not Found" message="We can't seem to find the page you're looking for." />} />
      </Routes>
    </Analytics>
  );
};

const VersionTileWrapper = () => {
  useVersionTile();

  return (
    <MaintenanceGate>
      <GoogleOAuthProvider clientId={window.env.googleClientId}>
        <BrowserRouter>
          <RoutesWrapper />
        </BrowserRouter>
      </GoogleOAuthProvider>
    </MaintenanceGate>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function App() {
  // Always load feature flags on startup
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(downloadFlags());
    // Poll for new flags every 2 minutes
    const flagInterval = setInterval(() => {
      dispatch(downloadFlags());
    }, 120000);
    return () => clearInterval(flagInterval);
  }, [dispatch]);

  const { theme } = useCustomTheme();

  return (
    <ChakraProvider theme={theme}>
      <VersionTileWrapper />
    </ChakraProvider>
  );
}

export default App;
