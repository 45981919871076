import { Button, VStack } from "@chakra-ui/react";
import { useLinkActionProps } from "hooks";
import { motion } from "framer-motion";
import React, { useCallback, memo, useMemo } from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useConversationDialogSubmitFunctions } from "screens/thread/useConversationDialogSubmitFunctions";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";

type AnswerLength = "concise" | "detailed" | undefined;

interface Props {
  question?: string;
  focus?: string;
  size?: string;
}

const MotionVStack = motion(VStack);
const MotionButton = motion(Button);

const buttonVariants = {
  hover: { scale: 1.1 },
};

const ProjectQuestionHotlinks: React.FC<Props> = memo(({ question, focus, size = "sm" }) => {
  const commonLinkActionProps = useLinkActionProps(size);
  const { setInitialQuestionText, setInitialQuestionFocus } = useConversationContext();
  const { isVerifiedAIPanelOpen, onVerifiedAIPanelClose } = useAddToCharliContext();

  const handleSetQuestionText = useCallback(() => {
    setInitialQuestionText(question || "");
    setInitialQuestionFocus(focus === "analytical" ? 0 : 1);
    requestAnimationFrame(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
    if (isVerifiedAIPanelOpen) {
      setTimeout(() => {
        onVerifiedAIPanelClose();
      }, 150);
    }
  }, [isVerifiedAIPanelOpen, setInitialQuestionText, question, focus, setInitialQuestionFocus, onVerifiedAIPanelClose]);

  const questionFocusAlternate = useMemo(() => {
    return focus === "analytical" ? "sentiment" : "analytical";
  }, [focus]);

  const { onSubmitGenerateAnswer } = useConversationDialogSubmitFunctions({});

  const handleGenerateAlternateAnswer = useCallback(
    (answerFocus?: string, answerLength?: AnswerLength) => {
      if (question) {
        onSubmitGenerateAnswer(question, answerFocus, answerLength);
      }
    },
    [question, onSubmitGenerateAnswer]
  );

  const handleConciseAnswer = useCallback(() => {
    focus && handleGenerateAlternateAnswer(focus, "concise");
  }, [focus, handleGenerateAlternateAnswer]);

  const handleDetailedAnswer = useCallback(() => {
    focus && handleGenerateAlternateAnswer(focus, "detailed");
  }, [focus, handleGenerateAlternateAnswer]);

  const handleAlternateAnswer = useCallback(() => {
    handleGenerateAlternateAnswer(questionFocusAlternate);
  }, [handleGenerateAlternateAnswer, questionFocusAlternate]);

  const motionVStackProps = useMemo(
    () => ({
      alignItems: "flex-start" as const,
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      transition: { duration: 0.5, staggerChildren: 0.5, delayChildren: 0.5 },
    }),
    []
  );

  return (
    <MotionVStack {...motionVStackProps}>
      <MotionButton {...commonLinkActionProps} variants={buttonVariants} whileHover="hover" onMouseDown={handleSetQuestionText}>
        Let me edit and re-ask this question
      </MotionButton>
      <MotionButton {...commonLinkActionProps} variants={buttonVariants} whileHover="hover" onMouseDown={handleConciseAnswer}>
        Generate a more concise answer
      </MotionButton>
      <MotionButton {...commonLinkActionProps} variants={buttonVariants} whileHover="hover" onMouseDown={handleDetailedAnswer}>
        Generate a more detailed answer
      </MotionButton>
      <MotionButton {...commonLinkActionProps} variants={buttonVariants} whileHover="hover" onMouseDown={handleAlternateAnswer}>
        {`Give me the ${questionFocusAlternate} answer to this question`}
      </MotionButton>
    </MotionVStack>
  );
});

ProjectQuestionHotlinks.displayName = "ProjectQuestionHotlinks";

export { ProjectQuestionHotlinks };
