import React, { useCallback, useContext, useMemo, useRef } from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useCollectionKey, useConversation, useEntitlements, usePortfolioCollections, useProjectParams } from "hooks";
import { useDispatch } from "react-redux";
import type { RequestEntities } from "types/charliui";
import { sendMessage } from "state/websocket/operations";
import { useFeatureUsage } from "hooks/useFeatureUsage";
import { Intent } from "types/intent";
import { v4 as uuid } from "uuid";
import onSubmitDueDiligence from "screens/collection/components/useSubmitDueDiligence";
import { ToastMessageContent } from "screens/common/components";
import { useBreakpointValue, useToast } from "@chakra-ui/react";
import { InputBarDropzoneContext } from "screens/conversation/components/InputBar/InputBarDropzoneContext";
import { useNavigate } from "react-router-dom";
import { useConversationDialogUtils } from "./useConversationDialogUtils";
import type { TickerType } from "api/tickers/models/TickerType";

interface Props {
  questionText?: string;
  selectedFocus?: string;
  afterSubmit?: () => void;
  conversationId?: string;
}

const createToastContent = (message: string, onClose: () => void, onClick?: () => void) => (
  <ToastMessageContent message={message} onClick={onClick} onClose={onClose} />
);

export const useConversationDialogSubmitFunctions = ({
  questionText,
  selectedFocus,
  afterSubmit,
  conversationId: conversationIdProp,
}: Props) => {
  const { projectId, projectFilter, isPortfolios, parentRoute } = useProjectParams();
  const { onConversationOpen, isConversationOpen, onSubscriptionModalOpen, conversationId, isOnboardingModalOpen } =
    useConversationContext();
  const projectConversationId = useCollectionKey(projectId, "conversationId") || uuid();
  const currentConversationID = useMemo(
    () => conversationIdProp || projectConversationId || conversationId,
    [conversationIdProp, projectConversationId, conversationId]
  );
  const conversation = useConversation(currentConversationID);
  const { files, resetFiles } = useContext(InputBarDropzoneContext);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  const portfolioCollections = usePortfolioCollections();
  const portfolioId = useMemo(() => {
    if (parentRoute === "home" && portfolioCollections.length === 1) return portfolioCollections[0]?.id;
    if (projectFilter && isPortfolios) return projectFilter;
    return undefined;
  }, [parentRoute, portfolioCollections, projectFilter, isPortfolios]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const projectUsage = useFeatureUsage(Intent.createDueDiligenceProject);
  const projectQuestionUsage = useFeatureUsage(Intent.generateAnswer);
  const { hasExceededMaxProjects, maxAllowedProjects } = useConversationDialogUtils();
  const { bypass_usage_limits: canBypassUsageLimits } = useEntitlements();

  const projectIdRef = useRef(projectId);
  projectIdRef.current = projectId;

  const onSubmitDefaultRequest = useCallback(
    (questionText: string) => {
      if (!selectedFocus || !currentConversationID) return;
      const entities: RequestEntities = projectIdRef.current ? [{ entity: "collection_id", value: projectIdRef.current }] : [];

      dispatch(
        sendMessage({
          conversationId: currentConversationID,
          intent: `${questionText}`,
          entities: [...entities],
          files: files,
        })
      );

      if (files.length) resetFiles();
      !isMobile && onConversationOpen(currentConversationID);
    },
    [selectedFocus, currentConversationID, dispatch, files, resetFiles, isMobile, onConversationOpen]
  );

  const onSubmitGenerateAnswer = useCallback(
    (questionText: string, selectedFocus?: string, answerLength?: string) => {
      if (!questionText || !projectIdRef.current || !selectedFocus || !currentConversationID) {
        console.error("Missing required parameters for onSubmitGenerateAnswer", {
          questionText,
          projectId: projectIdRef.current,
          selectedFocus,
          currentConversationID,
        });
        return;
      }

      if (
        !canBypassUsageLimits &&
        projectQuestionUsage &&
        projectQuestionUsage.allowed > 0 &&
        projectQuestionUsage.used >= projectQuestionUsage.allowed
      ) {
        onSubscriptionModalOpen();
        return;
      }

      const entities: RequestEntities = [
        { entity: "collection_id", value: projectIdRef.current },
        { entity: "question", value: questionText.trim() },
        { entity: "answer_focus", value: selectedFocus },
        { entity: "asked_by_user", value: "true" },
        { entity: "answer_format", value: "html" },
      ];
      if (answerLength) {
        entities.push({ entity: "answer_length", value: answerLength });
      }

      dispatch(
        sendMessage({
          conversationId: currentConversationID,
          intent: `/generate_answer`,
          entities: entities,
        })
      );

      !isMobile && onConversationOpen(currentConversationID);
    },
    [currentConversationID, canBypassUsageLimits, projectQuestionUsage, dispatch, isMobile, onConversationOpen, onSubscriptionModalOpen]
  );

  const handleDueDiligenceProjectError = useCallback(
    (errorType: "usage_limit" | "max_projects") => {
      if (errorType === "usage_limit") {
        onSubscriptionModalOpen();
      } else {
        toast({
          render: ({ onClose }) =>
            createToastContent(`You can only have ${maxAllowedProjects} active due diligence projects at a time`, onClose, () => {
              onClose();
              onSubscriptionModalOpen();
            }),
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    },
    [maxAllowedProjects, onSubscriptionModalOpen, toast]
  );

  const onSubmitDueDiligenceProject = useCallback(
    (
      questionText: string,
      questionFocus?: string,
      afterSubmit?: () => void,
      reRunProjectId?: string,
      reRunPortfolioId?: string,
      tickerType?: TickerType | null | undefined
    ) => {
      if (!canBypassUsageLimits && projectUsage && projectUsage.allowed > 0 && projectUsage.used >= projectUsage.allowed) {
        handleDueDiligenceProjectError("usage_limit");
        return;
      }

      if (hasExceededMaxProjects) {
        handleDueDiligenceProjectError("max_projects");
        return;
      }

      afterSubmit && afterSubmit();

      onSubmitDueDiligence({
        companyTickerOrName: questionText,
        companyStockExchange: questionFocus || "",
        isTicker: true,
        dispatch,
        onConversationOpen: isOnboardingModalOpen || isMobile ? undefined : onConversationOpen,
        onSubmitted: projectIdRef.current ? () => navigate(-1) : undefined,
        additionalEntities: [],
        portfolioId: reRunPortfolioId ? reRunPortfolioId : portfolioId,
        reRunProjectId: reRunProjectId ? reRunProjectId : undefined,
        companyStockType: tickerType,
      });

      toast.closeAll();
      toast({
        render: ({ onClose }) =>
          createToastContent(
            `I'll get on to creating the due diligence project now. When it's complete, I will email you a report.`,
            onClose
          ),
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    },
    [
      canBypassUsageLimits,
      projectUsage,
      hasExceededMaxProjects,
      handleDueDiligenceProjectError,
      dispatch,
      isOnboardingModalOpen,
      isMobile,
      onConversationOpen,
      navigate,
      portfolioId,
      toast,
    ]
  );

  const currentSubmitFunction = useCallback(
    (questionText: string) => {
      if (
        conversation.conversationState === "action" ||
        conversation.conversationState === "in_progress" ||
        questionText.startsWith("/") ||
        files.length > 0
      ) {
        onSubmitDefaultRequest(questionText);
      } else if (projectIdRef.current) {
        onSubmitGenerateAnswer(questionText, selectedFocus);
      } else {
        const stockExchange = selectedFocus && !["analytical", "sentiment"].includes(selectedFocus) ? selectedFocus : undefined;
        if (stockExchange) {
          onSubmitDueDiligenceProject(questionText, stockExchange, afterSubmit);
        } else {
          console.error("Unable to determine submit function");
        }
      }
    },
    [
      conversation.conversationState,
      files.length,
      onSubmitDefaultRequest,
      onSubmitGenerateAnswer,
      selectedFocus,
      onSubmitDueDiligenceProject,
      afterSubmit,
    ]
  );

  return {
    currentSubmitFunction,
    onSubmitGenerateAnswer,
    onSubmitDueDiligenceProject,
    currentConversationID,
    conversation,
    onConversationOpen,
    isConversationOpen,
    questionText,
  };
};
