import { createAction, createSlice } from "@reduxjs/toolkit";
import uniq from "lodash/uniq";
import orderBy from "lodash/orderBy";
import { REHYDRATE } from "redux-persist";
import type { Collection } from "types/collection";
import { downloadCollections, downloadCollectionsByType, removeCollection } from "./operations";

const rehydrate = createAction<CollectionState>(REHYDRATE);

interface CollectionState {
  isLoading: boolean;
  order: string[];
  collections: { [key: string]: Collection };
  collectionsByTypeCount: Record<string, number | undefined>;
  deletedCollectionIds: string[];
  hasInitialSyncCompleted: boolean;
  collectionsIdsByGroup: Record<string, string[]>;
}

const initialState: CollectionState = {
  isLoading: true,
  order: [],
  collections: {},
  collectionsByTypeCount: {},
  deletedCollectionIds: [],
  hasInitialSyncCompleted: false,
  collectionsIdsByGroup: {},
};

export const { actions, reducer } = createSlice({
  name: "collection",
  initialState,
  reducers: {
    flush: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrate, (_, action) => {
      if (action.payload?.collections) {
        return {
          ...initialState,
          collections: action.payload.collections,
          order: action.payload.order,
          deletedCollectionIds: action.payload.deletedCollectionIds,
          isLoading: false,
          hasInitialSyncCompleted: action.payload.hasInitialSyncCompleted,
          collectionsByTypeCount: action.payload.collectionsByTypeCount,
          collectionsIdsByGroup: action.payload.collectionsIdsByGroup || {},
        };
      }
    });

    builder.addCase(removeCollection.fulfilled, (state, action) => {
      const id = action.payload;

      if (id !== undefined) {
        state.order = state.order.filter((collectionId) => collectionId !== id);
        const collectionType = state.collections[id] && state.collections[id].collectionType;
        const groupId = state.collections[id] && state.collections[id].projectGroupId;
        const currentTotalByType = state.collectionsByTypeCount[collectionType];

        if (collectionType && currentTotalByType && currentTotalByType > 0) {
          state.collectionsByTypeCount[collectionType] = currentTotalByType - 1;
        }

        if (groupId && state.collectionsIdsByGroup[groupId]) {
          state.collectionsIdsByGroup[groupId] = state.collectionsIdsByGroup[groupId].filter((collectionId) => collectionId !== id);
        }

        delete state.collections[id];
        state.deletedCollectionIds = uniq([...state.deletedCollectionIds, id]);
      }
    });

    builder.addCase(downloadCollectionsByType.rejected, (state, action) => {
      state.collectionsByTypeCount[action.meta.arg.collectionType] = 0;
    });
    builder.addCase(downloadCollectionsByType.fulfilled, (state, action) => {
      state.collectionsByTypeCount[action.meta.arg.collectionType] = action.payload.totalCount;
    });

    builder.addCase(downloadCollections.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(downloadCollections.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(downloadCollections.fulfilled, (state, action) => {
      state.isLoading = false;
      action.payload.forEach((collection) => {
        if (state.deletedCollectionIds.includes(collection.id)) {
          return;
        }

        if (!state.collections[collection.id]) {
          const currentTotalByType = state.collectionsByTypeCount[collection.collectionType];
          state.collectionsByTypeCount[collection.collectionType] = (currentTotalByType ?? 0) + 1;

          state.order.push(collection.id);
        }

        if (collection.projectGroupId) {
          state.collectionsIdsByGroup[collection.projectGroupId] = uniq(
            state.collectionsIdsByGroup[collection.projectGroupId]
              ? [collection.id, ...state.collectionsIdsByGroup[collection.projectGroupId]]
              : [collection.id]
          );
        }

        collection.questions?.forEach((question) => question.answers.forEach((answer) => (answer.contextCoordinatesList = [])));

        state.collections[collection.id] = collection;
      });

      state.order = orderBy(state.order, (id) => state.collections[id].metadata.createdTime, "desc");

      state.hasInitialSyncCompleted = true;
    });
  },
});

export default reducer;
