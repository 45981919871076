import { Stack, Text, Center, Box, useBreakpointValue, Image } from "@chakra-ui/react";
import React from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useWizard } from "react-use-wizard";
import { ONBOARDING_MODEL_HEIGHT } from "./OnboardingModal";
import { ConversationDialogInput } from "screens/thread/ConversationDialogInput";
import { useCopyValue } from "hooks/useCopies";
import SlideImage1 from "screens/common/static/images/SlideImage1.png";

export const OnboardingNewProjectStep = () => {
  const { isOnboardingModalOpen } = useConversationContext();
  const { nextStep } = useWizard();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const copyOnboardingTitle = useCopyValue("copy_onboarding_modal_step2_title");
  const copyOnboardingSubTitle1 = useCopyValue("copy_onboarding_modal_step2_subtitle_1");

  const handleOnSubmitDueDiligence = () => {
    if (isOnboardingModalOpen) {
      nextStep();
    }
  };

  return (
    <Stack justifyContent="space-between" spacing="0" height={ONBOARDING_MODEL_HEIGHT}>
      <Stack spacing="0" height="100%" justifyContent={"space-between"}>
        <Stack height="100%" spacing="1rem" bgColor={"primary.default"} px={isMobile ? "1rem" : "2rem"} pt="2rem" color="white">
          <Center>
            <Text fontWeight={"semibold"} fontSize="xl">
              {copyOnboardingTitle}
            </Text>
          </Center>
          <Text fontSize="md" width="100%" textAlign="center">
            {copyOnboardingSubTitle1}
          </Text>
        </Stack>
        <Center>
          <Image position={"absolute"} top="10rem" src={SlideImage1} height="12rem" />
        </Center>
        <Box height="100%" display={"flex"} alignItems="center" justifyContent="center">
          <Box width="80%">
            <ConversationDialogInput
              inputId={"view-input"}
              afterSubmit={handleOnSubmitDueDiligence}
              initialText={"Enter the ticker of a company"}
            />
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};
