import { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { downloadRecentContents } from "state/content/operations";
import { requestToDownloadContents } from "state/content/utils";
import type { RootState } from "state/rootReducer";
import type { StockEquityData } from "types/stock";
import { NewStockExchangeRecord, StockExchangeRecord } from "types/stock";
import type { ContentDetails } from "types/content/ContentDetails";
import { useCollectionKey, useCollectionStock } from "./useCollections";
import { useConversationCompanyExchangeValue, useConversationCompanyTickerValue } from "./useConversation";
import { useCollectionMetadata, useCollectionMetadataIds } from "./useCollectionMetadata";
import { getTickersQuery } from "api/tickers";

export function useContentDetails(metadataId?: string, options = { refreshFromNetwork: false }) {
  const content = useSelector((state: RootState) => (metadataId ? state.content.contentData[metadataId] : undefined), shallowEqual);
  const hasContent = useSelector((state: RootState) => !!metadataId && !!state.content.contentData[metadataId], shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!hasContent && metadataId && options.refreshFromNetwork) {
      requestToDownloadContents({ metadataIds: [metadataId] }, dispatch);
    }
  }, [dispatch, metadataId, hasContent, options.refreshFromNetwork]);

  return content;
}

export function useAllContents() {
  return useSelector((state: RootState) => state.content.contentData, shallowEqual);
}

export function useContents(metadataIds: string[] | undefined, options: { refreshFromNetwork: boolean } = { refreshFromNetwork: false }) {
  const deDuplicatedMetadataIds = useMemo(() => [...new Set(metadataIds)], [metadataIds]);
  const content = useSelector(
    (state: RootState) =>
      deDuplicatedMetadataIds
        ? deDuplicatedMetadataIds.flatMap((id) => {
            if (state.content.contentData[id]) {
              return [state.content.contentData[id]];
            } else {
              return [];
            }
          })
        : [],
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (options.refreshFromNetwork && deDuplicatedMetadataIds.length > content.length) {
      requestToDownloadContents({ metadataIds: deDuplicatedMetadataIds }, dispatch);
    }
  }, [dispatch, deDuplicatedMetadataIds, content.length, options.refreshFromNetwork]);

  return content.length > 0 ? content : undefined;
}

export function useCompanyStockFromCollectionOrContents(collectionId: string | undefined): StockExchangeRecord | null {
  const metadataIds = useCollectionMetadataIds(collectionId);
  const collectionStock = useCollectionStock(collectionId);

  return useSelector((state: RootState) => {
    if (collectionStock) {
      return collectionStock;
    }

    const contents = metadataIds.flatMap((metadataId) =>
      state.collectionMetadata.contentsByIds[metadataId] ? [state.collectionMetadata.contentsByIds[metadataId]] : []
    );

    const companyDetailsContent = contents.find((content) => content.dynamicData?.category === "company_details");

    if (!companyDetailsContent) {
      return null;
    }

    const validStockExchange = StockExchangeRecord.validate(companyDetailsContent.dynamicData?.dynamic_data);
    const validNewStockExchange = NewStockExchangeRecord.validate(companyDetailsContent.dynamicData?.dynamic_data);

    if (validStockExchange.success) {
      return {
        name: validStockExchange.value.name,
        exchange: validStockExchange.value.exchange,
        ticker: validStockExchange.value.ticker,
      };
    } else if (validNewStockExchange.success) {
      return {
        name: validNewStockExchange.value.company_name,
        exchange: validNewStockExchange.value.company_stock_exchange,
        ticker: validNewStockExchange.value.company_ticker,
      };
    } else {
      return null;
    }
  }, shallowEqual);
}

export function useCompanyStockFromCollectionContentsOrConversation(collectionId: string | undefined): StockExchangeRecord | null {
  const collectionConversationId = useCollectionKey(collectionId, "conversationId");
  const conversationTicker = useConversationCompanyTickerValue(collectionConversationId);
  const conversationExchange = useConversationCompanyExchangeValue(collectionConversationId);
  const collectionStockData = useCompanyStockFromCollectionOrContents(collectionId);
  const [tickerData, setTickerData] = useState<StockExchangeRecord | null>(null);

  const isTickerSet = useMemo(() => !!tickerData?.ticker, [tickerData]);

  useEffect(() => {
    if (collectionStockData?.ticker || isTickerSet) {
      return;
    }

    if (!conversationTicker || !conversationExchange) {
      setTickerData(null);
      return;
    }

    let isCurrent = true;
    getTickersQuery({ limit: 1, query: conversationTicker, exchange: conversationExchange })
      .then((response) => {
        if (!isCurrent) return;
        if (response && response.length > 0) {
          setTickerData({
            name: response[0].name,
            exchange: response[0].exchange,
            ticker: response[0].symbol,
          });
        } else {
          setTickerData(null);
        }
      })
      .catch((error) => {
        console.error(error);
        if (isCurrent) setTickerData(null);
      });

    return () => {
      isCurrent = false;
    };
  }, [conversationTicker, conversationExchange, collectionStockData?.ticker, isTickerSet]);

  return collectionStockData || tickerData;
}

export function useProjectStockEquityDataFromContents(metadataIds: string[]): StockEquityData | null {
  return useSelector((state: RootState) => {
    for (const metadataId of metadataIds) {
      const content = state.content.contentData[metadataId];
      if (content && content.name?.toLowerCase() === "stock equity data") {
        return (content.dynamicData?.dynamic_data as StockEquityData) || null;
      }
    }
    return null;
  }, shallowEqual);
}

export function useContent(metadataId: string): ContentDetails | undefined {
  return useSelector((state: RootState) => state.content.contentData[metadataId], shallowEqual);
}

export function useContentFromCollectionOrContents(metadataId: string): ContentDetails | undefined {
  const maybeCollectionMetadata = useCollectionMetadata(metadataId);
  const maybeContentData = useContent(metadataId);

  return useMemo(() => maybeCollectionMetadata || maybeContentData, [maybeCollectionMetadata, maybeContentData]);
}

export function useRecentContents(limit: number) {
  const contents = useSelector((state: RootState) => {
    return state.content.order.slice(0, limit).map((metadataId) => state.content.contentData[metadataId]);
  }, shallowEqual);

  return contents;
}

export function useTotalContentsCount() {
  return useSelector((state: RootState) => state.content.recentContent.totalCount);
}

export function useDownloadRecentContents(limit: number) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(downloadRecentContents({ limit: limit }));
  }, [dispatch, limit]);
}

export const useLoadingContents = (): boolean => {
  return useSelector((state: RootState) => state.content.isLoading);
};
