import {
  Box,
  Button,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Stack,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  useButtonProps,
  useCollectionKey,
  useConfigMap,
  useContents,
  useGetViewConfig,
  useMainCollectionWorkflowId,
  useProjectParams,
  useUserPreference,
} from "hooks";
import type { FunctionComponent } from "react";
import React, { useContext, useState, useMemo, useEffect } from "react";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { OptionsMenuItem } from "../components/OptionsMenuItem";
import { HamburgerIcon, TriangleDownIcon } from "@chakra-ui/icons";
import { ProjectDeleteButton } from "./ProjectActions/ProjectDeleteButton";
import { ProjectRerunButton } from "./ProjectActions/ProjectRerunButton";
import { TbListSearch } from "react-icons/tb";
import { AiOutlineFileText } from "react-icons/ai";
import { ResearchContext } from "screens/panels/research/ResearchContext";
import { useRequestDefaultReport } from "../components/useRequestDefaultReport";
import { ManualTags } from "../components/utils";
import { MoveProjectModal } from "./ProjectActions/MoveProjectModal";
import { ProjectMoveButton } from "./ProjectActions/ProjectMoveButton";
import { v4 as uuid } from "uuid";
import { useWorkflowStatus } from "hooks/useWorkflows";
import { ConversationContext } from "screens/thread/ConversationContext";
import { ProjectReportPanelNewReportModal } from "screens/panels/researchReport/ProjectReportPanelNewReportModal";

interface Props {
  collectionId: string;
}

export const ProjectDetailDefaultActionMenu: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  collectionId,
}) => {
  const { isPortfolios } = useProjectParams();
  const {
    onProjectReportPanelOpen,
    onKeywordsNotesPanelOpen,
    onProjectAddSourceOpen,
    onProjectReportSelectorOpen,
    isProjectReportSelectorOpen,
    onProjectReportSelectorClose,
    isProjectReportPanelOpen,
  } = useAddToCharliContext();
  const { onResearchHistoryPanelOpen } = useContext(ResearchContext);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const buttonSize = isMobile ? "xs" : "sm";
  const commonButtonProps = useButtonProps(buttonSize, "primary");
  const newReportButtonProps = useButtonProps(buttonSize, "cta");
  const [isRequesting, setIsRequesting] = useState(false);
  const configMap = useConfigMap();
  const projectDefaultReport = useGetViewConfig("defaultReport", "due_diligence", configMap);
  const userDefaultReportTemplateType = useUserPreference("default_report_template_type");
  const requestDefaultReport = useRequestDefaultReport({
    collectionId,
    setIsRequesting,
    selectedReportTemplate: isPortfolios && projectDefaultReport ? projectDefaultReport : undefined,
  });
  const collectionQuestions = useCollectionKey(collectionId, "questions");
  const collectionMetadataIds = useCollectionKey(collectionId, "metadataIds");
  const collectionConversationId = useCollectionKey(collectionId, "conversationId");
  const collectionResearch = useCollectionKey(collectionId, "searchHistory");
  const contentData = useContents(collectionMetadataIds || []);
  const workflowId = useMainCollectionWorkflowId(collectionId);
  const workflowStatus = useWorkflowStatus(workflowId);
  const { onOpen: onOpenMoveProject, onClose: onCloseMoveProject, isOpen: isOpenMoveProject } = useDisclosure();
  const { onDeleteProjectModalOpen } = useContext(ConversationContext);

  const collectionQuestionsFilter = useMemo(() => {
    const userQuestions = collectionQuestions?.filter((question) => question.initiator === "user");

    if (userQuestions?.length === 0) return "";
    const lastUserQuestion = userQuestions?.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime())[0]
      .creationDate;
    return lastUserQuestion || "";
  }, [collectionQuestions]);

  const collectionReportsFilter = useMemo(() => {
    const projectReports = contentData?.filter((content) => content.manualTags?.includes(ManualTags.projectOutput));
    if (projectReports?.length === 0) return "";
    const latestReport = projectReports?.sort((a, b) => new Date(b.timeCreated).getTime() - new Date(a.timeCreated).getTime())[0]
      .timeCreated;
    return latestReport || "";
  }, [contentData]);

  const hasQuestionOlderThenReport = useMemo(() => {
    return new Date(collectionQuestionsFilter) > new Date(collectionReportsFilter);
  }, [collectionQuestionsFilter, collectionReportsFilter]);

  useEffect(() => {
    setIsRequesting(false);
  }, [contentData]);

  const handleReportOnClick = () => {
    if (userDefaultReportTemplateType) {
      setIsRequesting(true);
      requestDefaultReport();
    } else {
      onProjectReportSelectorOpen();
    }
  };

  return (
    <Stack direction="row" spacing="0.5rem">
      <Tooltip label={hasQuestionOlderThenReport ? "Generate a new report" : ""} aria-label="New Report">
        <Tooltip
          label={
            hasQuestionOlderThenReport
              ? "The project details have changed and you may have asked new questions, click here for me to send a new report."
              : "The project details have not changed since the last report was generated, click the View AI Generated Reports button ➜ to see the last report."
          }>
          <Button
            cursor="pointer"
            aria-label="New Report"
            className="ch-new-report-panel"
            {...(isRequesting || !hasQuestionOlderThenReport ? commonButtonProps : newReportButtonProps)}
            leftIcon={<Icon as={AiOutlineFileText} boxSize={isMobile ? "1rem" : "1.3rem"} />}
            onClick={handleReportOnClick}>
            Send Report
          </Button>
        </Tooltip>
      </Tooltip>
      <Tooltip label="View AI Generated Reports" aria-label="Show/Hide Reports">
        <Box cursor="pointer">
          <IconButton
            aria-label="Open Reports"
            className="ch-open-report-panel"
            {...commonButtonProps}
            icon={<Icon as={AiOutlineFileText} boxSize={isMobile ? "1rem" : "1.3rem"} />}
            onClick={() => {
              onProjectReportPanelOpen();
            }}
          />
        </Box>
      </Tooltip>
      <Tooltip label="View AI Keywords and Notes" aria-label="Show/Hide Notes">
        <Box cursor="pointer">
          <IconButton
            aria-label="Open Notes"
            className="ch-open-keywords-notes-panel"
            {...commonButtonProps}
            icon={<Icon as={TbListSearch} boxSize={isMobile ? "1rem" : "1.3rem"} />}
            onClick={() => {
              onKeywordsNotesPanelOpen();
            }}
          />
        </Box>
      </Tooltip>
      <Box>
        <Menu isLazy>
          {isMobile ? (
            <MenuButton
              {...commonButtonProps}
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon boxSize={isMobile ? "1rem" : "1.3rem"} />}
              variant="outline"
              onClick={(event) => event.stopPropagation()}
            />
          ) : (
            <MenuButton
              {...commonButtonProps}
              className="ch-project-options"
              as={Button}
              aria-label="Options"
              rightIcon={<TriangleDownIcon />}
              onClick={(event) => event.stopPropagation()}>
              Actions
            </MenuButton>
          )}
          <MenuList minWidth={"unset"} zIndex={10}>
            <ProjectMoveButton isDisabled={workflowStatus === "in_progress"} onClick={onOpenMoveProject} />
            <OptionsMenuItem menuLabel={"Add Source Content"} className="ch-open-add-resource-panel" onClick={onProjectAddSourceOpen} />
            {collectionResearch && (
              <OptionsMenuItem menuLabel={"Search History"} className="ch-open-research-panel" onClick={onResearchHistoryPanelOpen} />
            )}
            <ProjectRerunButton reRunProjectId={collectionId} />
            <MenuDivider />
            <ProjectDeleteButton onOpenConfirmation={onDeleteProjectModalOpen} projectId={collectionId} isMenuItem />
          </MenuList>
        </Menu>
        <MoveProjectModal
          isOpen={isOpenMoveProject}
          onClose={() => {
            onCloseMoveProject();
          }}
          collectionId={collectionId}
          conversationId={collectionConversationId || uuid()}
        />
        {!isProjectReportPanelOpen && (
          <ProjectReportPanelNewReportModal
            onClose={onProjectReportSelectorClose}
            isOpen={isProjectReportSelectorOpen}
            collectionId={collectionId}
          />
        )}
      </Box>
    </Stack>
  );
};
