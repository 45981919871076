import { Stack, useColorModeValue, Box, useBreakpointValue } from "@chakra-ui/react";
import { useConfigMap, useCustomTheme, useGetViewConfig, useUserPreference } from "hooks";
import React, { useEffect, useState } from "react";
import { SidebarNavigationMain } from "./SidebarNavigationMain";
import { HelpMenu } from "./HelpMenu";
import { SidebarAdminButton } from "./SidebarAdminButton";
import SideBarHelpVideos from "./SideBarHelpVideos";
import { useDebouncedCallback } from "use-debounce";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { useDispatch } from "react-redux";
import { SidebarNavigationLogos } from "./SidebarNavigationLogo";

export const SIDEBAR_PADDING = "1rem";

const MIN_SIDEBAR_WIDTH = 208;
const MAX_SIDEBAR_WIDTH = 500;
export const DEFAULT_SIDEBAR_WIDTH = 208;

export const SidebarNavigation = () => {
  const bgColor = useColorModeValue("charli.lightBlue", "gray.800");
  const { isEmbeddedApp } = useCustomTheme();
  const userPrefSidebarWidth = useUserPreference("ui_sidebar_width") as number;
  const hasHiddenOnboardingVideos = useUserPreference("ui_hide_onboarding_videos") as boolean;
  const [width, setWidth] = useState(userPrefSidebarWidth || DEFAULT_SIDEBAR_WIDTH);
  const configMap = useConfigMap();
  const homeOnboardingSteps = useGetViewConfig("onBoardingSteps", "home", configMap);
  const isMobile = useBreakpointValue({ base: true, md: true, lg: false }, { fallback: "md", ssr: false });
  const dispatch = useDispatch();

  useEffect(() => {
    if (userPrefSidebarWidth) {
      setWidth(userPrefSidebarWidth);
    }
  }, [userPrefSidebarWidth]);

  const debouncedUpdateWidth = useDebouncedCallback((newWidth: number) => {
    dispatch(updateTypedUserPreference({ preferenceKey: "ui_sidebar_width", value: newWidth }));
  }, 150);

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();

    const handleMouseMove = (e: MouseEvent) => {
      const newWidth = Math.min(Math.max(e.clientX, MIN_SIDEBAR_WIDTH), MAX_SIDEBAR_WIDTH);
      setWidth(newWidth);

      debouncedUpdateWidth(newWidth);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);

      debouncedUpdateWidth.flush();
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleResetWidth = () => {
    const newWidth = DEFAULT_SIDEBAR_WIDTH;
    dispatch(updateTypedUserPreference({ preferenceKey: "ui_sidebar_width", value: newWidth }));
    setWidth(newWidth);
  };

  return (
    <Stack
      gap="0"
      mt="0!important"
      direction="row"
      backgroundColor={bgColor}
      width={["100vw", "100%", "100%", `${width}px`, `${width}px`]}
      justifyContent="space-between"
      height="100vh"
      zIndex={1}>
      <Stack gap="0" width="100%" direction="column" height="100%" align="flex-start" justifyContent="space-between">
        <Stack spacing={"1rem"} height="100%" width="100%">
          {!isEmbeddedApp && <SidebarNavigationLogos />}
          <Box
            px="1rem"
            maxHeight={hasHiddenOnboardingVideos ? "70vh" : "55vh"}
            height="100%"
            width="100%"
            overflowY={"auto"}
            overflowX="hidden">
            <SidebarNavigationMain />
          </Box>
        </Stack>
        <Stack width="100%" height="18rem" spacing="1rem" px="1rem" overflowX="hidden" justifyContent="flex-end" mb="1rem">
          {homeOnboardingSteps && !hasHiddenOnboardingVideos && width > 120 && <SideBarHelpVideos links={homeOnboardingSteps} />}
          {!isEmbeddedApp && <SidebarAdminButton />}
          {!isEmbeddedApp && <HelpMenu />}
        </Stack>
      </Stack>
      {!isMobile && (
        <Box
          height="100%"
          width="5px"
          cursor="ew-resize"
          backgroundColor="transparent"
          onMouseDown={handleMouseDown}
          onDoubleClick={handleResetWidth}
          _hover={{ backgroundColor: "gray.300" }}
        />
      )}
    </Stack>
  );
};
