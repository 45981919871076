import type { ReactNode } from "react";
import React, { useMemo, useState } from "react";
import { Button, FormControl, FormLabel, FormErrorMessage, Input, Box, Switch, Text, Textarea } from "@chakra-ui/react";
import { useActiveConfiguredWorkflowWithIntent, useConfiguredWorkflow, useLoadingConfiguredWorkflow } from "hooks/useConfiguredWorkflows";
import type { Checkpoint, ConfiguredWorkflow, ConfiguredWorkflowCreationPayload } from "types/configuredWorkflows";
import { CheckpointTypes } from "types/configuredWorkflows";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { CheckpointsFieldArray } from "./CheckpointsFieldArray";
import { useAppDispatch, useButtonProps, useUserProfile } from "hooks";
import { EntitiesFieldArray } from "./EntitiesFieldArray";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import type { Entity } from "types/workflows/workflow";
import { AvailableUsersFieldArray } from "./AvailableUsersFieldArray";
import { approveConfiguredWorkflow, unapproveConfiguredWorkflow, updateConfiguredWorkflow } from "state/configuredWorkflow/operations";
import { useUsersMap } from "hooks/useUsers";
import type { User } from "@charliai/node-core-lib/lib/src/userService/models/User";
import { ConfigDiffPanel } from "./ConfigDiffPanel";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

interface FormCheckpoint {
  id?: string;
  type: "execute_intent" | "create_entities" | "milestone";
  label?: string;
  intent?: string;
  needsConfirmation: boolean;
  runInNewChildWorkflow: boolean;
  showEntityToSplit: boolean;
  onlyIncludeTheseEntities?: { value: string }[];
  entityToSplitName?: string;
  entityToSplitRenameTo?: string;
  entityToSplitShouldUnwrap?: boolean;
  childWorkflowSuccessIsOptional?: boolean;
  requirements?: {
    entity: string;
    operator: "equal" | "not_equal" | "in" | "not_in" | "contains" | "does_not_contain";
    value: string;
  }[];
  entitiesToRename?: { entityName: string; renameTo: string }[];
  entitiesToInject?: { entity: string; value: string }[];
}

interface FormConfig {
  defaultEntities?: {
    entity: string;
    value: string;
  }[];
  checkpoints: FormCheckpoint[];
}

export interface FormValues {
  id?: string;
  userIntent: string;
  config: FormConfig;
  onlyAvailableToUserIds?: { label: string; value: string }[];
  notes?: string;
}

interface IProps {
  id?: string;
  isOpen: boolean;
  initialValues?: ConfiguredWorkflow;
  onClose: () => void;
  onSubmit: (values: ConfiguredWorkflowCreationPayload, closePanel?: boolean) => void;
  onSubmitState: (values: { id: string; state: "active" | "draft"; fromVersion?: string }) => void;
}

const testIsJson = (value: string | undefined | null) => {
  if (!value) {
    return true;
  }

  try {
    JSON.parse(value);

    return true;
  } catch (error) {
    return false;
  }
};

const validJsonErrorMessage = "Value should be a valid JSON";

const transformToFormCheckpoints = (checkpoints: Checkpoint[]): FormCheckpoint[] => {
  return checkpoints
    .filter(({ type }) => CheckpointTypes.includes(type))
    .map((checkpoint) => {
      if (checkpoint.type === "create_entities") {
        return {
          id: checkpoint.id,
          type: "create_entities",
          intent: "",
          needsConfirmation: false,
          runInNewChildWorkflow: false,
          showEntityToSplit: false,
          requirements: (checkpoint.requirements ?? []).map((requirement) => {
            return {
              ...requirement,
              value: requirement.value !== null ? JSON.stringify(requirement.value) : "null",
            };
          }),
          entitiesToInject: transformToFormEntities(checkpoint.entitiesToInject),
        };
      } else if (checkpoint.type === "execute_intent") {
        return {
          id: checkpoint.id,
          type: checkpoint.type ?? "execute_intent",
          intent: checkpoint.intent,
          needsConfirmation: checkpoint.needsConfirmation ?? false,
          runInNewChildWorkflow: checkpoint.runInNewChildWorkflow ?? false,
          childWorkflowSuccessIsOptional:
            checkpoint.shouldFailEntireWorkflowOnChildWorkflowFailure !== undefined
              ? !checkpoint.shouldFailEntireWorkflowOnChildWorkflowFailure
              : undefined,
          showEntityToSplit: checkpoint.entityToSplit !== undefined ? true : false,
          onlyIncludeTheseEntities: checkpoint.onlyIncludeTheseEntities?.map((entity) => ({ value: entity })) ?? [],
          ...(checkpoint.entityToSplit
            ? {
                entityToSplitName: checkpoint.entityToSplit.entityName,
                entityToSplitRenameTo: checkpoint.entityToSplit.renameTo ?? "",
                entityToSplitShouldUnwrap: checkpoint.entityToSplit.shouldUnwrap ?? false,
              }
            : {
                entityToSplitName: "",
                entityToSplitRenameTo: "",
                entityToSplitShouldUnwrap: false,
              }),
          requirements: checkpoint.requirements.map((requirement) => {
            return {
              ...requirement,
              value: requirement.value !== null ? JSON.stringify(requirement.value) : "null",
            };
          }),
          entitiesToRename: checkpoint.entitiesToRename ?? [],
          entitiesToInject: checkpoint.entitiesToInject ? transformToFormEntities(checkpoint.entitiesToInject) : [],
        };
      } else {
        return {
          id: checkpoint.id,
          type: "milestone",
          label: checkpoint.label,
          needsConfirmation: false,
          runInNewChildWorkflow: false,
          showEntityToSplit: false,
        };
      }
    });
};

const transformToFormEntities = (entities: Entity[]) => {
  return entities.map((entity) => ({
    value: entity.value !== null ? String(JSON.stringify(entity.value)) : "null",
    entity: entity.entity,
  }));
};

const transformFromFormConfiguredWorkflowToPayload = (fields: FormValues, id?: string): ConfiguredWorkflowCreationPayload => {
  return {
    ...(id && { id }),
    userIntent: fields.userIntent,
    config: {
      defaultEntities: fields.config.defaultEntities
        ? fields.config.defaultEntities.map(({ entity, value }) => {
            return {
              entity,
              value: (() => {
                try {
                  return JSON.parse(`${value}`);
                } catch (error) {
                  // It is probably a string and not a JSON.
                  return value;
                }
              })(),
            };
          })
        : undefined,
      checkpoints: (() => {
        const checkpoints: Checkpoint[] = [];
        fields.config.checkpoints.forEach((checkpoint) => {
          if (checkpoint.type === "execute_intent" && checkpoint.intent) {
            checkpoints.push({
              type: "execute_intent",
              id: checkpoint.id,
              intent: checkpoint.intent,
              entitiesToInject: checkpoint.entitiesToInject?.map(({ entity, value }) => {
                return {
                  entity,
                  value: (() => {
                    try {
                      return JSON.parse(`${value}`);
                    } catch (error) {
                      return value;
                    }
                  })(),
                };
              }),
              needsConfirmation: checkpoint.needsConfirmation,
              runInNewChildWorkflow: checkpoint.runInNewChildWorkflow,
              ...(checkpoint.runInNewChildWorkflow && {
                onlyIncludeTheseEntities: checkpoint.onlyIncludeTheseEntities?.map(({ value }) => value),
                ...(checkpoint.showEntityToSplit &&
                  checkpoint.entityToSplitName && {
                    entityToSplit: {
                      entityName: checkpoint.entityToSplitName,
                      renameTo: checkpoint.entityToSplitRenameTo,
                      shouldUnwrap: checkpoint.entityToSplitShouldUnwrap ?? false,
                    },
                  }),
                entitiesToRename: checkpoint.entitiesToRename,
                shouldFailEntireWorkflowOnChildWorkflowFailure: checkpoint.childWorkflowSuccessIsOptional
                  ? !checkpoint.childWorkflowSuccessIsOptional
                  : true,
              }),
              requirements: (checkpoint.requirements || []).map(({ entity, value, operator }) => {
                return {
                  entity,
                  operator,
                  value: (() => {
                    try {
                      return JSON.parse(`${value}`);
                    } catch (error) {
                      return value;
                    }
                  })(),
                };
              }),
            });
          } else if (checkpoint.type === "create_entities") {
            checkpoints.push({
              type: "create_entities",
              id: checkpoint.id,
              entitiesToInject: (checkpoint.entitiesToInject || []).map(({ entity, value }) => {
                return {
                  entity,
                  value: (() => {
                    try {
                      return JSON.parse(`${value}`);
                    } catch (error) {
                      return value;
                    }
                  })(),
                };
              }),
              requirements: (checkpoint.requirements || []).map(({ entity, value, operator }) => {
                return {
                  entity,
                  operator,
                  value: (() => {
                    try {
                      return JSON.parse(`${value}`);
                    } catch (error) {
                      return value;
                    }
                  })(),
                };
              }),
            });
          } else if (checkpoint.type === "milestone" && checkpoint.label) {
            checkpoints.push({
              id: checkpoint.id,
              type: "milestone",
              label: checkpoint.label,
            });
          } else {
            console.warn("Unhandled checkpoint type", checkpoint);
          }
        });

        return checkpoints;
      })(),
    },
    notes: fields.notes,
  };
};

const transformToFormUsers = (usersIds: string[], usersMap: Record<string, User>): { label: string; value: string }[] => {
  return usersIds.map((userId) => {
    const user = usersMap[userId];

    if (!user) {
      return {
        label: `User ${userId}`,
        value: userId,
      };
    }

    return {
      label: `${user.firstName} ${user.lastName} (${user.email})`,
      value: userId,
    };
  });
};

const transformConfiguredWorkflowToFormValues = (configWorkflow: ConfiguredWorkflow, usersMap: Record<string, User>) => {
  return {
    userIntent: configWorkflow.userIntent,
    config: {
      defaultEntities: configWorkflow.config.defaultEntities ? transformToFormEntities(configWorkflow.config.defaultEntities) : [],
      checkpoints: transformToFormCheckpoints(configWorkflow.config.checkpoints),
    },
    onlyAvailableToUserIds: transformToFormUsers(configWorkflow.onlyAvailableToUserIds ?? [], usersMap),
    notes: configWorkflow.notes ?? "",
  };
};

const validationSchema = yup.object().shape({
  id: yup.string(),
  config: yup.object().shape({
    defaultEntities: yup
      .array()
      .of(
        yup.object().shape({
          entity: yup.string().required("Entity is required."),
          value: yup
            .string()
            .nullable()
            .required("Value is required.")
            .test("is-default-entity-value-json", validJsonErrorMessage, testIsJson),
        })
      )
      .required(),
    checkpoints: yup
      .array()
      .of(
        yup.object().shape({
          type: yup.string().oneOf(CheckpointTypes).required("Type is required."),
          intent: yup.string().when("type", {
            is: "execute_intent",
            then: (schema) => schema.required("Intent is required."),
            otherwise: (schema) => schema.notRequired(),
          }),
          label: yup.string().when("type", {
            is: "milestone",
            then: (schema) => schema.required("Label is required."),
            otherwise: (schema) => schema.notRequired(),
          }),
          needsConfirmation: yup.boolean().required("Needs confirmation is required."),
          runInNewChildWorkflow: yup.boolean().required("Run in new child workflow is required."),
          showEntityToSplit: yup.boolean().required("Show entity to split is required."),
          onlyIncludeTheseEntities: yup
            .array()
            .of(yup.object().shape({ value: yup.string().required("Value is required.") }))
            .optional(),
          entityToSplitName: yup.string().when("showEntityToSplit", {
            is: true,
            then: (schema) => schema.required("Entity name is required."),
            otherwise: (schema) => schema.optional(),
          }),
          entityToSplitRenameTo: yup.string().optional(),
          entityToSplitShouldUnwrap: yup.boolean().optional(),
          requirements: yup.array().of(
            yup.object().shape({
              entity: yup.string().required("Entity is required."),
              operator: yup
                .string()
                .oneOf(["equal", "not_equal", "in", "not_in", "contains", "does_not_contain"])
                .required("Operator is required."),
              value: yup
                .string()
                .nullable()
                .test("is-requirement-value-json", validJsonErrorMessage, testIsJson)
                .required("Value is required."),
            })
          ),
          entitiesToRename: yup.array().of(
            yup.object().shape({
              entityName: yup.string().required("Entity name is required."),
              renameTo: yup.string().required("Rename to is required."),
            })
          ),
          entitiesToInject: yup
            .array()
            .of(
              yup.object().shape({
                entity: yup.string().required("Entity is required."),
                value: yup
                  .string()
                  .nullable()
                  .required("Value is required.")
                  .test("is-entity-to-inject-value-json", validJsonErrorMessage, testIsJson),
              })
            )
            .when("type", {
              is: "create_entities",
              then: (schema) => schema.min(1, "At least one entity is required."),
              otherwise: (schema) => schema.optional(),
            }),
        })
      )
      .required(),
  }),
  userIntent: yup.string().required("User intent is required."),
  onlyAvailableToUserIds: yup
    .array()
    .of(yup.object().shape({ label: yup.string().required(), value: yup.string().required() }))
    .optional(),
  notes: yup.string().required("Notes are required."),
});

export const ConfiguredWorkflowUpsertModal = (props: IProps) => {
  const { id, isOpen, onClose, onSubmit: onSubmitProp, onSubmitState, initialValues } = props;
  const configWorkflow = useConfiguredWorkflow(id);
  const commonButtonProps = useButtonProps("sm", "primary");
  const isLoading = useLoadingConfiguredWorkflow(id);
  const dispatch = useAppDispatch();
  const users = useUsersMap();
  const { id: userId } = useUserProfile();
  const [isDiffPanelOpen, setIsDiffPanelOpen] = useState(false);
  const activeConfigWorkflow = useActiveConfiguredWorkflowWithIntent(configWorkflow?.userIntent);

  const methods = useForm<FormValues>({
    defaultValues: {
      ...(configWorkflow
        ? transformConfiguredWorkflowToFormValues(configWorkflow, users)
        : initialValues
        ? transformConfiguredWorkflowToFormValues(initialValues, users)
        : {
            userIntent: "",
            config: {
              defaultEntities: [{ entity: "", value: "" }],
              checkpoints: [
                {
                  type: "execute_intent",
                  intent: "",
                  needsConfirmation: false,
                  runInNewChildWorkflow: false,
                  childWorkflowSuccessIsOptional: false,
                  showEntityToSplit: false,
                  requirements: [
                    {
                      entity: "",
                      operator: "equal",
                      value: "null",
                    },
                  ],
                  entitiesToRename: [],
                  entitiesToInject: [],
                  entityToSplitName: "",
                  entityToSplitRenameTo: "",
                  entityToSplitShouldUnwrap: false,
                },
              ],
            },
            onlyAvailableToUserIds: [],
            notes: "",
          }),
    },
    resolver: yupResolver<FormValues>(validationSchema),
  });

  const {
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    control,
  } = methods;

  const onSubmit = (fields: FormValues) => {
    console.log("fields", fields);
    const payload = transformFromFormConfiguredWorkflowToPayload(fields, id);

    console.log("onSubmit", payload);

    onSubmitProp(payload);
  };

  const onChangeState = (state: "active" | "draft", isConfirmed = false) => {
    if (activeConfigWorkflow && !isConfirmed) {
      setIsDiffPanelOpen(true);
      return;
    }

    if (!configWorkflow?.id) {
      return;
    }

    onSubmitState({
      id: configWorkflow.id,
      state,
      fromVersion: activeConfigWorkflow?.version,
    });
  };

  const isApprovedByCurrentUser = useMemo(
    () => !!configWorkflow && !!configWorkflow.approvedByUsers && configWorkflow.approvedByUsers.some((user) => user.id === userId),
    [configWorkflow, userId]
  );

  const panelFooter: ReactNode = useMemo(
    () => (
      <Button {...commonButtonProps} isLoading={isSubmitting} isDisabled={!isDirty} type="submit" form="upsert-configured-workflow-form">
        Save Workflow
      </Button>
    ),
    [commonButtonProps, isDirty, isSubmitting]
  );

  return (
    <>
      <PanelView isOpen={isOpen} onClose={onClose} panelTitle={`${id ? "Update" : "Create"} configured workflow`} panelFooter={panelFooter}>
        <Wizard>
          <PanelStep onNextDisabled justifyContent={"flex-start"}>
            {configWorkflow && (configWorkflow.state === "active" || configWorkflow.state === "draft") ? (
              <>
                {configWorkflow.state === "draft" && (
                  <>
                    <FormControl mb={"1rem"} display="flex" justifyContent={"flex-end"} alignItems="center">
                      <FormLabel fontSize={"sm"} htmlFor="email-alerts" mb="0">
                        Approve
                      </FormLabel>
                      <Switch
                        size="sm"
                        disabled={isLoading}
                        isChecked={isApprovedByCurrentUser}
                        onChange={() => {
                          if (isApprovedByCurrentUser) {
                            dispatch(unapproveConfiguredWorkflow(configWorkflow.id));
                          } else {
                            dispatch(approveConfiguredWorkflow(configWorkflow.id));
                          }
                        }}
                      />
                    </FormControl>

                    <Box display={"flex"} flexDirection="column" alignItems={"flex-end"}>
                      <Text fontSize={"sm"} fontWeight={"bold"}>
                        Approved by
                      </Text>
                      {configWorkflow.approvedByUsers &&
                        configWorkflow.approvedByUsers.map((user) => (
                          <Text fontSize={"sm"} key={user.id}>
                            {user.name}
                          </Text>
                        ))}
                    </Box>
                  </>
                )}

                <Box height={"1rem"} />
                <FormControl display="flex" justifyContent={"flex-end"} alignItems="center">
                  <FormLabel fontSize={"sm"} htmlFor="email-alerts" mb="0">
                    Publish
                  </FormLabel>
                  <Switch
                    size="sm"
                    disabled={isLoading || (configWorkflow.state === "draft" && !configWorkflow.canBePublished)}
                    isChecked={configWorkflow.state === "active"}
                    onChange={(evt) => {
                      onChangeState(evt.target.checked ? "active" : "draft", configWorkflow.state === "active");
                    }}
                  />
                </FormControl>
              </>
            ) : null}

            <Box pt="1rem" pb="2rem">
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} id="upsert-configured-workflow-form">
                  <FormControl isInvalid={errors.userIntent !== undefined} pb="1rem">
                    <FormLabel fontSize={"sm"} htmlFor="user-intent">
                      User Intent
                    </FormLabel>
                    <Controller
                      render={({ field }) => <Input {...field} size="sm" id="user-intent" type="text" />}
                      name="userIntent"
                      control={control}
                    />
                    {errors.userIntent && <FormErrorMessage>{errors.userIntent.message}</FormErrorMessage>}
                  </FormControl>
                  <FormControl isInvalid={errors.notes !== undefined} pb="1rem">
                    <FormLabel fontSize={"sm"} htmlFor="notes">
                      Notes
                    </FormLabel>
                    <Controller render={({ field }) => <Textarea {...field} size="sm" id="notes" />} name="notes" control={control} />
                    {errors.notes && <FormErrorMessage>{errors.notes.message}</FormErrorMessage>}
                  </FormControl>
                  <EntitiesFieldArray />
                  <CheckpointsFieldArray />
                  {/* Is update */}
                  {configWorkflow && configWorkflow.state === "draft" && (
                    <AvailableUsersFieldArray
                      onAdd={async (userId) => {
                        const currentUsers = new Set<string>(configWorkflow.onlyAvailableToUserIds ?? []);
                        currentUsers.add(userId);

                        const response = await dispatch(
                          updateConfiguredWorkflow({
                            id: configWorkflow.id,
                            onlyAvailableToUserIds: Array.from(currentUsers),
                          })
                        );

                        return response.type === updateConfiguredWorkflow.fulfilled.type;
                      }}
                      onDelete={async (userId: string) => {
                        const currentUsers = new Set<string>(configWorkflow.onlyAvailableToUserIds ?? []);
                        currentUsers.delete(userId);

                        const response = await dispatch(
                          updateConfiguredWorkflow({
                            id: configWorkflow.id,
                            onlyAvailableToUserIds: Array.from(currentUsers),
                          })
                        );

                        return response.type === updateConfiguredWorkflow.fulfilled.type;
                      }}
                      control={control}
                      isLoading={isLoading}
                    />
                  )}
                </form>
              </FormProvider>
            </Box>
          </PanelStep>
        </Wizard>
      </PanelView>

      {isDiffPanelOpen && configWorkflow && activeConfigWorkflow && (
        <ConfigDiffPanel
          isOpen
          onClose={() => setIsDiffPanelOpen(false)}
          originalConfig={activeConfigWorkflow.config}
          modifiedConfig={configWorkflow.config}
          userIntent={configWorkflow.userIntent}
          onSubmit={() => {
            onChangeState("active", true);
            setIsDiffPanelOpen(false);
          }}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
