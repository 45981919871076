import { Box, Center, useTheme } from "@chakra-ui/react";
import { useCollectionKey, useContents, useProjectGroupIdToGetMetaDataIds } from "hooks";
import { useCollectionMetadataWithTagId } from "hooks";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import React from "react";
import { ContentDetailImage } from "screens/collection/components/ContentDetailImage";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { ProjectThumbnailsGrid } from "./ProjectThumbnailGrid";

interface Props {
  projectType: string;
  width?: string;
  metadataIds?: string[];
  projectName?: string;
  projectGroupId?: string;
  collectionId?: string;
}

const NonDDProjectThumbnailGrid = ({ collectionId, calculatedWidth }: { collectionId: string; calculatedWidth: string }) => {
  const collectionMetadatasIds = useCollectionKey(collectionId, "metadataIds");
  const slicedMetadataIds = useMemo(() => (collectionMetadatasIds ? collectionMetadatasIds?.slice(0, 2) : []), [collectionMetadatasIds]);

  useContents(slicedMetadataIds, { refreshFromNetwork: true });

  return (
    <Box width={calculatedWidth}>
      <ProjectThumbnailsGrid metadataIds={slicedMetadataIds} />
    </Box>
  );
};

export const ProjectHeaderImage: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  projectType,
  width,
  metadataIds,
  projectName,
  projectGroupId,
  collectionId,
}) => {
  const contentIdOfCompanyLogoFromGroup = useProjectGroupIdToGetMetaDataIds(projectGroupId);

  const contentIdOfCompanyLogo = useCollectionMetadataWithTagId(metadataIds ?? [], "company_logo");
  const contentIdOfProjectLandingThumbnail = useCollectionMetadataWithTagId(metadataIds ?? [], "project_landing_thumbnail");
  const calculatedWidth = width ? width : "8rem";
  const theme = useTheme();

  if (contentIdOfProjectLandingThumbnail) {
    return (
      <Box width={calculatedWidth}>
        <ContentDetailImage contentId={contentIdOfProjectLandingThumbnail} maxHeight="2rem" />
      </Box>
    );
  }

  if (contentIdOfCompanyLogo) {
    return (
      <Center width={calculatedWidth}>
        <Box className="ch-project-company-logo" width="100%">
          <ContentDetailImage contentId={contentIdOfCompanyLogo} maxHeight="2rem" />
        </Box>
      </Center>
    );
  } else if (contentIdOfCompanyLogoFromGroup) {
    return (
      <Center width={calculatedWidth}>
        <Box className="ch-project-company-logo" width="100%">
          <ContentDetailImage contentId={contentIdOfCompanyLogoFromGroup} maxHeight="2rem" />
        </Box>
      </Center>
    );
  } else if (collectionId && projectType !== "due_diligence") {
    return <NonDDProjectThumbnailGrid collectionId={collectionId} calculatedWidth={calculatedWidth} />;
  } else {
    return (
      <Box className="ch-project-default-icon" width={calculatedWidth}>
        <TextOverflowTooltip
          className="ch-project-landing-default-tile-title"
          label={projectName ?? ""}
          style={{ fontSize: "1.4rem", fontWeight: "bold", fontFamily: theme.fonts.logo }}
        />
      </Box>
    );
  }
};
