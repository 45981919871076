import { Stack, Text, Image, HStack, useBreakpointValue, Box, useToast, Center } from "@chakra-ui/react";
import type { PlanName } from "api/subscription/models/PlanName";
import { useUserProfile } from "hooks";
import React from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import verifiedShield from "screens/common/static/misc/verified_shield.svg";
import soc2logo from "screens/common/static/logos/soc2_logo.png";
import { SubscribeForm } from "./SubscribeForm";
import { ToastMessageContent } from "screens/common/components";
import { subscribeToPlan, updateCustomerDetails } from "api/subscription";
import { downloadSubscriptionDetails } from "state/subscription/operations";
import { useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { useCopyValue } from "hooks/useCopies";
import nasdaqLogo from "screens/common/static/logos/nasdaq_logo_white.svg";
import tmxLogo from "screens/common/static/logos/tmx_logo_white.svg";

export interface Feature {
  name: string;
  trial: boolean | string;
  business: boolean | string;
}

export const SubscriptionUpgradeConfirm = ({ upgradePlan = "business" }: { upgradePlan: PlanName }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { onSubscriptionModalClose } = useConversationContext();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { id: userId } = useUserProfile();
  const copySubscriptionPlan = useCopyValue("copy_subscription_plan");

  const onSubscribeFormSubmit = async (fields: {
    firstName: string;
    lastName: string;
    organization: string;
    city: string;
    address: string;
    address2: string;
    country: string;
    state: string;
    postcode: string;
    acceptTerms: boolean;
    promotionCode?: string;
  }) => {
    const { firstName, lastName, address, address2, country, state, postcode, city, promotionCode } = fields;

    await updateCustomerDetails(String(userId), {
      firstName,
      lastName,
      address,
      address2,
      country,
      state,
      city,
      zip: postcode,
    });

    const promoCode = isEmpty(promotionCode) ? undefined : promotionCode;
    await subscribeToPlan(String(userId), copySubscriptionPlan, promoCode); // change the plan here
    dispatch(downloadSubscriptionDetails());

    onSubscriptionModalClose();

    toast.closeAll();
    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={"Thank you for confirming the upgrade, I will send you a follow-up email shortly."}
          onClick={() => {
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  return (
    <Stack justifyContent={"space-between"} height="100%" minHeight={"40rem"} spacing="0" width="100%">
      <Stack spacing="0" height="100%" justifyContent={"space-between"}>
        <Stack height="100%" spacing="1rem" bgColor={"primary.default"} py="2rem" color="white">
          <Center px={isMobile ? "1rem" : "5rem"}>
            <Text fontWeight={"semibold"} fontSize="xl">
              Step 2 of 2: Upgrade To Professional
            </Text>
          </Center>
          <Text fontSize="md" width="100%" textAlign="center" px={isMobile ? "1rem" : "5rem"}>
            Get started with the Automated Analyst for Only{" "}
            <Text fontWeight={"semibold"} as="span" color="#ffc070">
              USD $125
            </Text>{" "}
            /month.
          </Text>
          <Text fontSize="md" width="100%" textAlign="center" px={isMobile ? "1rem" : "5rem"}>
            For our Business, Premium and Enterprise subscriptions, including access to FactSet data, please press{" "}
            <Text
              cursor="pointer"
              fontWeight={"semibold"}
              as="span"
              color="#ffc070"
              onClick={() => window.open("https://charliai.com/pricing/", "_blank")}>
              here
            </Text>{" "}
            for more details.
          </Text>
          <Stack direction={"row"} justifyContent="end" px={isMobile ? ".5rem" : "1rem"}>
            <HStack align="center" spacing="1rem">
              <Image src={nasdaqLogo} height="2rem" alt="NASDAQ Logo" />
              <Image src={tmxLogo} height="2.5rem" alt="TMX Logo" />
            </HStack>
          </Stack>
        </Stack>
        <Box height="100%" p="2rem">
          <SubscribeForm onSubmit={onSubscribeFormSubmit} />
        </Box>
        {!isMobile && (
          <HStack position="absolute" bottom="2rem" right="2rem" align={"center"} spacing="1rem">
            <Image src={soc2logo} height="2.7rem" />
            <Image src={verifiedShield} height="2.5rem" />
          </HStack>
        )}
      </Stack>
    </Stack>
  );
};
