import React from "react";
import { Stack, Box, Center } from "@chakra-ui/react";
import type { BackgroundProps } from "@chakra-ui/react";
import { motion } from "framer-motion";

const bounce = {
  animate: {
    scale: [1, 1.4, 1],
    opacity: [0.4, 1, 0.4],
  },
  transition: {
    duration: 1,
    ease: "easeInOut",
    loop: Infinity,
  },
};

interface Props {
  color?: BackgroundProps["backgroundColor"];
  size?: "small" | "normal";
  height?: string;
}

export const TypingIndicator = ({ color = "#95ccd1", size = "normal", height = "unset" }: Props) => {
  const sizeMap = size === "small" ? "0.3rem" : "0.5rem";
  const MotionBox = motion(Box);

  return (
    <Center height={height}>
      <Stack className="ch-progress-indicator-cell" aria-label="Charli is typing" direction="row" spacing={sizeMap}>
        {["0s", "0.1s", "0.3s"].map((delay, index) => {
          return (
            <MotionBox
              key={index}
              cursor="not-allowed"
              rounded="full"
              backgroundColor={color}
              width={sizeMap}
              height={sizeMap}
              boxShadow="none"
              flexGrow={0}
              initial={{ opacity: 0.4 }}
              {...bounce}
              transition={{ ...bounce.transition, delay: parseFloat(delay) }}
            />
          );
        })}
      </Stack>
    </Center>
  );
};
