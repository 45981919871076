import { prependProxyBaseUrl, request } from "api/request";
import type { ConfiguredWorkflowCreationPayload } from "types/configuredWorkflows";
import { ConfiguredWorkflows } from "api/configuredWorkflows/models/GetConfiguredWorkflows";
import { ConfiguredWorkflow } from "api/configuredWorkflows/models/ConfiguredWorkflow";
import { GetRegisteredHandlers } from "./models/registeredHandlers/GetRegisteredHandlers";

const CONFIGURED_WORKFLOWS_ENDPOINT = prependProxyBaseUrl("/api/configured-workflows");

export async function getConfiguredWorkflows(): Promise<ConfiguredWorkflows> {
  const response = await request().url(`${CONFIGURED_WORKFLOWS_ENDPOINT}`).get().json();
  const maybeConfiguredWorkflows = ConfiguredWorkflows.validate(response);

  if (maybeConfiguredWorkflows.success) {
    return maybeConfiguredWorkflows.value;
  } else {
    console.error(maybeConfiguredWorkflows);
    throw new Error(maybeConfiguredWorkflows.message);
  }
}

export async function getConfiguredWorkflow(id: string): Promise<ConfiguredWorkflow> {
  const response = await request().url(`${CONFIGURED_WORKFLOWS_ENDPOINT}/${id}`).get().json();
  const maybeConfiguredWorkflow = ConfiguredWorkflow.validate(response);

  if (maybeConfiguredWorkflow.success) {
    return maybeConfiguredWorkflow.value;
  } else {
    console.error(maybeConfiguredWorkflow);
    throw new Error(maybeConfiguredWorkflow.message);
  }
}

export async function deleteConfiguredWorkflow(id: string) {
  await request().url(`${CONFIGURED_WORKFLOWS_ENDPOINT}/${id}`).delete().res();

  return { id };
}

export async function postConfiguredWorkflow(payload: ConfiguredWorkflowCreationPayload): Promise<ConfiguredWorkflow> {
  return await request().url(`${CONFIGURED_WORKFLOWS_ENDPOINT}`).post(payload).json();
}

export async function putConfiguredWorkflow(payload: ConfiguredWorkflowCreationPayload): Promise<ConfiguredWorkflow> {
  return await request().url(`${CONFIGURED_WORKFLOWS_ENDPOINT}`).put(payload).json();
}

export async function approveConfiguredWorkflow(configuredWorkflowId: string, userId: string): Promise<ConfiguredWorkflow> {
  return await request().url(`${CONFIGURED_WORKFLOWS_ENDPOINT}/${configuredWorkflowId}/approvals/${userId}`).put().json();
}

export async function unapproveConfiguredWorkflow(configuredWorkflowId: string, userId: string): Promise<ConfiguredWorkflow> {
  return await request().url(`${CONFIGURED_WORKFLOWS_ENDPOINT}/${configuredWorkflowId}/approvals/${userId}`).delete().json();
}

export async function updateWorkflowState(configuredWorkflowId: string, state: string, fromVersion?: string): Promise<ConfiguredWorkflow> {
  return await request().url(`${CONFIGURED_WORKFLOWS_ENDPOINT}/${configuredWorkflowId}/state`).patch({ state, fromVersion }).json();
}

export async function getRegisteredHandlers(filter?: string): Promise<GetRegisteredHandlers> {
  const response = await request()
    .url(`${CONFIGURED_WORKFLOWS_ENDPOINT}/registered-handlers`)
    .query({ ...(filter && { filter }) })
    .get()
    .json();
  const validate = GetRegisteredHandlers.validate(response);

  if (validate.success) {
    return validate.value;
  } else {
    console.error(validate);
    throw new Error(validate.message);
  }
}
