import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  Input,
  Stack,
  Text,
  useToast,
  Image,
  useBreakpointValue,
  HStack,
  Link,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import { sendPasswordResetEmail } from "api/user";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CopyrightNotice, ToastMessageContent } from "screens/common/components";
import { TabTitle } from "screens/common/components/TabTitle";
import { GoogleSignInButton } from ".";
import logo from "screens/common/static/logos/charli-logo-full.svg";
import verifiedShield from "screens/common/static/misc/verified_shield.svg";
import soc2logo from "screens/common/static/logos/soc2_logo.png";
import { useButtonProps } from "hooks";

interface Props {
  onSubmit: (fields: any) => void;
  showEmailForm: boolean;
}

export const LoginForm = (props: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
  } = useForm<{
    username: string;
    password: string;
  }>();
  const [showResetPassword, setShowResetPassword] = useState(false);
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const commonButtonProps = useButtonProps("md", "cta");
  const [hasWebsocketSupport, setHasWebsocketSupport] = useState(true);

  const checkWebSocketSupport = () => {
    if ("WebSocket" in window) {
      setHasWebsocketSupport(true);
    } else {
      setHasWebsocketSupport(false);
    }
  };

  useEffect(() => {
    checkWebSocketSupport();
  }, []);

  const handleResetPassword = async () => {
    const email = getValues("username");
    if (email === "") {
      setError("username", {}, { shouldFocus: true });
    } else {
      clearErrors("username");
      await sendPasswordResetEmail(email);

      toast({
        render: ({ onClose }) => (
          <ToastMessageContent
            message={`An email has been sent to your ${email} account. Please use the link in it to reset your password then try logging in again.`}
            onClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        ),
        duration: 10000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <Stack justifyContent={"space-between"} width={"100%"} height="100vh">
      <TabTitle title={`Charli > ${showResetPassword ? "Reset Password" : "Login"}`} />
      <Box>
        {!hasWebsocketSupport && (
          <Center>
            <Alert status="error" width="100%" justifyContent={"center"}>
              <AlertIcon />
              <AlertTitle>WebSockets Required:</AlertTitle>
              <AlertDescription>Your browser does not support WebSockets. Please try using a different browser.</AlertDescription>
            </Alert>
          </Center>
        )}
        <Center pt="7rem" pb="4rem">
          <Image src={logo} alt="Charli logo" width="12rem" cursor="pointer" />
        </Center>
        <Center>
          <Stack width="100%" justifyContent={"space-between"} spacing="1rem" px="2rem" maxWidth={["90%", "25rem", "20rem"]}>
            <form onSubmit={handleSubmit(props.onSubmit)}>
              <Stack spacing="1rem" width="100%">
                <FormControl isInvalid={!!errors.username} pb=".5rem">
                  <Input
                    _hover={{}}
                    size="sm"
                    {...register("username", { required: true })}
                    id="login-username"
                    borderColor={errors.username ? "red.500" : "gray.700"}
                    name="username"
                    type="username"
                    placeholder={showResetPassword ? "Email Address to Reset" : "Email Address"}
                    autoComplete="username"
                  />
                  {showResetPassword && (
                    <Text
                      color="gray.400"
                      textAlign={"end"}
                      cursor={"pointer"}
                      width="100%"
                      id="link-button"
                      fontWeight="light"
                      fontSize="xs"
                      onClick={() => {
                        setShowResetPassword((value) => !value);
                      }}>
                      Back to login
                    </Text>
                  )}
                  {errors.username && <FormErrorMessage>Email is required.</FormErrorMessage>}
                </FormControl>
                <FormControl isInvalid={!!errors.password} hidden={showResetPassword} pb=".5rem">
                  <Input
                    _hover={{}}
                    size="sm"
                    {...register("password", { required: true })}
                    id="login-password"
                    type="password"
                    placeholder="Password"
                    autoComplete="current-password"
                    borderColor={errors.password ? "red.500" : "gray.700"}
                    fontSize="sm"
                    boxShadow="none"
                  />
                  {!showResetPassword && (
                    <Text
                      color="gray.400"
                      textAlign={"end"}
                      cursor={"pointer"}
                      width="100%"
                      id="link-button"
                      fontWeight="light"
                      fontSize="xs"
                      onClick={() => {
                        setShowResetPassword((value) => !value);
                      }}>
                      Forgot password?
                    </Text>
                  )}
                  {errors.password && <FormErrorMessage>Password is required.</FormErrorMessage>}
                </FormControl>

                {!showResetPassword && (
                  <Center>
                    <Button
                      {...commonButtonProps}
                      mt="2rem"
                      fontWeight={"normal"}
                      bgColor={"#81c34b"}
                      borderColor={"#81c34b"}
                      width="100%"
                      borderRadius={"full"}
                      id={"login-submit"}
                      type="submit"
                      isDisabled={!hasWebsocketSupport}>
                      Sign in with email
                    </Button>
                  </Center>
                )}
                {showResetPassword && (
                  <Center>
                    <Button
                      {...commonButtonProps}
                      fontWeight={"normal"}
                      bgColor={"#81c34b"}
                      borderColor={"#81c34b"}
                      width="100%"
                      borderRadius={"full"}
                      id="reset-password"
                      type="submit"
                      onClick={handleResetPassword}>
                      Reset Password
                    </Button>
                  </Center>
                )}
              </Stack>
            </form>
            <Stack spacing={"1rem"}>
              <Center>
                <Text position="relative" w="3rem" textAlign="center" fontSize="sm">
                  or
                </Text>
              </Center>
              <GoogleSignInButton variant={"signin"} />
            </Stack>
          </Stack>
        </Center>
      </Box>

      <Stack spacing="3rem">
        <Text textAlign="center" fontSize="xs" fontWeight="semibold">
          Not a user?{" "}
          <Link href="/register" fontWeight="bold" color="primary.default">
            Register here
          </Link>
        </Text>
        <Stack pb="1.7rem">
          <Text textAlign="center" fontSize="11px" fontWeight="light" textColor="gray.500">
            By signing up you agree to Charli's{" "}
            <Link href="https://charli.ai/terms-of-service/" isExternal textDecoration={"underline"}>
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link href="https://charli.ai/privacy-policy/" isExternal textDecoration={"underline"}>
              Privacy Policy
            </Link>
          </Text>
          <Box textAlign="center">
            <CopyrightNotice />
          </Box>
        </Stack>
      </Stack>
      {!isMobile && (
        <HStack position="absolute" bottom="2rem" right="2rem" align={"center"} spacing="1rem">
          <Image src={soc2logo} height="3.2rem" />
          <Image src={verifiedShield} height="2.9rem" />
        </HStack>
      )}
    </Stack>
  );
};
