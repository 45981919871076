import { createSlice } from "@reduxjs/toolkit";
import uniq from "lodash/uniq";
import type { ConfiguredWorkflow } from "types/configuredWorkflows";
import {
  approveConfiguredWorkflow,
  createConfiguredWorkflow,
  deleteConfiguredWorkflowAction,
  downloadConfiguredWorkflows,
  downloadConfiguredWorkflow,
  unapproveConfiguredWorkflow,
  updateConfiguredWorkflow,
  updateConfiguredWorkflowState,
  addApprovedConfiguredWorkflow,
  removeApprovedConfiguredWorkflow,
} from "./operations";

interface ConfiguredWorkflowState {
  isLoading: boolean;
  order: string[];
  configuredWorkflows: { [key: string]: ConfiguredWorkflow };
  isLoadingWorkflowConfig: Record<string, boolean>;
}

const initialState: ConfiguredWorkflowState = {
  isLoading: false,
  order: [],
  configuredWorkflows: {},
  isLoadingWorkflowConfig: {},
};

export const { actions, reducer } = createSlice({
  name: "configuredWorkflow",
  initialState,
  reducers: {
    flush() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteConfiguredWorkflowAction.pending, (state, action) => {
      state.isLoadingWorkflowConfig[action.meta.arg.id] = true;
    });
    builder.addCase(deleteConfiguredWorkflowAction.rejected, (state, action) => {
      state.isLoadingWorkflowConfig[action.meta.arg.id] = false;
    });
    builder.addCase(deleteConfiguredWorkflowAction.fulfilled, (state, action) => {
      state.isLoadingWorkflowConfig[action.meta.arg.id] = false;

      const { id } = action.payload;
      delete state.configuredWorkflows[id];
      state.order = state.order.filter((configId) => configId !== id);
    });

    builder.addCase(downloadConfiguredWorkflows.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(downloadConfiguredWorkflows.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(downloadConfiguredWorkflows.fulfilled, (state, action) => {
      state.isLoading = false;

      const hashWorkflows = {};
      const workflowsIds: string[] = [];
      action.payload.forEach((cw) => {
        hashWorkflows[cw.id] = true;
        workflowsIds.push(cw.id);

        if (state.configuredWorkflows[cw.id]) {
          Object.assign(state.configuredWorkflows[cw.id], cw);
        } else {
          state.configuredWorkflows[cw.id] = cw;
        }
      });

      state.order.forEach((id) => {
        if (!hashWorkflows[id]) {
          delete state.configuredWorkflows[id];
        }
      });

      state.order = workflowsIds;
    });

    builder.addCase(downloadConfiguredWorkflow.pending, (state, action) => {
      state.isLoadingWorkflowConfig[action.meta.arg.id] = true;
    });
    builder.addCase(downloadConfiguredWorkflow.rejected, (state, action) => {
      state.isLoadingWorkflowConfig[action.meta.arg.id] = false;
    });
    builder.addCase(downloadConfiguredWorkflow.fulfilled, (state, action) => {
      state.isLoadingWorkflowConfig[action.meta.arg.id] = false;

      const { payload: configuredWorkflow } = action;
      state.configuredWorkflows[configuredWorkflow.id] = configuredWorkflow;
      state.order = uniq([configuredWorkflow.id, ...state.order]);
    });

    builder.addCase(addApprovedConfiguredWorkflow.fulfilled, (state, action) => {
      const { workflowConfigId, userId, userName } = action.payload;
      const configuredWorkflow = state.configuredWorkflows[workflowConfigId];

      if (configuredWorkflow) {
        configuredWorkflow.approvedByUsers = [
          ...(configuredWorkflow.approvedByUsers ? configuredWorkflow.approvedByUsers : []),
          { id: userId, name: userName },
        ];
      }
    });

    builder.addCase(removeApprovedConfiguredWorkflow.fulfilled, (state, action) => {
      const { workflowConfigId, userId } = action.payload;
      const configuredWorkflow = state.configuredWorkflows[workflowConfigId];

      if (configuredWorkflow) {
        configuredWorkflow.approvedByUsers = configuredWorkflow.approvedByUsers
          ? configuredWorkflow.approvedByUsers?.filter((user) => user.id !== userId)
          : undefined;
      }
    });

    builder.addCase(createConfiguredWorkflow.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createConfiguredWorkflow.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createConfiguredWorkflow.fulfilled, (state, action) => {
      state.isLoading = false;
      const { payload: newConfiguredWorkflow } = action;

      state.configuredWorkflows[newConfiguredWorkflow.id] = newConfiguredWorkflow;
      state.order = [newConfiguredWorkflow.id, ...state.order];
    });

    builder.addCase(updateConfiguredWorkflow.pending, (state, action) => {
      if (action.meta.arg.id) {
        state.isLoadingWorkflowConfig[action.meta.arg.id] = true;
      }
    });
    builder.addCase(updateConfiguredWorkflow.rejected, (state, action) => {
      if (action.meta.arg.id) {
        state.isLoadingWorkflowConfig[action.meta.arg.id] = false;
      }
    });
    builder.addCase(updateConfiguredWorkflow.fulfilled, (state, action) => {
      state.isLoadingWorkflowConfig[action.payload.id] = false;
      const { payload: updatedConfiguredWorkflow } = action;

      state.configuredWorkflows[updatedConfiguredWorkflow.id] = updatedConfiguredWorkflow;
    });

    builder.addCase(approveConfiguredWorkflow.pending, (state, action) => {
      state.isLoadingWorkflowConfig[action.meta.arg] = true;
    });
    builder.addCase(approveConfiguredWorkflow.rejected, (state, action) => {
      state.isLoadingWorkflowConfig[action.meta.arg] = false;
    });
    builder.addCase(approveConfiguredWorkflow.fulfilled, (state, action) => {
      state.isLoadingWorkflowConfig[action.meta.arg] = false;
      const { payload: approvedConfiguredWorkflow } = action;

      if (!approvedConfiguredWorkflow) {
        return;
      }

      state.configuredWorkflows[approvedConfiguredWorkflow.id] = approvedConfiguredWorkflow;
    });

    builder.addCase(unapproveConfiguredWorkflow.pending, (state, action) => {
      state.isLoadingWorkflowConfig[action.meta.arg] = true;
    });
    builder.addCase(unapproveConfiguredWorkflow.rejected, (state, action) => {
      state.isLoadingWorkflowConfig[action.meta.arg] = false;
    });
    builder.addCase(unapproveConfiguredWorkflow.fulfilled, (state, action) => {
      state.isLoadingWorkflowConfig[action.meta.arg] = false;
      const { payload: unapprovedConfiguredWorkflow } = action;

      if (!unapprovedConfiguredWorkflow) {
        return;
      }

      state.configuredWorkflows[unapprovedConfiguredWorkflow.id] = unapprovedConfiguredWorkflow;
    });

    builder.addCase(updateConfiguredWorkflowState.pending, (state, action) => {
      state.isLoadingWorkflowConfig[action.meta.arg.configuredWorkflowId] = true;
    });
    builder.addCase(updateConfiguredWorkflowState.rejected, (state, action) => {
      state.isLoadingWorkflowConfig[action.meta.arg.configuredWorkflowId] = false;
    });
    builder.addCase(updateConfiguredWorkflowState.fulfilled, (state, action) => {
      state.isLoadingWorkflowConfig[action.meta.arg.configuredWorkflowId] = false;
      const { payload } = action;

      state.configuredWorkflows[payload.id] = payload;
    });
  },
});

export default reducer;
