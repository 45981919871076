import { Stack } from "@chakra-ui/react";
import { useCustomTheme, useEntitlementKey, useUserSubscriptionDetails } from "hooks";
import { useFeatureUsage } from "hooks/useFeatureUsage";
import type { FunctionComponent } from "react";
import React from "react";
import { UpgradePlanButton } from "screens/panels/settings/tabs/subscription/SubscriptionUpgrade";
import { InteractionBarFindInput } from "./InteractionBarFindInput";
import { NotificationsMenu } from "./NotificationsMenu";
import { ProfileSettingsMenu } from "./ProfileSettingsMenu";

interface Props {
  initialText?: string;
}

export const InteractionBar: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ initialText = "" }) => {
  const { isEmbeddedApp } = useCustomTheme();
  const hasSearch = useEntitlementKey("ui_enable_search");
  const projectUsage = useFeatureUsage("create_due_diligence_project");
  const subscriptionInfo = useUserSubscriptionDetails();

  return (
    <Stack direction="row" width="100%" justifyContent={"flex-end"} p="0.5rem" align="center" spacing="1em">
      {hasSearch && <InteractionBarFindInput initialText={initialText} />}
      <NotificationsMenu />
      {subscriptionInfo.internalPlanName === "free_trial" && (
        <UpgradePlanButton
          tooltip={`You can analyze up to ${projectUsage?.allowed} projects on your current plan. Upgrade to continue receiving the benefits of Autopilot.`}
          buttonSize="xs"
        />
      )}
      {!isEmbeddedApp && <ProfileSettingsMenu />}
    </Stack>
  );
};
