import type { ReactNode } from "react";
import React, { useContext, useMemo, useCallback, useRef, useState, useEffect } from "react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "../../panels/components/PanelStep";
import { PanelView } from "../../panels/components/PanelView";
import { useAddToCharliContext } from "../../panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { useContents, useFeatureFlags, useTileProps } from "hooks";
import {
  Text,
  Stack,
  Box,
  Center,
  useColorModeValue,
  Image,
  useToast,
  useDisclosure,
  Divider,
  useOutsideClick,
  useBreakpointValue,
} from "@chakra-ui/react";
import { LandingTitle } from "screens/landing/components";
import { SmallActionButton } from "../contentCanvas/cell/SmallActionButton";
import { SmallActionConfidenceScoreBadge } from "../contentCanvas/cell/SmallActionConfidenceScoreBadge";
import { ContentFilterContext } from "../contentCanvas/body/ContentFilterContext";
import { ContentPreview, onOpenContent } from "./previewSection/ContentPreview";
import { TagInput } from "screens/common/components/Tags/TagInput";
import { HiOutlineExternalLink } from "react-icons/hi";
import { ToastMessageContent } from "screens/common/components";
import verifiedShield from "screens/common/static/misc/verified_shield.svg";
import { MarkupTextViewer } from "./sections/MarkupTextViewer";
import { AnswerStatus } from "types/question";
import { sendMessage } from "state/websocket/operations";
import { useDispatch } from "react-redux";
import type { RequestEntities } from "types/charliui";
import { ConversationContext } from "screens/thread/ConversationContext";
import { AiOutlineDelete } from "react-icons/ai";
import { VerifyDeleteModal } from "./VerifyDeleteModal";
import verifiedShieldGray from "screens/common/static/misc/verified_shield_gray.svg";
import { HighlightItemActionsDelete } from "screens/panels/highlights/HighlightItemActionsDelete";
import { VerifiedAIPanelAttribution } from "./VerifiedAIPanelAttribution";
import { Popover } from "react-tiny-popover";
import { ProjectQuestionHotlinks } from "screens/collection/views/ProjectActions/ProjectQuestionHotlinks";
import { focusBadgeColor } from "screens/collection/views/AutocompleteInput";
import { useLocation } from "react-router-dom";
import { ProjectThumbHotlinks } from "screens/collection/views/ProjectActions/ProjectThumbHotlinks";

export const VerifiedAIPanel = () => {
  const { isVerifiedAIPanelOpen, onVerifiedAIPanelClose } = useAddToCharliContext();
  const iconColor = useColorModeValue("gray.500", "gray.400");
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const commonTileProps = useTileProps();
  const { selectedResult, searchText } = useContext(ContentFilterContext);
  const attributionContentItems = useContents(selectedResult.attributionMetadataIds);
  const dispatch = useDispatch();
  const toast = useToast();
  const { setConversationId, onConversationOpen } = useContext(ConversationContext);
  const { isOpen: isOpenConfirmDelete, onOpen: onOpenConfirmDelete, onClose: onCloseConfirmDelete } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { pathname } = useLocation();

  const { isOpen: isThumbsUpOpen, onToggle: onThumbsUpToggle, onClose: onThumbsUpClose } = useDisclosure();
  const { isOpen: isThumbsDownOpen, onToggle: onThumbsDownToggle, onClose: onThumbsDownClose } = useDisclosure();

  const toggleThumbsUp = useCallback(() => {
    onThumbsUpToggle();
    onThumbsDownClose();
  }, [onThumbsUpToggle, onThumbsDownClose]);

  const toggleThumbsDown = useCallback(() => {
    onThumbsDownToggle();
    onThumbsUpClose();
  }, [onThumbsDownToggle, onThumbsUpClose]);

  const updateAnswerStatus = useCallback(
    (reason?: string) => {
      if (!selectedResult.conversationId) {
        return;
      }
      const entities: RequestEntities = [];
      selectedResult.resultId &&
        entities.push({ entity: `${selectedResult.isQuestion ? "question_id" : "answer_id"}`, value: selectedResult.resultId });
      entities.push({ entity: `${selectedResult.isQuestion ? "question_status" : "answer_status"}`, value: AnswerStatus.unpinned });
      reason && entities.push({ entity: "content_delete_reason", value: reason });
      selectedResult.collectionId && entities.push({ entity: "collection_id", value: selectedResult.collectionId });

      dispatch(
        sendMessage({
          conversationId: selectedResult.conversationId,
          intent: `${selectedResult.isQuestion ? "/manage_question" : "/manage_answer"}`,
          entities: entities,
        })
      );
      onVerifiedAIPanelClose();
      toast({
        render: ({ onClose }) => (
          <ToastMessageContent
            message={`I'll remove this result from your project now. You can open the conversation I started about it by clicking this message`}
            onClick={() => {
              selectedResult.conversationId && setConversationId(selectedResult.conversationId);
              onConversationOpen();
              onClose();
            }}
            onClose={onClose}
          />
        ),
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    },
    [
      selectedResult.conversationId,
      selectedResult.resultId,
      selectedResult.isQuestion,
      selectedResult.collectionId,
      dispatch,
      onVerifiedAIPanelClose,
      toast,
      setConversationId,
      onConversationOpen,
    ]
  );

  const panelHeader: ReactNode = useMemo(
    () => (
      <Stack spacing="0" className="ch-verified-panel">
        <Box bgColor={selectedResult.verifiedStatus === "verified" ? "#009AD8" : "#9F9F9F"} height="1rem" width="100%" />
        <Stack direction="row" justifyContent={"space-between"} backgroundColor={"gray.100"} px={"1.5rem"} py="1rem">
          <Stack width="100%">
            <Text fontSize={"md"} fontWeight="semibold">
              {`Verified by AI`}
            </Text>
            <Text fontSize={"sm"} fontWeight="normal">
              Charli AI performs detail fact check analysis on the AI results and can also indicate if there is insufficient information to
              provide an answer. This is important to ensure that relevant and accurate results are provided. This page allows you to double
              check the results and make suggestions to improve overall accuracy.
            </Text>
          </Stack>
          <Center>
            <Image
              src={selectedResult.verifiedStatus === "verified" ? verifiedShield : verifiedShieldGray}
              width={["2rem", "2.5rem", "3rem"]}
              ml="1.5rem"
            />
          </Center>
        </Stack>
      </Stack>
    ),
    [selectedResult]
  );

  const confidenceValue = selectedResult?.confidence ? Math.round(selectedResult?.confidence * 100) : "--";
  const [showPopover, setShowPopover] = useState(false);
  const mainRef = useRef<HTMLDivElement | null>(null);
  const popRef = useRef<HTMLDivElement | null>(null);
  const { show_answer_actions_popover: showActionPopover } = useFeatureFlags();

  useOutsideClick({
    ref: popRef!,
    handler: () => {
      setShowPopover(false);
    },
  });

  useEffect(() => {
    onVerifiedAIPanelClose();
  }, [pathname, onVerifiedAIPanelClose]);

  const panelContent: ReactNode = useMemo(
    () => (
      <Stack spacing={isMobile ? "2rem" : "1rem"} mb="1rem">
        <Box {...commonTileProps} borderWidth={isMobile ? "0" : "1px"} cursor="default" p={isMobile ? "0" : "1rem"} height="unset">
          <LandingTitle text="AI Results" underline color={"primary.default"} pb={isMobile ? "0" : "1rem"} />
          <Stack className="ch-verified-details" justifyContent={"flex-start"} spacing="1rem" width="100%" pt=".5rem">
            <Stack spacing="1rem">
              <Stack direction="row" width="100%" justifyContent={"space-between"}>
                <Box ref={mainRef}>
                  <Popover
                    parentElement={mainRef?.current || undefined}
                    isOpen={showPopover}
                    positions={["top"]}
                    padding={10}
                    align="start"
                    transformMode="relative"
                    onClickOutside={() => setShowPopover(false)}
                    content={() => <ProjectQuestionHotlinks question={selectedResult.questionText} focus={selectedResult.focus} />}>
                    <Text
                      borderWidth={"1px"}
                      borderColor={"transparent"}
                      borderRadius={"full"}
                      px=".5rem"
                      _hover={{
                        borderColor: "primary.default",
                        borderStyle: "dashed",
                      }}
                      color={textColor}
                      cursor={showActionPopover ? "pointer" : "default"}
                      onClick={() => showActionPopover && setShowPopover(true)}
                      width="100%"
                      className="ch-qa-result-question"
                      fontWeight="semibold"
                      fontSize="md">
                      {selectedResult.questionText}
                    </Text>
                  </Popover>
                </Box>
                {selectedResult.focus && (
                  <Box
                    borderRadius={"4px"}
                    height="1.2rem"
                    lineHeight={"1.2rem"}
                    px="5px"
                    fontSize={"10px"}
                    color="gray.600"
                    background={focusBadgeColor[selectedResult.focus.toUpperCase()]}>
                    {selectedResult.focus.toUpperCase()}
                  </Box>
                )}
              </Stack>
              <Stack direction={isMobile ? "column" : "row"} justifyContent={"flex-start"} spacing="1rem" width="100%" pt=".5rem">
                <Stack direction={isMobile ? "row" : "column"} align={"center"} spacing="1rem">
                  <SmallActionConfidenceScoreBadge
                    score={confidenceValue}
                    scoreTooltip={`Confidence score: ${confidenceValue}%`}
                    scoreBgColor={iconColor}
                  />
                  <Stack direction="row" spacing="0">
                    <ProjectThumbHotlinks
                      action="up"
                      resultId={selectedResult.resultId}
                      collectionId={selectedResult.collectionId}
                      isOpen={isThumbsUpOpen}
                      onToggle={toggleThumbsUp}
                      onClose={onThumbsUpClose}
                    />
                    <ProjectThumbHotlinks
                      action="down"
                      resultId={selectedResult.resultId}
                      collectionId={selectedResult.collectionId}
                      isOpen={isThumbsDownOpen}
                      onToggle={toggleThumbsDown}
                      onClose={onThumbsDownClose}
                    />
                  </Stack>
                  {selectedResult.resultType === "answer" && (
                    <Box>
                      <SmallActionButton
                        iconTypeName={AiOutlineDelete}
                        iconSize="1.4rem"
                        onClick={onOpenConfirmDelete}
                        tooltip={`Remove result`}
                      />
                      <VerifyDeleteModal
                        title="Confirm Remove Result"
                        message="Please confirm that you would like to remove this result and select one of the reasons below. The reason will be used in future AI learning to improve results."
                        onHandleConfirmDelete={(reason: string) => updateAnswerStatus(reason)}
                        onClose={onCloseConfirmDelete}
                        isOpen={isOpenConfirmDelete}
                      />
                    </Box>
                  )}
                  {selectedResult.resultType === "highlight" && (
                    <Box>
                      <HighlightItemActionsDelete highlightRecordId={selectedResult.resultId} onClose={onVerifiedAIPanelClose} />
                    </Box>
                  )}
                </Stack>
                <Stack direction="column" spacing="1rem" width="100%">
                  <MarkupTextViewer searchText={searchText} markupText={selectedResult.resultText} />
                  <Stack direction="row">
                    <Text className="ch-qa-result-answer" fontSize="sm" fontWeight="normal" color={textColor}>
                      Confidence:
                    </Text>
                    <Text className="ch-qa-result-answer" fontSize="sm" fontWeight="normal" color={textColor}>
                      {selectedResult.confidence ? selectedResult.confidence.toFixed(5) : "--"}
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Box>
        {isMobile && <Divider />}
        {attributionContentItems && (
          <Box {...commonTileProps} borderWidth={isMobile ? "0" : "1px"} cursor="default" p={isMobile ? "0" : "1rem"} height="unset">
            <LandingTitle
              text={`AI Attribution${attributionContentItems.length > 1 ? "s" : ""}`}
              underline
              color={"primary.default"}
              pb="1rem"
            />
            <Stack spacing="1rem">
              {attributionContentItems.map((itemContent, index) => (
                <Box key={index}>
                  <Stack
                    className="ch-verified-attribution"
                    direction="column"
                    spacing="1rem"
                    width="100%"
                    key={`attribution-item-${index}`}>
                    <Stack direction={isMobile ? "column" : "row"} spacing={isMobile ? "1rem" : "2rem"}>
                      <Box {...commonTileProps} cursor="default" p="5px" height="unset" width={isMobile ? "6rem" : "unset"}>
                        <ContentPreview
                          answerId={selectedResult.resultId}
                          itemContent={itemContent}
                          maxHeight="8rem"
                          thumbnailHeight="100px"
                          thumbnailWidth="100px"
                        />
                      </Box>
                      <Stack direction="column" spacing="1rem">
                        <Stack direction="row" align="center" spacing="1rem">
                          <Text className="ch-qa-result-answer" fontSize="sm" fontWeight="semibold" color={textColor} pr=".5rem">
                            {itemContent.name}
                          </Text>
                          <SmallActionButton
                            iconTypeName={HiOutlineExternalLink}
                            onClick={() => {
                              if (!itemContent) {
                                return;
                              } else {
                                onOpenContent(itemContent, selectedResult.resultId);
                              }
                            }}
                            tooltip={"Open link in new tab"}
                          />
                        </Stack>
                        {!isMobile && (
                          <>
                            <Box>
                              <TagInput tags={itemContent.autoTags} tagType="auto" hideInput allowUpdate={false} allowDelete={false} />
                            </Box>
                            {itemContent.sourceIntegrationUrn && (
                              <VerifiedAIPanelAttribution sourceIntegrationUrn={itemContent.sourceIntegrationUrn} />
                            )}
                          </>
                        )}
                      </Stack>
                    </Stack>
                    {isMobile && (
                      <Stack spacing="0.5rem">
                        <Box>
                          <TagInput tags={itemContent.autoTags} tagType="auto" hideInput allowUpdate={false} allowDelete={false} />
                        </Box>
                        {itemContent.sourceIntegrationUrn && (
                          <VerifiedAIPanelAttribution sourceIntegrationUrn={itemContent.sourceIntegrationUrn} />
                        )}
                      </Stack>
                    )}
                  </Stack>
                  {index !== attributionContentItems.length - 1 && <Divider />}
                </Box>
              ))}
            </Stack>
          </Box>
        )}
      </Stack>
    ),
    [
      isMobile,
      commonTileProps,
      showPopover,
      textColor,
      showActionPopover,
      selectedResult.questionText,
      selectedResult.focus,
      selectedResult.resultId,
      selectedResult.collectionId,
      selectedResult.resultType,
      selectedResult.resultText,
      selectedResult.confidence,
      confidenceValue,
      iconColor,
      isThumbsUpOpen,
      toggleThumbsUp,
      onThumbsUpClose,
      isThumbsDownOpen,
      toggleThumbsDown,
      onThumbsDownClose,
      onOpenConfirmDelete,
      onCloseConfirmDelete,
      isOpenConfirmDelete,
      onVerifiedAIPanelClose,
      searchText,
      attributionContentItems,
      updateAnswerStatus,
    ]
  );

  return (
    <PanelView
      isOpen={isVerifiedAIPanelOpen}
      onClose={() => {
        onVerifiedAIPanelClose();
      }}
      panelHeader={panelHeader}>
      <Wizard>
        <PanelStep>{panelContent}</PanelStep>
      </Wizard>
    </PanelView>
  );
};
