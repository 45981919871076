import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  useColorModeValue,
  Badge,
  useBreakpointValue,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
} from "@chakra-ui/react";
import { getStatusColor } from "screens/common/components/WorkflowSummary";
import { getWorkflowsAdmin } from "api/workflowsAdmin";
import capitalize from "lodash/capitalize";
import type { Workflow } from "types/workflows/workflow";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { WorkflowsFiltersContextProvider, useWorkflowsFiltersContext, WorkflowsFiltersPanel } from "screens/common/components";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { useInfiniteLoading } from "hooks";
import { calculateTimeDifference } from "screens/collection/components/utils";
import { uniq } from "lodash";
import { getUsersByIds } from "api/user";

const COLUMNS_WIDTH = 250;
const DATE_COLUMN_WIDTH = 400;
const DURATION_COLUMN_WIDTH = 200;
const STATUS_COLUMN_WIDTH = 180;
const PAGE_SIZE = 30;

const WorkflowsAdminBase = () => {
  const { usersInIds, usersOutIds, statusIn, intentsIn, collectionId } = useWorkflowsFiltersContext();
  const bgColor = useColorModeValue("gray.200", "gray.700");
  const titleColor = useColorModeValue("charli.lightGray", "gray.500");
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const [usersEmailsMap, setUsersEmailsMap] = useState<Record<string, string | undefined>>({});

  const loadItems = useCallback(
    async (token: string | null) => {
      const filters = {
        ...(usersInIds.length > 0 && { inUsersIds: usersInIds }),
        ...(usersOutIds.length > 0 && { outUsersIds: usersOutIds }),
        ...(statusIn.length > 0 && { inStatus: statusIn }),
        ...(intentsIn.length > 0 && { inIntents: intentsIn }),
        ...(collectionId && { collectionId }),
      };

      const res = await getWorkflowsAdmin({
        ...(token && { token }),
        limit: PAGE_SIZE,
        ...(Object.keys(filters).length > 0 && { filters }),
      });

      return { data: res.data, totalCount: res.totalCount, nextToken: res.nextToken };
    },
    [usersInIds, usersOutIds, statusIn, intentsIn, collectionId]
  );

  const {
    loading: isLoading,
    items,
    clear,
    lastMessageObserverRef,
    hasNextPage,
  } = useInfiniteLoading<Workflow>({
    loadItems,
  });

  const handleOnClick = (workflow: Workflow) => {
    navigate(`${workflow.id}${location.search}`);
  };

  useEffect(() => {
    clear();
  }, [usersInIds, usersOutIds, statusIn, intentsIn, clear]);

  useEffect(() => {
    const usersIds = uniq(items.map((item) => item.userId)).filter((userId) => !usersEmailsMap[userId]);

    if (usersIds.length === 0) {
      return;
    }

    getUsersByIds(usersIds)
      .then((users) => {
        const usersResponseMap = users.reduce((acc: Record<string, string>, user) => ({ ...acc, [user.id]: user.email }), {});

        setUsersEmailsMap((prev) => ({
          ...prev,
          ...usersIds.reduce((acc, userId) => ({ ...acc, [userId]: usersResponseMap[userId] ?? `N/A` }), {}),
        }));
      })
      .catch(console.error);
  }, [items, usersEmailsMap]);

  return (
    <Box width="100%" fontSize="sm" px={isMobile ? ".5rem" : "1rem"} pt={isMobile ? ".5rem" : "1rem"} mt="0!important">
      <WorkflowsFiltersPanel isLoading={isLoading} />
      {/* Table header */}
      <Box
        fontSize={"small"}
        borderBottomColor={bgColor}
        borderBottom={`2px solid`}
        mb={1}
        color={titleColor}
        display={"flex"}
        justifyContent="space-between">
        <Box flex={1}>Title</Box>
        <Box width={DATE_COLUMN_WIDTH}>Creation date</Box>
        <Box width={DURATION_COLUMN_WIDTH}>Duration</Box>
        <Box width={COLUMNS_WIDTH}>Created by</Box>
        <Box width={STATUS_COLUMN_WIDTH + 20}>Status</Box>
      </Box>

      {items.map((workflow) => {
        const initDate = new Date(workflow.creationDate);
        const endDate = workflow.completionDate ? new Date(workflow.completionDate) : new Date();
        const duration = calculateTimeDifference(initDate, endDate);

        return (
          <Flex
            key={workflow.id}
            fontSize="sm"
            onClick={() => handleOnClick(workflow)}
            p={1}
            _hover={{ bgColor }}
            role="button"
            justifyContent="space-between">
            <Popover trigger="hover" isLazy>
              <PopoverTrigger>
                <Box flex={1} overflow={"hidden"} whiteSpace="nowrap" textOverflow={"ellipsis"}>
                  {workflow.title}
                </Box>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverBody>
                  <Text>
                    Intent: <Text as={"em"}>{workflow.intent}</Text>
                  </Text>
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <Box width={DATE_COLUMN_WIDTH}>{moment(new Date(workflow.creationDate)).format("dddd, MMMM Do YYYY, h:mm:ss a")}</Box>
            <Box
              // Red color if the duration is greater than 30 minutes
              {...(Math.abs(endDate.getTime() - initDate.getTime()) > 30 * 60 * 1000 && {
                color: "red.500",
              })}
              width={DURATION_COLUMN_WIDTH}>
              {duration}
            </Box>
            <Popover trigger="hover" isLazy>
              <PopoverTrigger>
                <Box width={COLUMNS_WIDTH}>{workflow.userName}</Box>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverBody>
                  <Text>
                    User Email: <Text as={"em"}>{usersEmailsMap[workflow.userId] ?? "N/A"}</Text>
                  </Text>
                </PopoverBody>
              </PopoverContent>
            </Popover>

            <Box width={STATUS_COLUMN_WIDTH}>
              <Badge colorScheme={getStatusColor(workflow.status)}>{capitalize(workflow.status).split("_").join(" ")}</Badge>
            </Box>
          </Flex>
        );

        // return (
        //   <Popover key={workflow.id} trigger="hover" isLazy>
        //     <PopoverTrigger>
        //       <Flex
        //         fontSize="sm"
        //         onClick={() => handleOnClick(workflow)}
        //         p={1}
        //         _hover={{ bgColor }}
        //         role="button"
        //         justifyContent="space-between">
        //         <Box flex={1} overflow={"hidden"} whiteSpace="nowrap" textOverflow={"ellipsis"}>
        //           {workflow.title}
        //         </Box>
        //         <Box width={DATE_COLUMN_WIDTH}>{moment(new Date(workflow.creationDate)).format("dddd, MMMM Do YYYY, h:mm:ss a")}</Box>
        //         <Box
        //           // Red color if the duration is greater than 30 minutes
        //           {...(Math.abs(endDate.getTime() - initDate.getTime()) > 30 * 60 * 1000 && {
        //             color: "red.500",
        //           })}
        //           width={DURATION_COLUMN_WIDTH}>
        //           {duration}
        //         </Box>
        //         <Box width={COLUMNS_WIDTH}>{workflow.userName}</Box>
        //         <Box width={STATUS_COLUMN_WIDTH}>
        //           <Badge colorScheme={getStatusColor(workflow.status)}>{capitalize(workflow.status).split("_").join(" ")}</Badge>
        //         </Box>
        //       </Flex>
        //     </PopoverTrigger>
        //     <PopoverContent>
        //       <PopoverBody>
        //         <Text>
        //           User Email: <Text as={"em"}>{usersEmailsMap[workflow.userId] ?? "N/A"}</Text>
        //         </Text>
        //       </PopoverBody>
        //     </PopoverContent>
        //   </Popover>
        // );
      })}

      {hasNextPage && (
        <Flex visibility={isLoading ? "visible" : "hidden"} height={"3rem"} ref={lastMessageObserverRef} align="center" justify="center">
          <TypingIndicator />
        </Flex>
      )}

      <Outlet />
    </Box>
  );
};

export const WorkflowsAdmin = () => {
  return (
    <WorkflowsFiltersContextProvider>
      <WorkflowsAdminBase />
    </WorkflowsFiltersContextProvider>
  );
};
