import React, { useEffect, useState, useRef } from "react";
import type { DropResult } from "@hello-pangea/dnd";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { Box, Flex, Input, Select, IconButton, Button, Text, Stack, Icon } from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { useButtonProps } from "hooks";
import { MdDragIndicator } from "react-icons/md";

interface Question {
  id: string;
  text: string;
  focus: "sentiment" | "analytical";
}

const EditableQuestionList: React.FC = () => {
  const commonButtonProps = useButtonProps("sm", "primary");
  const secondaryButtonProps = useButtonProps("sm", "secondary");

  const [questions, setQuestions] = useState<Question[]>([{ id: "1", text: "", focus: "sentiment" }]);
  const addQuestionButtonRef = useRef<HTMLDivElement>(null);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(questions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setQuestions(items);
  };

  const handleQuestionChange = (id: string, text: string) => {
    setQuestions(questions.map((q) => (q.id === id ? { ...q, text } : q)));
  };

  const handleFocusChange = (id: string, focus: "sentiment" | "analytical") => {
    setQuestions(questions.map((q) => (q.id === id ? { ...q, focus } : q)));
  };

  const addNewQuestion = () => {
    const newId = (questions.length + 1).toString();
    setQuestions([...questions, { id: newId, text: "", focus: "sentiment" }]);
  };

  const deleteQuestion = (id: string) => {
    setQuestions(questions.filter((q) => q.id !== id));
  };

  useEffect(() => {
    if (addQuestionButtonRef.current) {
      addQuestionButtonRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [questions.length]);

  return (
    <Box fontSize="sm">
      <Flex mb={4} alignItems="center" fontWeight="bold">
        <Box width="45px" />
        <Text flex={1} mr={2}>
          Question
        </Text>
        <Text width="110px" mr={2}>
          Focus
        </Text>
        <Box width="40px" />
      </Flex>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="questions">
          {(provided) => (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {questions.map((question, index) => (
                <Draggable key={question.id} draggableId={question.id} index={index}>
                  {(provided, snapshot) => {
                    const { style } = provided.draggableProps;
                    return (
                      <Flex
                        {...provided.draggableProps}
                        style={{ ...style, left: "auto !important", top: "auto !important" }}
                        ref={provided.innerRef}
                        alignItems="center"
                        py={"1"}
                        bgColor="white"
                        borderRadius="md">
                        <IconButton
                          {...secondaryButtonProps}
                          aria-label="Drag handle"
                          icon={<Icon as={MdDragIndicator} boxSize="1.3rem" />}
                          {...provided.dragHandleProps}
                          bg={snapshot.isDragging ? "gray.100" : "white"}
                          mr={2}
                          cursor="grab"
                        />
                        <Input
                          size="sm"
                          placeholder="Enter question to be asked in projects"
                          value={question.text}
                          onChange={(e) => handleQuestionChange(question.id, e.target.value)}
                          mr={2}
                          flex={1}
                        />
                        <Select
                          size="sm"
                          value={question.focus}
                          onChange={(e) => handleFocusChange(question.id, e.target.value as "sentiment" | "analytical")}
                          width="120px"
                          mr={2}>
                          <option value="sentiment">Sentiment</option>
                          <option value="analytical">Analytical</option>
                        </Select>
                        <IconButton
                          {...secondaryButtonProps}
                          aria-label="Delete question"
                          icon={<DeleteIcon />}
                          onClick={() => deleteQuestion(question.id)}
                          colorScheme="red"
                        />
                      </Flex>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <Stack direction="row" width="100%" justifyContent="flex-end" height="4rem" ref={addQuestionButtonRef}>
        <Button {...commonButtonProps} leftIcon={<AddIcon />} mt={4} onClick={addNewQuestion}>
          Add New Question
        </Button>
      </Stack>
    </Box>
  );
};

export default EditableQuestionList;
