import React, { useRef, useEffect, useMemo, useCallback, useState } from "react";
import { AutocompleteInput } from "screens/collection/views/AutocompleteInput";
import { Box, Button, Flex, Highlight, Text } from "@chakra-ui/react";
import { SemanticFocusedAnswer } from "screens/collection/views/SemanticFocusedAnswer";
import { WorkflowProgressBar } from "screens/landing/components/WorkflowProgressBar";
import { formatDistanceToNow } from "date-fns";
import { useConversationDialogUtils } from "./useConversationDialogUtils";
import { useConversationDialogSuggestionLists } from "./useConversationDialogSuggestionLists";
import { useConversationDialogSubmitFunctions } from "./useConversationDialogSubmitFunctions";
import { useProjectParams } from "hooks";
import { useLocation } from "react-router-dom";
import { useConversationContext } from "./ConversationContext";
import { InfoIcon } from "@chakra-ui/icons";
import type { SuggestedQuestion } from "api/suggestions";

export type ConversationInputIds = "conversation-input" | "view-input";

interface Props {
  initialText?: string;
  className?: string;
  inputId: ConversationInputIds;
  minWidth?: string;
  afterSubmit?: () => void;
  conversationId?: string;
}

export const ConversationDialogInput: React.FC<Props> = React.memo(
  ({ initialText, className, inputId, minWidth, afterSubmit, conversationId }) => {
    const { projectId } = useProjectParams();
    const inputRef = useRef<HTMLInputElement>(null);
    const { pathname } = useLocation();
    const {
      onFeedbackModalOpen,
      setFeedbackValue,
      showCommands,
      setShowCommands,
      onConversationClose,
      initialQuestionText,
      setInitialQuestionText,
      initialQuestionFocus,
      setInitialQuestionFocus,
      focusedInputId,
      setFocusedInputId,
    } = useConversationContext();
    const [questionText, setQuestionText] = useState(initialQuestionText);

    const {
      hasExceededMaxProjects,
      currentCollectionType,
      maxAllowedProjects,
      currentWorkflowId,
      workflowStatus,
      currentWorkflowCompletionDate,
      canBypassDisabled,
      setCanBypassDisabled,
    } = useConversationDialogUtils();

    const {
      isValidTicker,
      isLoading,
      currentSuggestionList,
      currentSuggestionListType,
      isInputDisabled: isBaseInputDisabled,
      handleUpdateSourceWeightPreference,
      selectedFocus,
      answerFocusOptions,
      selectedSourceWeightsIndex,
      onResetQuestion,
      debouncedTickerFilter,
    } = useConversationDialogSuggestionLists({
      questionText,
      setQuestionText,
    });

    const isInputDisabled = isBaseInputDisabled || (focusedInputId !== inputId && focusedInputId !== undefined);

    const { currentSubmitFunction, onSubmitDueDiligenceProject, currentConversationID, conversation, isConversationOpen } =
      useConversationDialogSubmitFunctions({
        questionText,
        selectedFocus,
        afterSubmit,
        conversationId,
      });

    useEffect(() => {
      if (currentSuggestionListType !== "tickers" || questionText.startsWith("/")) return;
      debouncedTickerFilter(questionText);
    }, [debouncedTickerFilter, currentSuggestionListType, questionText]);

    useEffect(() => {
      setQuestionText(initialQuestionText.length > 0 ? initialQuestionText : questionText);
      initialQuestionFocus && handleUpdateSourceWeightPreference(initialQuestionFocus);
    }, [pathname, setQuestionText, initialQuestionText, questionText, handleUpdateSourceWeightPreference, initialQuestionFocus]);

    useEffect(() => {
      if (questionText !== initialQuestionText) {
        setInitialQuestionText("");
      }
    }, [questionText, initialQuestionText, setInitialQuestionText, setInitialQuestionFocus, pathname]);

    useEffect(() => {
      onResetQuestion();
    }, [onResetQuestion, pathname]);

    const onClickFeedback = useCallback(() => {
      setFeedbackValue(questionText);
      setQuestionText("");
      onFeedbackModalOpen();
    }, [setFeedbackValue, questionText, onFeedbackModalOpen]);

    useEffect(() => {
      if (isConversationOpen && !isInputDisabled && inputId === "conversation-input" && showCommands) {
        setQuestionText("/");
      }
      setShowCommands(false);
    }, [inputId, isConversationOpen, isInputDisabled, setShowCommands, showCommands, setQuestionText]);

    useEffect(() => {
      const handleKeyDown = (ev: KeyboardEvent) => {
        if (ev.key === "Escape" && isConversationOpen && questionText.length === 0 && inputId === "conversation-input") {
          onConversationClose();
        }
      };

      document.addEventListener("keydown", handleKeyDown);
      return () => document.removeEventListener("keydown", handleKeyDown);
    }, [inputId, isConversationOpen, onConversationClose, questionText]);

    const errorMessage = useMemo(() => {
      const commonStyles = {
        fontSize: "sm",
        bgColor: "orange.100",
        borderRadius: "md",
        p: "8px",
        lineHeight: "1.4rem",
      };

      if (hasExceededMaxProjects) {
        return (
          <Box {...commonStyles}>
            {`You can only have ${maxAllowedProjects} active ${currentCollectionType?.replace(/_/g, " ")} projects at a time`}
          </Box>
        );
      }

      if (
        conversation.conversationState !== "action" &&
        !isLoading &&
        questionText.length > 0 &&
        !isValidTicker(questionText) &&
        currentSuggestionList.length === 0
      ) {
        return (
          <Box className="ch-autocomplete-error" borderRadius="md" bgColor="white" borderColor="gray.300" borderWidth="1px" p="1rem">
            <Text fontSize="sm" lineHeight="1.4rem">
              <Highlight query={questionText || ""} styles={{ py: "1", whiteSpace: "normal", fontWeight: "bold" }}>
                {`There are no matching results for ${
                  questionText ? questionText.toUpperCase() : "this ticker"
                }.  Additional exchanges, indices and equities are being added rapidly and our team can prioritize based on your feedback.`}
              </Highlight>
            </Text>
            <Button
              leftIcon={<InfoIcon color="gray.400" />}
              fontWeight="normal"
              color="gray.600"
              pt="1rem"
              variant="link"
              fontSize="sm"
              onClick={onClickFeedback}
              width="100%"
              justifyContent="end">
              Information on prioritizing
            </Button>
          </Box>
        );
      }

      return null;
    }, [
      hasExceededMaxProjects,
      maxAllowedProjects,
      currentCollectionType,
      conversation.conversationState,
      isLoading,
      questionText,
      isValidTicker,
      currentSuggestionList.length,
      onClickFeedback,
    ]);

    const handleChange = useCallback(
      (value: string) => {
        setQuestionText(value);
      },
      [setQuestionText]
    );

    const handleSelectOption = useCallback(
      (selectedQuestion: SuggestedQuestion) => {
        const { question, focus, matchFilter, type } = selectedQuestion;
        if (conversation.conversationState === "action" || projectId || questionText.startsWith("/")) {
          if (focus && ["analytical", "sentiment"].includes(focus)) {
            handleUpdateSourceWeightPreference(focus === "analytical" ? 0 : 1);
          }
          setQuestionText(question);
        } else {
          const stockExchange = focus && ["analytical", "sentiment"].includes(focus) ? undefined : focus;
          matchFilter && onSubmitDueDiligenceProject(matchFilter[0].toUpperCase(), stockExchange, afterSubmit, undefined, undefined, type);
          setQuestionText("");
        }
      },
      [
        conversation.conversationState,
        projectId,
        questionText,
        handleUpdateSourceWeightPreference,
        onSubmitDueDiligenceProject,
        afterSubmit,
      ]
    );

    const handleClickSubmit = useCallback(() => {
      if (isInputDisabled) return;
      const question = questionText.trim();
      currentSubmitFunction(question);
      setQuestionText("");
    }, [isInputDisabled, questionText, currentSubmitFunction]);

    const handleFocus = useCallback(() => {
      setFocusedInputId(inputId);
    }, [inputId, setFocusedInputId]);

    const handleBlur = useCallback(() => {
      isConversationOpen && setFocusedInputId(isConversationOpen ? "conversation-input" : "view-input");
    }, [isConversationOpen, setFocusedInputId]);

    return (
      <Box position="relative">
        {isConversationOpen && !projectId && (
          <Box pl=".5rem" bottom="3rem" zIndex={2} position="absolute" width="calc(100% - 5rem)" ml="2.5rem" mr="3rem">
            {errorMessage}
          </Box>
        )}
        <AutocompleteInput
          inputId={inputId}
          externalInputRef={inputRef}
          className={className}
          minWidth={minWidth || "22rem"}
          conversationId={conversationId || currentConversationID}
          value={isInputDisabled ? "" : questionText}
          onChange={handleChange}
          onReset={onResetQuestion}
          initialText={conversation.conversationState === "action" ? "Respond to Charli..." : initialText}
          suggestedQuestions={conversation.conversationState === "action" || isInputDisabled ? [] : currentSuggestionList}
          isInputDisabled={isInputDisabled}
          isSubmitDisabled={isInputDisabled}
          onSelectOption={handleSelectOption}
          onClickSubmit={handleClickSubmit}
          isLoading={isLoading}
          conversationState={conversation.conversationState}
          canBypassDisabled={canBypassDisabled}
          setCanBypassDisabled={setCanBypassDisabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disableFilter={currentSuggestionListType === "tickers"}
        />
        {projectId && !pathname.includes("/panel") && (
          <Box height="1rem" width="100%" mt="5px">
            {!isConversationOpen && currentWorkflowId && workflowStatus === "in_progress" ? (
              <Box pr="1rem" pl="10rem" justifyContent="end" width="100%">
                <WorkflowProgressBar
                  workflowId={currentWorkflowId}
                  lastUpdated={formatDistanceToNow(new Date(currentWorkflowCompletionDate), {
                    addSuffix: true,
                    includeSeconds: false,
                  })}
                  size="compact"
                  showInline={false}
                />
              </Box>
            ) : answerFocusOptions ? (
              <Flex justifyContent="flex-end">
                <SemanticFocusedAnswer
                  isDisabled={currentSuggestionListType === "commands"}
                  answerFocusOptions={answerFocusOptions}
                  selectedSourceWeightsIndex={selectedSourceWeightsIndex}
                  onSourceWeightPreferenceChange={(index) => handleUpdateSourceWeightPreference(index)}
                />
              </Flex>
            ) : null}
          </Box>
        )}
        {!isConversationOpen && !projectId && (
          <Box ml="4rem" position="absolute" width="30rem">
            {errorMessage}
          </Box>
        )}
      </Box>
    );
  }
);
