import React, { useEffect, useMemo, useState } from "react";
import { SidebarButton } from "./SidebarButton";
import { HiOutlineCog } from "react-icons/hi";
import { useCustomTheme, useMenuConfig, useConfigMap, useUserPreference, useProjectConfigEntitlements } from "hooks";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { Skeleton, Stack, useColorModeValue } from "@chakra-ui/react";
import sortBy from "lodash/sortBy";
import { SidebarNavigationMainMenu } from "./SidebarNavigationMainMenu";
import { getTypeFromRoute, getTypeIcon, getViewConfig } from "configs/configMap";
import { AiOutlineHome } from "react-icons/ai";

export const SidebarNavigationMain = () => {
  const { isEmbeddedApp } = useCustomTheme();
  const { onSettingsOpen } = useAddToCharliContext();
  const configMap = useConfigMap();
  const menuConfig = useMenuConfig(configMap, "all", false);
  const menuItemsOrderPreference = useUserPreference("ui_menu_order") as string;
  const startColor = useColorModeValue("gray.100", "gray.900");
  const endColor = useColorModeValue("gray.50", "gray.800");
  const [isMenuLoaded, setIsMenuLoaded] = useState(false);
  const menuItemOrder = useMemo(
    () => (menuItemsOrderPreference && menuItemsOrderPreference.split(",")) || menuConfig.map((menuItem) => menuItem.id),
    [menuConfig, menuItemsOrderPreference]
  );
  const { due_diligence_project: hasDueDiligence } = useProjectConfigEntitlements();

  useEffect(() => {
    if (menuConfig && menuConfig.length > 0) {
      setIsMenuLoaded(true);
    }
  }, [menuConfig]);

  const reorderedMenuConfig = useMemo(() => {
    if (isMenuLoaded) {
      const reorderedMenuConfig = sortBy(menuConfig, (menuItem) => {
        const index = menuItemOrder.indexOf(menuItem.id);
        return index !== -1 ? index : menuItemOrder.length;
      });

      return reorderedMenuConfig.filter(
        (projectConfig) => projectConfig.config.collectionType !== "home" && projectConfig.config.collectionType !== "due_diligence"
      );
    }

    return [];
  }, [isMenuLoaded, menuConfig, menuItemOrder]);

  const homeMenuItem = menuConfig.find((menuItem) => menuItem.config.collectionType === "home");
  const dueDiligenceMenuItem = menuConfig.find((menuItem) => menuItem.config.collectionType === "due_diligence");

  return (
    <>
      {!isMenuLoaded ? (
        <Stack spacing="1rem">
          {Array.from({ length: 5 }, (_, i) => (
            <Stack justifyContent={"space-between"} spacing="1rem" direction="row" pb=".5rem" px="1rem" key={`skeleton-${i}`}>
              <Skeleton
                borderRadius={"md"}
                height="1.8rem"
                width="100%"
                fadeDuration={0.5}
                speed={0.8}
                startColor={startColor}
                endColor={endColor}
              />
            </Stack>
          ))}
        </Stack>
      ) : (
        <Stack spacing="1rem">
          <SidebarButton
            tooltip={homeMenuItem?.config.title}
            key={`sidebar-nav-home`}
            text={homeMenuItem?.config.title}
            icon={AiOutlineHome}
            screen={"home"}
            cssClasses={[`ch-sidebar-nav-${getTypeFromRoute(homeMenuItem?.config.route)}`]}
          />
          {hasDueDiligence && (
            <SidebarButton
              tooltip={dueDiligenceMenuItem?.config.title}
              key={`sidebar-nav-dd`}
              text={dueDiligenceMenuItem?.config.title}
              icon={getTypeIcon(getViewConfig("icon", dueDiligenceMenuItem?.id || "", configMap))}
              screen={getViewConfig("route", dueDiligenceMenuItem?.id || "", configMap)}
              cssClasses={[`ch-sidebar-nav-${getTypeFromRoute(dueDiligenceMenuItem?.config.route)}`]}
            />
          )}
          <SidebarNavigationMainMenu reorderedMenuConfig={reorderedMenuConfig} configMap={configMap} />
        </Stack>
      )}

      {isEmbeddedApp && (
        <>
          <SidebarButton
            tooltip={"Settings"}
            text="Settings"
            icon={HiOutlineCog}
            screen="settings"
            onClick={onSettingsOpen}
            cssClasses={["ch-sidebar-nav-settings"]}
          />
        </>
      )}
    </>
  );
};
