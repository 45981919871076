import { Box, Stack, useColorModeValue, useBreakpointValue, Text, Tooltip, Tag } from "@chakra-ui/react";
import {
  useCollectionHasHighlights,
  useCollectionKey,
  useCompanyStockFromCollectionContentsOrConversation,
  useCollectionMetadataIds,
  useConfigMap,
  useConversation,
  useDownloadCollectionContents,
  useGetViewConfig,
  useLatestCollectionWorkflowId,
  useProjectStockEquityDataFromCollectionMetadata,
  useCollectionCreatedDate,
} from "hooks";
import type { FunctionComponent } from "react";
import React, { useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Highlights } from "screens/panels/highlights/Highlights";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useWorkflowCompletionDateOrNow } from "hooks/useWorkflows";
import { CollectionsFilterContext } from "screens/landing/tabs";
import { getViewConfig } from "configs/configMap";
import { WorkflowProgressBar } from "screens/landing/components/WorkflowProgressBar";
import { formatDistanceToNow } from "date-fns";
import { ProjectHeaderImage } from "./components/ProjectHeaderImage";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { WorkflowProgressClarification } from "screens/landing/components/WorkflowProgressClarification";
import { MarkdownItemView } from "screens/markdown/MarkdownItemView";
import Highlighter from "react-highlight-words";
import { StockEquityChart } from "screens/collection/components/StockEquityChart";
import { formatDateWithOutputFormat } from "screens/common/modal/formatters";

interface Props {
  collectionId: string;
  projectGroupCount?: number;
}

export const HomeDefaultTile: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  collectionId,
  projectGroupCount,
}) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const tileBorderColor = useColorModeValue("gray.300", "gray.700");
  const borderColorHover = useColorModeValue("gray.400", "gray.600");
  const navigate = useNavigate();
  const extractedHighlights = useCollectionKey(collectionId, "extractedHighlights");
  const currentWorkflowId = useLatestCollectionWorkflowId(collectionId);
  const currentWorkflowCompletionDate = useWorkflowCompletionDateOrNow(currentWorkflowId);
  const configMap = useConfigMap();
  const { onConversationOpen } = useContext(ConversationContext);
  const { searchText } = useContext(CollectionsFilterContext);
  const highlightLines = useBreakpointValue([4, 5, 5, 6, 6]);
  const collectionMetadataIds = useCollectionKey(collectionId, "metadataIds");
  const collectionConversationId = useCollectionKey(collectionId, "conversationId");
  const collectionType = useCollectionKey(collectionId, "collectionType");
  const collectionQuestions = useCollectionKey(collectionId, "questions");
  const collectionName = useCollectionKey(collectionId, "name");
  const projectGroupId = useCollectionKey(collectionId, "projectGroupId");
  const { conversationState } = useConversation(collectionConversationId ?? "");
  const companyStockData = useCompanyStockFromCollectionContentsOrConversation(collectionId);
  const filteredContentData = useCollectionMetadataIds(collectionId);
  const stockEquityData = useProjectStockEquityDataFromCollectionMetadata(collectionMetadataIds || []);
  const projectCreationDate = useCollectionCreatedDate(collectionId);

  const getPath = () => {
    const projectRoute = getViewConfig("route", collectionType || "", configMap);
    if (projectRoute) {
      return navigate(`/${projectRoute}/${collectionId}`);
    } else {
      onConversationOpen();
    }
  };

  const businessOverview = useMemo(() => {
    const relevantQuestions = ["what are the material changes", "company nature of business and overview"];
    const question = collectionQuestions?.find(
      (q) => q.status !== "unpinned" && relevantQuestions.includes(q.question.toLowerCase()) && q.answers && q.answers.length > 0
    );

    return question?.answers[0].answer;
  }, [collectionQuestions]);

  const hasHighlights = useCollectionHasHighlights(collectionId);

  const projectTitle = useGetViewConfig("title", collectionType, configMap);

  const itemCount = collectionMetadataIds?.length ?? 0;

  useDownloadCollectionContents(collectionMetadataIds, collectionId);

  return (
    <Box
      position="relative"
      cursor="pointer"
      _hover={{
        borderColor: borderColorHover,
      }}
      backgroundColor={bgColor}
      borderColor={tileBorderColor}
      borderWidth="1px"
      borderRadius="md"
      justifyContent="flex-start"
      width="100%"
      height={"100%"}
      minH="13rem"
      overflow="hidden"
      _active={{ backgroundColor: { bgColor } }}
      p="1rem"
      pr="11px"
      onClick={() => {
        getPath();
      }}
      whiteSpace="normal"
      textAlign="left"
      className={`ch-collections-tab-collection-tile simple-tile-layout ${hasHighlights ? "has-highlights" : ""}`}>
      {currentWorkflowId && (
        <Box position="absolute" right="5px" top="5px" bgColor={bgColor} borderRadius="full" boxSize={"1.4rem"}>
          <WorkflowProgressBar
            workflowId={currentWorkflowId}
            size="compact"
            lastUpdated={formatDistanceToNow(new Date(currentWorkflowCompletionDate), {
              addSuffix: true,
              includeSeconds: false,
            })}
            progressIndicatorStyle="circle"
            conversationId={collectionConversationId}
          />
        </Box>
      )}
      <Stack direction="row" height="100%" width="100%" spacing="1rem">
        <Stack width="8rem" justifyContent={"space-between"}>
          {collectionType && (
            <ProjectHeaderImage
              projectGroupId={projectGroupId}
              projectName={companyStockData?.name}
              metadataIds={filteredContentData}
              projectType={collectionType}
            />
          )}
          {projectGroupCount && projectGroupCount > 0 && (
            <Tag
              variant="subtle"
              position={"absolute"}
              top="2.7rem"
              borderColor={"gray.300"}
              borderWidth="1px"
              bgColor="white"
              fontSize="0.65rem"
              cursor={"pointer"}>
              {`${projectGroupCount} ${projectGroupCount === 1 ? "version" : "versions"}`}
            </Tag>
          )}
          {stockEquityData && (
            <Tooltip
              zIndex={2}
              aria-label="stock-equity-tooltip"
              label={`As of ${projectCreationDate && formatDateWithOutputFormat(new Date(projectCreationDate), "do MMM yyyy hh:mm")}`}>
              <Box zIndex={1}>
                <StockEquityChart height="8rem" hideAxis stockEquityData={stockEquityData} />
              </Box>
            </Tooltip>
          )}
        </Stack>
        <Stack alignContent={"top"} width="100%" spacing=".5rem">
          {collectionType !== "due_diligence" && collectionName && <TextOverflowTooltip label={collectionName} />}
          {hasHighlights || (businessOverview && businessOverview.length > 0) ? (
            <Box alignContent={"top"}>
              {businessOverview && businessOverview.length > 0 ? (
                <Box maxHeight={filteredContentData.length === 0 ? "8.5rem" : "11rem"} overflow="auto" fontSize={"xs"}>
                  <Box pr="5px">
                    <MarkdownItemView markdown={businessOverview} />
                  </Box>
                </Box>
              ) : (
                <Highlights
                  textOnlyView
                  textTruncated
                  extractedHighlights={extractedHighlights}
                  showActionMenu={false}
                  maxHighlights={1}
                  maxLines={highlightLines}
                  searchPhrase={searchText}
                />
              )}
            </Box>
          ) : (
            <>
              {conversationState === "in_progress" && (
                <Text alignSelf="left" fontSize="xs" fontStyle={"italic"}>
                  {companyStockData ? (
                    <Highlighter
                      highlightStyle={{
                        backgroundColor: "transparent",
                        fontWeight: "bold",
                      }}
                      searchWords={[`${companyStockData.name} (${companyStockData.ticker})` || ""]}
                      autoEscape
                      textToHighlight={`Your ${projectTitle?.replace("All", "")} project ${
                        companyStockData ? `on ${companyStockData.name} (${companyStockData.ticker})` : ""
                      } is currently being processed. You will be notified when it is complete`}
                    />
                  ) : (
                    `Your ${projectTitle?.replace(
                      "All",
                      ""
                    )} project is currently being processed. You will be notified when it is complete`
                  )}
                </Text>
              )}
              {conversationState === "complete" && (
                <Text alignSelf="left" fontSize="xs">
                  {itemCount
                    ? `Your ${projectTitle?.replace("All", "")} project ${
                        companyStockData ? `on ${companyStockData.name} (${companyStockData.ticker})` : ""
                      } is complete. There ${itemCount === 1 ? "is" : "are"} ${itemCount} source content item${
                        itemCount === 1 ? "" : "s"
                      } in the project. Click here to access the project.`
                    : `Your ${projectTitle?.replace(
                        "All",
                        ""
                      )} project is complete. No resources were added but you can click here to access the project.`}
                </Text>
              )}
            </>
          )}
        </Stack>
      </Stack>
      {currentWorkflowId && conversationState === "in_progress" && collectionConversationId && (
        <Box
          zIndex={1}
          position={"absolute"}
          width="100%"
          left="0"
          bottom="0"
          borderColor={tileBorderColor}
          borderTopWidth="1px"
          p="10px"
          bgColor={bgColor}>
          <WorkflowProgressBar
            workflowId={currentWorkflowId}
            lastUpdated={formatDistanceToNow(new Date(currentWorkflowCompletionDate), {
              addSuffix: true,
              includeSeconds: false,
            })}
            size="compact"
            showInline={false}
            hideCompletedInDate
            conversationId={collectionConversationId}
          />
        </Box>
      )}
      {currentWorkflowId && conversationState !== "complete" && conversationState !== "in_progress" && (
        <WorkflowProgressClarification workflowId={currentWorkflowId} collectionId={collectionId} size="compact" />
      )}
    </Box>
  );
};
