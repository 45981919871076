import type { FunctionComponent } from "react";
import React, { useMemo } from "react";
import { Stack, Text, Badge, Box } from "@chakra-ui/react";
import { ResponsiveContainer, ComposedChart, Tooltip, XAxis, YAxis, Bar, CartesianGrid, Area } from "recharts";
import type { StockEquityData } from "types/stock";

interface Props {
  height?: string;
  hideAxis?: boolean;
  stockEquityData: StockEquityData | null;
  width?: number | string;
}

const formatPrice = (price: number | string) => Number(price).toFixed(2);
const formatNumber = (num: number | string): string => {
  return Number(num).toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

const CustomTooltip = ({ active = false, payload = [] }: any) => {
  if (active && payload.length) {
    const activeDataDate = payload[0].payload.date;
    const activeDataHigh = formatPrice(payload[0].payload.high);
    const activeDataLow = formatPrice(payload[0].payload.low);
    const activeDataClose = formatPrice(payload[0].payload.close);
    const activeDataVolume = formatNumber(payload[0].payload.volume);
    return (
      <Stack spacing="0" bgColor={"white"} fontSize={"xs"} boxShadow="md" width="100%" borderRadius={"md"}>
        <Box bgColor={"gray.50"} p="10px" borderTopRadius={"md"}>
          <Text isTruncated width="100%" fontWeight={"semibold"}>{`${activeDataDate}`}</Text>
        </Box>
        <Stack spacing="5px" p="10px" borderBottomRadius={"md"}>
          {activeDataHigh && (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Badge size="xs" isTruncated color={"white"} bgColor={"#007560"}>
                High
              </Badge>
              <Text width="5rem" textAlign={"right"}>
                {`$${activeDataHigh}`}
              </Text>
            </Stack>
          )}
          {activeDataLow && (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Badge size="xs" isTruncated color={"white"} bgColor={"#f56565"}>
                Low
              </Badge>
              <Text width="5rem" textAlign={"right"}>
                {`$${activeDataLow}`}
              </Text>
            </Stack>
          )}
          {activeDataClose && (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Badge size="xs" isTruncated color={"white"} bgColor={"#3d9484"}>
                Close
              </Badge>
              <Text width="5rem" textAlign={"right"}>
                {`$${activeDataClose}`}
              </Text>
            </Stack>
          )}
          {activeDataVolume && (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Badge size="xs" isTruncated color={"white"} bgColor={"#93abcc"}>
                Volume
              </Badge>
              <Text width="5rem" textAlign={"right"}>
                {`${activeDataVolume}`}
              </Text>
            </Stack>
          )}
        </Stack>
      </Stack>
    );
  }

  return null;
};

export const StockEquityChart: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  height = "20rem",
  hideAxis = false,
  stockEquityData,
  width = "100%",
}) => {
  const reformattedStockEquityData = useMemo(() => {
    if (
      !stockEquityData ||
      !stockEquityData.data.chart ||
      !stockEquityData.data.chart.result ||
      !stockEquityData.data.chart.result.length
    ) {
      return [];
    }
    const result = stockEquityData.data.chart.result[0];
    const timestamps = result.timestamp;
    const closeValues = result.indicators.quote[0].close;
    const lowValues = result.indicators.quote[0].low;
    const highValues = result.indicators.quote[0].high;
    const volumeValues = result.indicators.quote[0].volume;

    if (!timestamps) {
      return [];
    }

    return timestamps.map((timestamp, index) => {
      const date = new Date(timestamp * 1000);
      const formattedDate = date.toLocaleDateString("en-US", { day: "2-digit", month: "short", year: "numeric" });
      return {
        label: formattedDate,
        date: formattedDate,
        close: Number(closeValues[index]?.toFixed(2) ?? 0),
        low: Number(lowValues[index]?.toFixed(2) ?? 0),
        high: Number(highValues[index]?.toFixed(2) ?? 0),
        volume: Math.round(volumeValues[index] ?? 0),
      };
    });
  }, [stockEquityData]);

  const minMaxCloseValues = useMemo(
    () =>
      reformattedStockEquityData.reduce(
        (acc, item) => {
          if (item.close < acc.min) {
            acc.min = item.close;
          }
          if (item.close > acc.max) {
            acc.max = item.close;
          }
          return acc;
        },
        {
          min: Number.MAX_VALUE,
          max: Number.MIN_VALUE,
        }
      ),
    [reformattedStockEquityData]
  );

  const minMaxVolumeValues = useMemo(
    () =>
      reformattedStockEquityData.reduce(
        (acc, item) => {
          if (item.volume < acc.min) {
            acc.min = item.volume;
          }
          if (item.volume > acc.max) {
            acc.max = item.volume;
          }
          return acc;
        },
        {
          min: Number.MAX_VALUE,
          max: Number.MIN_VALUE,
        }
      ),
    [reformattedStockEquityData]
  );

  return (
    <>
      {!stockEquityData ? (
        <React.Fragment />
      ) : (
        <Stack height={height} width="100%" className="ch-project-tile-stock-chart">
          <ResponsiveContainer width={width} height={"100%"}>
            <ComposedChart
              width={500}
              height={400}
              data={reformattedStockEquityData}
              margin={{
                top: 0,
                right: hideAxis ? 0 : 10,
                bottom: 0,
                left: hideAxis ? 0 : -20,
              }}>
              <defs>
                <linearGradient id="colorClose" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={"#007560"} stopOpacity={0.4} />
                  <stop offset="95%" stopColor={"#007560"} stopOpacity={0} />
                </linearGradient>
              </defs>
              {!hideAxis && <CartesianGrid stroke="#f5f5f5" />}
              <XAxis hide={hideAxis} dataKey="date" fontSize={"10px"} />
              <YAxis
                hide={hideAxis}
                yAxisId="left"
                domain={[
                  Number(Math.round(Math.abs(minMaxCloseValues.min / 1.05) * 100) / 100),
                  Number(Math.round(Math.abs(minMaxCloseValues.max + minMaxCloseValues.max * 0.02) * 100) / 100),
                ]}
                allowDataOverflow
                fontSize={"10px"}
              />
              <YAxis
                hide
                yAxisId="right"
                orientation="right"
                domain={[Math.abs(minMaxVolumeValues.min), minMaxVolumeValues.max * 2]}
                allowDataOverflow
                fontSize={"10px"}
                tickLine={false}
              />
              {!hideAxis && <Tooltip isAnimationActive={false} content={<CustomTooltip />} />}
              <Area yAxisId="left" fill="url(#colorClose)" dataKey="close" stroke="#007560" activeDot={{ r: 8 }} />
              <Bar yAxisId="right" dataKey="volume" fill="#93abcc" />
            </ComposedChart>
          </ResponsiveContainer>
        </Stack>
      )}
    </>
  );
};
