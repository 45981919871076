import { Box, Icon, Stack, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { FaCheck } from "react-icons/fa";
import { useCopyValue } from "hooks/useCopies";

interface SubscriptionInformationProps {
  hideButtons?: boolean;
  scrollHeight?: string;
}

export const SubscriptionInformation: React.FC<SubscriptionInformationProps> = ({
  hideButtons = false,
  scrollHeight = "calc(100vh - 14rem)",
}) => {
  const rawCopySubscriptionFeatures = useCopyValue("copy_subscription_features");
  const featureList = useMemo(
    () =>
      rawCopySubscriptionFeatures?.flatMap((feature) => {
        const [name, trial, business] = feature.split("|").map((part) => part.trim());
        return name && trial && business ? [{ name, trial, business }] : [];
      }),
    [rawCopySubscriptionFeatures]
  );

  return (
    <Box>
      <Stack
        color="gray.700"
        direction="row"
        width="100%"
        justifyContent={"space-between"}
        pl="1.5rem"
        pr=".5rem"
        pb=".5rem"
        fontWeight={"semibold"}
        fontSize="md">
        <Text>Feature</Text>
        <Stack direction="row">
          <Text width="8rem" textAlign={"center"}>
            Free
          </Text>
          <Text width="8rem" textAlign={"center"}>
            Professional
          </Text>
        </Stack>
      </Stack>
      <Box maxHeight={scrollHeight} overflowY="auto">
        <Table variant="simple">
          <Tbody>
            {featureList.map((feature, index) => (
              <Tr className="ch-subscription-feature" fontSize={"sm"} key={index}>
                <Td>{feature.name}</Td>
                <Td textAlign={"center"} width="8rem">
                  {feature.trial === "true" ? (
                    <Icon as={FaCheck} color="green.500" />
                  ) : typeof feature.trial === "string" ? (
                    <>
                      {feature.trial.split(/total|days/).map((part, index) => (
                        <Stack spacing="0" key={index}>
                          <Text>{part}</Text>
                          {index > 0 && (
                            <Text lineHeight={".6rem"} fontSize={"xs"} color="gray.500">
                              {feature.trial.includes("days") ? "days" : "total"}
                            </Text>
                          )}
                        </Stack>
                      ))}
                    </>
                  ) : null}
                </Td>
                <Td textAlign={"center"} width="8rem">
                  {feature.business === "true" ? (
                    <Icon as={FaCheck} color="green.500" />
                  ) : typeof feature.business === "string" ? (
                    <>
                      {feature.business.split(/per month|days/).map((part, index) => (
                        <Stack spacing="0" key={index}>
                          <Text>{part}</Text>
                          {index > 0 && (
                            <Text lineHeight={".6rem"} fontSize={"xs"} color="gray.500">
                              {feature.business.includes("days") ? "days" : "per month"}
                            </Text>
                          )}
                        </Stack>
                      ))}
                    </>
                  ) : null}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};
