import type { Entity } from "./workflows/workflow";

export const CheckpointTypes = ["execute_intent", "create_entities", "milestone"] as const;

export type State = "active" | "draft" | "backup" | "deleted";
export type CheckpointType = typeof CheckpointTypes[number];

export interface ConfiguredWorkflow {
  id: string;
  userIntent: string;
  config: Config;
  creationByUserId: string;
  creationDate: string;
  lastUpdatedByUserId: string;
  lastUpdatedDate: string;
  creationByUserName: string;
  lastUpdatedByUserName: string;
  deletionByUserId: string | null;
  deletionDate: string | null;
  state: State;
  onlyAvailableToUserIds: string[] | null;
  approvedByUsers?: { id: string; name: string }[] | undefined;
  version: string;
  canBePublished: boolean;
  canBeEdited: boolean;
  notes?: string;
}

export interface Config {
  defaultEntities?: Entity[];
  checkpoints: Checkpoint[];
}

export interface ExecuteIntentCheckpoint {
  id?: string;
  type: "execute_intent";
  intent: string;
  needsConfirmation?: boolean;
  runInNewChildWorkflow?: boolean;
  onlyIncludeTheseEntities?: string[];
  shouldFailEntireWorkflowOnChildWorkflowFailure?: boolean;
  entityToSplit?: {
    entityName: string;
    renameTo?: string;
    shouldUnwrap: boolean;
  };
  requirements: Requirement[];
  entitiesToRename?: { entityName: string; renameTo: string }[];
  entitiesToInject?: Entity[];
}

export interface CreateEntitiesCheckpoint {
  id?: string;
  type: "create_entities";
  entitiesToInject: Entity[];
  requirements?: Requirement[];
}

export interface MilestoneCheckpoint {
  id?: string;
  type: "milestone";
  label: string;
}

export type Checkpoint = ExecuteIntentCheckpoint | CreateEntitiesCheckpoint | MilestoneCheckpoint;

export interface Requirement {
  entity: string;
  operator: "equal" | "not_equal" | "in" | "not_in" | "contains" | "does_not_contain";
  value: string | string[] | number | null | boolean;
}

export interface ConfiguredWorkflowCreationPayload {
  id?: string;
  userIntent?: string;
  config?: Config;
  state?: State;
  onlyAvailableToUserIds?: string[];
  notes?: string;
}
