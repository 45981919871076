import { SimpleGrid, useBreakpointValue, Box, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useContext } from "react";
import { IntegrationsFilterContext, ExternalIntegrations } from ".";
import groupBy from "lodash/groupBy";

export const IntegrationsRouter = React.memo(({ disableTileActions }: { disableTileActions?: boolean }) => {
  const { availableCells: cells, isGroupedByCategory } = useContext(IntegrationsFilterContext);
  const gridColumns = useBreakpointValue([1, 1, 2, 3, 4]);
  const groupedCategories = groupBy(cells, "category");
  const textColor = useColorModeValue("primary.darkGray", "gray.300");
  const lineColor = useColorModeValue("#E2E8F0", "#293142");

  return (
    <>
      {isGroupedByCategory ? (
        <SimpleGrid columns={gridColumns} spacing="1.5rem">
          {cells &&
            cells.map((integration, index) => {
              return (
                <ExternalIntegrations
                  key={`${integration.integrationId}-${index}`}
                  integration={integration}
                  disableActions={disableTileActions}
                />
              );
            })}
        </SimpleGrid>
      ) : (
        Object.keys(groupedCategories)
          .sort()
          .map((category, index) => {
            return (
              <Box key={index} pb="3rem" mt="0!important">
                <Box borderBottom={`2px solid ${lineColor}`} px=".5rem" py=".3rem" width="100%" mb="1rem">
                  <Text fontWeight="bold" fontSize="md" color={textColor}>
                    {category}
                  </Text>
                </Box>
                <SimpleGrid key={category} columns={gridColumns} spacing="1.5rem">
                  {groupedCategories[category].map((integration, index) => {
                    return (
                      <ExternalIntegrations
                        key={`${integration.integrationId}-${index}`}
                        integration={integration}
                        disableActions={disableTileActions}
                      />
                    );
                  })}
                </SimpleGrid>
              </Box>
            );
          })
      )}
    </>
  );
});
