import { Box, Image, Stack, Center, HStack, useBreakpointValue } from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { LoadingGate } from "screens/common/components";
import type { RootState } from "state/rootReducer";
import factsetLogo from "screens/common/static/logos/factset_logo.svg";
import nasdaqLogo from "screens/common/static/logos/nasdaq_logo.svg";
import tmxLogo from "screens/common/static/logos/tmx_logo.svg";
import { useLocation } from "react-router-dom";
import { LoginWrapperSlides } from "./LoginWrapperSlides";

export const LoginWrapper: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = ({ children }) => {
  const { isLoading, isLoggedIn } = useSelector((state: RootState) => state.session, shallowEqual);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const location = useLocation();
  const willShowLoadingSpinner = (isLoading || isLoggedIn) && location.pathname !== "/verify";

  return (
    <Stack
      id="login-container"
      height="100vh"
      width="100%"
      overflowY="auto"
      spacing="0"
      direction={isMobile ? "column" : "row"}
      position="relative">
      {!isMobile && (
        <Stack
          backgroundColor="gray.100"
          justifyContent="space-between"
          height="100%"
          minHeight={"43rem"}
          pt="5rem"
          pb="2rem"
          px="4rem"
          width="50rem"
          position="relative"
          overflow="hidden">
          <Box position="absolute" top="0" left="0" right="0" height="25rem" backgroundColor="primary.default" />
          <Box color="white" position="relative" zIndex="1">
            <LoginWrapperSlides />
          </Box>
          <Center position="relative" zIndex="1">
            <HStack align="center" spacing="1rem">
              <Image src={factsetLogo} height="1.2rem" alt="FactSet Logo" />
              <Image src={nasdaqLogo} height="2rem" alt="NASDAQ Logo" />
              <Image src={tmxLogo} height="2.5rem" alt="TMX Logo" />
            </HStack>
          </Center>
        </Stack>
      )}
      <Box width="100%" height="100%">
        <LoadingGate isLoading={willShowLoadingSpinner}>{children}</LoadingGate>
      </Box>
    </Stack>
  );
};
