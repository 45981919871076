import { CloseIcon } from "@chakra-ui/icons";
import { Image, Stack, Tooltip, Box, IconButton } from "@chakra-ui/react";
import { useUserPreference } from "hooks";
import React from "react";
import { useDispatch } from "react-redux";
import { updateTypedUserPreference } from "state/userPreference/operations";

export interface Link {
  stepNumber?: number;
  stepColor?: string;
  stepText?: string;
  stepTitle?: string;
  thumbnailSource?: string;
  url?: string;
  tooltip?: string;
}

interface SideBarHelpVideosProps {
  links: Link[];
}

const SideBarHelpVideos: React.FC<SideBarHelpVideosProps> = ({ links }) => {
  const hasHiddenOnboardingVideos = useUserPreference("ui_hide_onboarding_videos") as boolean;
  const dispatch = useDispatch();

  const orderLinksByStepNumberArray = [...links].sort((a, b) => {
    const aNumber = a.stepNumber ?? -1;
    const bNumber = b.stepNumber ?? -1;
    return aNumber - bNumber;
  });

  if (hasHiddenOnboardingVideos) {
    return null;
  }

  const onClickHandler = () => {
    !hasHiddenOnboardingVideos && dispatch(updateTypedUserPreference({ preferenceKey: "ui_hide_onboarding_videos", value: true }));
  };

  return (
    <Box position="relative" maxWidth="12rem" width="100%" className="ch-sidebar-onboarding-videos" paddingInline={"0"} mb="1rem">
      <Tooltip label="Hide videos" aria-label="Close" maxWidth="11rem" placement="top">
        <IconButton
          position={"absolute"}
          right="-0.3rem"
          top="0"
          p=".3rem"
          aria-label=""
          borderRadius={"lg"}
          bgColor={"gray.200"}
          icon={<CloseIcon color={"gray.500"} boxSize="10px" borderRadius={"full"} />}
          size="xs"
          onClick={(event) => {
            onClickHandler();
            event.stopPropagation();
          }}
        />
      </Tooltip>
      <Stack spacing="1rem">
        {orderLinksByStepNumberArray.map((link, index) => (
          <Tooltip key={index} label={link.tooltip} aria-label={link.tooltip} maxWidth="11rem">
            <Box cursor={"pointer"}>
              <Image borderRadius={"md"} src={link.thumbnailSource} onClick={() => window.open(link.url, "_blank")} />
            </Box>
          </Tooltip>
        ))}
      </Stack>
    </Box>
  );
};

export default SideBarHelpVideos;
