import { Box, Center, Flex, HStack, Image, Stack, Text, Tooltip } from "@chakra-ui/react";
import { useCopyValue } from "hooks/useCopies";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { useEffect, useState } from "react";
import React from "react";
import SlideImage1 from "screens/common/static/images/SlideImage1.png";
import SlideImage2 from "screens/common/static/images/SlideImage2.png";
import SlideImage3 from "screens/common/static/images/SlideImage3.png";
import SlideImage4 from "screens/common/static/images/SlideImage4.png";

export const LoginWrapperSlides: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = ({ children }) => {
  const rawCopySubscriptionFeatures = useCopyValue("copy_login_list");
  const slideList: { img: string; label?: string; description?: string }[] | undefined = useMemo(
    () =>
      rawCopySubscriptionFeatures.flatMap((feature) => {
        const [imgName, label, description] = feature.split("|").map((part) => part.trim());
        const img = (() => {
          switch (imgName) {
            case "SlideImage1":
              return SlideImage1;
            case "SlideImage2":
              return SlideImage2;
            case "SlideImage3":
              return SlideImage3;

            case "SlideImage4":
              return SlideImage4;
            default:
              return SlideImage1;
          }
        })();

        return img && label && description ? [{ img, label, description }] : [];
      }),
    [rawCopySubscriptionFeatures]
  );

  const [currentSlide, setCurrentSlide] = useState(0);
  const slidesCount = slideList.length;
  const carouselStyle = {
    transition: "all .5s",
    ml: `-${currentSlide * 100}%`,
  };
  const SLIDES_INTERVAL_TIME = 7000;
  const ANIMATION_DIRECTION = "right";

  useEffect(() => {
    const prevSlide = () => {
      setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
    };

    const nextSlide = () => {
      setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
    };

    const automatedSlide = setInterval(() => {
      ANIMATION_DIRECTION.toLowerCase() === "left" ? prevSlide() : nextSlide();
    }, SLIDES_INTERVAL_TIME);
    return () => clearInterval(automatedSlide);
  }, [slidesCount]);

  return (
    <Flex w="full" bg="transparent" alignItems="center" justifyContent="center" flexDirection="column">
      <Flex w="full" overflow="hidden">
        <Flex pos="relative" h="28rem" w="full" {...carouselStyle}>
          {slideList.map((slide, sid) => (
            <Box key={`slide-${sid}`} boxSize="full" flex="none">
              <Stack height="100%" justifyContent={"space-between"} pos="absolute" textAlign="left" w="full" color="white" spacing="1.5rem">
                <Stack spacing="1rem">
                  <Center>
                    <Text fontSize="xl" textAlign={"center"} fontWeight={"semibold"} width="80%">
                      {slide.label}
                    </Text>
                  </Center>
                  <Text fontSize="lg" textAlign={"center"}>
                    {slide.description}
                  </Text>
                </Stack>
                <Center width="100%">
                  <Tooltip label="Click to learn more about AI Due Diligence" aria-label="Learn more about AI Due Diligence">
                    <Image
                      src={slide.img}
                      width="100%"
                      onClick={() => window.open("https://charliai.com/features/", "_blank")}
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                </Center>
              </Stack>
            </Box>
          ))}
        </Flex>
      </Flex>
      <HStack justify="center" w="full" pt="2rem">
        {Array.from({
          length: slidesCount,
        }).map((_, slide) => (
          <Box
            key={`dots-${slide}`}
            cursor="pointer"
            boxSize={["7px", null, "10px"]}
            m="0 2px"
            bg={currentSlide === slide ? "gray.300" : "gray.200"}
            rounded="50%"
            display="inline-block"
            transition="background-color 0.6s ease"
            _hover={{
              bg: "gray.300",
            }}
            onClick={() => setCurrentSlide(slide)}
          />
        ))}
      </HStack>
    </Flex>
  );
};
