import React from "react";
import {
  Avatar,
  Badge,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  useColorModeValue,
  Table,
  Tbody,
  Tr,
  Td,
  Center,
  Tooltip,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  useDisclosure,
} from "@chakra-ui/react";
import { useButtonProps, useUserProfile, useUserSubscriptionDetails } from "hooks";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useFeatureUsage } from "hooks/useFeatureUsage";
import { UpgradePlanButton } from "../subscription/SubscriptionUpgrade";
import { Verify } from "screens/login/Verify";

export const Profile = () => {
  const { id, fullName, firstName, lastName, email, avatarUrl, isProfileUpdatePending, updateUser, isVerified } = useUserProfile();
  const secondaryButtonStyle = useButtonProps("sm", "secondary");
  const verifyButtonStyle = useButtonProps("sm", "subscribe");

  const borderColor = useColorModeValue("gray.200", "gray.600");
  const subscriptionInfo = useUserSubscriptionDetails();
  const projectUsage = useFeatureUsage("create_due_diligence_project");
  const answerUsage = useFeatureUsage("generate_answer");
  const [showTooltipProjects, setShowTooltipProjects] = React.useState(false);
  const [showTooltipQuestions, setShowTooltipQuestions] = React.useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<{
    firstName: string;
    lastName: string;
  }>({
    defaultValues: {
      firstName: firstName || "",
      lastName: lastName || "",
    },
  });

  const onSubmit = useCallback(
    (fields: { firstName: string; lastName: string }) => {
      updateUser(fields);
    },
    [updateUser]
  );

  const { ref: firstNameRef, ...firstNameRest } = register("firstName", { required: true });
  const { ref: lastNameRef, ...lastNameRes } = register("lastName", { required: true });

  return (
    <Flex width="100%" height="100%" flexDirection="column" justify="space-between">
      <Stack direction="row" width="100%" spacing="2rem">
        <Avatar name={fullName} src={avatarUrl} boxSize="4.5rem" />
        <Stack spacing="0" width="100%">
          <Stack spacing="1.5rem" width="100%" pb="1.5rem">
            <Stack direction="row" spacing="2rem">
              <FormControl isInvalid={!!errors.firstName}>
                <FormLabel>First name</FormLabel>
                <Input
                  {...firstNameRest}
                  id="firstName"
                  placeholder="First name"
                  ref={firstNameRef}
                  borderColor={errors.firstName ? "red.500" : borderColor}
                />
                {errors.firstName && (
                  <FormErrorMessage fontSize="xs" mt="0.3rem">
                    First name cannot be blank.
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.lastName}>
                <FormLabel>Last name</FormLabel>
                <Input
                  {...lastNameRes}
                  id="lastName"
                  placeholder="Last name"
                  ref={lastNameRef}
                  borderColor={errors.lastName ? "red.500" : borderColor}
                />
                {errors.lastName && (
                  <FormErrorMessage fontSize="xs" mt="0.3rem">
                    Last name cannot be blank.
                  </FormErrorMessage>
                )}
              </FormControl>
            </Stack>
            <Stack direction="row" spacing="2rem">
              <FormControl id="email-address">
                <FormLabel>Email address</FormLabel>
                <Input placeholder="Email address" value={email} isDisabled />
              </FormControl>
              <FormControl id="charli-id">
                <FormLabel>Charli ID</FormLabel>
                <Input placeholder="Charli ID" value={id} isDisabled />
              </FormControl>
            </Stack>
            <Stack direction="row" width="100%" justify="space-between">
              <Button {...(isVerified ? secondaryButtonStyle : verifyButtonStyle)} onClick={onOpen} isDisabled={isVerified}>
                {`${isVerified ? "Verified Profile" : "Verify Profile"}`}
              </Button>
              <Button {...secondaryButtonStyle} onClick={handleSubmit(onSubmit)} isLoading={isProfileUpdatePending} isDisabled={!isDirty}>
                Update Profile
              </Button>
            </Stack>
          </Stack>
          <Stack spacing="1.5rem" width="100%">
            <Divider />
            <Stack spacing="1rem">
              <FormLabel>Subscription Details</FormLabel>
              <Table variant="simple" size="sm">
                <Tbody>
                  <Tr>
                    <Td paddingInlineStart={0}>Current Subscription</Td>
                    <Td paddingInlineStart={0} className="ch-subscription-plan">
                      {subscriptionInfo.plan}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td paddingInlineStart={0}>Next Billing Date</Td>
                    <Td paddingInlineStart={0} className="ch-subscription-billing">
                      {subscriptionInfo.nextBillingDate
                        ? new Intl.DateTimeFormat(undefined, {
                            dateStyle: "long",
                            timeZone: "UTC",
                          }).format(new Date(subscriptionInfo.nextBillingDate))
                        : "N/A"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td paddingInlineStart={0}>Status</Td>
                    <Td paddingInlineStart={0}>
                      <Badge className="ch-subscription-status" colorScheme={subscriptionInfo.status === "active" ? "green" : "blue"}>
                        {subscriptionInfo.status}
                      </Badge>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td paddingInlineStart={0}>Number of Analysis Remaining</Td>
                    <Td paddingInlineStart={0}>
                      {subscriptionInfo.internalPlanName !== "business" && projectUsage && projectUsage?.used >= projectUsage?.allowed ? (
                        <UpgradePlanButton
                          tooltip={`You have received your ${projectUsage?.allowed} free equity reports. Upgrade for up to 20 reports`}
                          buttonSize="xs"
                        />
                      ) : (
                        <Center>
                          <Slider
                            width="100%"
                            isReadOnly
                            id="slider"
                            value={projectUsage ? projectUsage.used : 0}
                            min={0}
                            max={projectUsage && projectUsage.allowed ? projectUsage.allowed : 0}
                            colorScheme={
                              projectUsage && Math.min((projectUsage.used / projectUsage.allowed) * 100, 100) < 90 ? "teal" : "orange"
                            }
                            onMouseEnter={() => setShowTooltipProjects(true)}
                            onMouseLeave={() => setShowTooltipProjects(false)}>
                            <SliderTrack height="10px" borderRadius={"full"}>
                              <SliderFilledTrack />
                            </SliderTrack>
                            <Tooltip
                              hasArrow
                              placement="top"
                              isOpen={showTooltipProjects}
                              label={`You have used ${projectUsage?.used} of ${projectUsage?.allowed} projects`}>
                              <SliderThumb />
                            </Tooltip>
                          </Slider>
                        </Center>
                      )}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td paddingInlineStart={0}>Number of Questions Remaining</Td>
                    <Td paddingInlineStart={0}>
                      {subscriptionInfo.internalPlanName !== "business" && answerUsage && answerUsage?.used >= answerUsage?.allowed ? (
                        <UpgradePlanButton
                          tooltip={`You have received your ${answerUsage?.allowed} free questions. Upgrade to ask Charli up to 200 questions`}
                          buttonSize="xs"
                        />
                      ) : (
                        <Center>
                          <Slider
                            width="100%"
                            isReadOnly
                            id="answer-usage-slider"
                            value={answerUsage ? answerUsage.used : 0}
                            min={0}
                            max={answerUsage && answerUsage.allowed ? answerUsage.allowed : 0}
                            colorScheme={
                              answerUsage && Math.min((answerUsage.used / answerUsage.allowed) * 100, 100) < 90 ? "teal" : "orange"
                            }
                            onMouseEnter={() => setShowTooltipQuestions(true)}
                            onMouseLeave={() => setShowTooltipQuestions(false)}>
                            <SliderTrack height="10px" borderRadius={"full"}>
                              <SliderFilledTrack />
                            </SliderTrack>
                            <Tooltip
                              hasArrow
                              placement="top"
                              isOpen={showTooltipQuestions}
                              label={`You have used ${answerUsage?.used} of ${answerUsage?.allowed} AI questions`}>
                              <SliderThumb />
                            </Tooltip>
                          </Slider>
                        </Center>
                      )}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {isOpen && <Verify viewType="modal" isModalOpen={isOpen} onCloseModal={onClose} />}
    </Flex>
  );
};
