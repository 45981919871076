import React, { useContext, useEffect, useRef, useMemo, useState, useCallback } from "react";
import { Box, Center, Drawer, DrawerBody, DrawerContent, Stack, useBreakpointValue, useColorModeValue, Text } from "@chakra-ui/react";
import { SidebarNavigation } from "./components";
import { HeaderInteractionBar } from "screens/common/components";
import { useSettingContext } from "../panels/settings/SettingsProvider";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { TypingIndicator } from "screens/thread/components/cells/components";
import {
  useIsMainAppLoading,
  useProjectConfigEntitlements,
  useSidebarNavigation,
  useUserPreference,
  useGetViewConfig,
  useConfigMap,
  useProjectParams,
  DeleteCollectionModal,
  useDeleteCollection,
  useCollectionKey,
  useFeatureFlags,
} from "hooks";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useDebouncedCallback } from "use-debounce";
import { v4 as uuid } from "uuid";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ProjectNewInput } from "./tabs/project/ProjectNewInput";
import { ProjectDetailDefaultViewHeader } from "screens/collection/views/ProjectDetailDefaultViewHeader";
import { EquityFeedbackModal } from "screens/thread/components/useEquityFeedback";
import { useCopyValue } from "hooks/useCopies";
import { InfoOutlineIcon } from "@chakra-ui/icons";

export const PROJECT_DETAIL_SECTION_SELECTOR = "project-detail-section";

export const Landing = () => {
  const { parentRoute, projectFilter, projectId, contentId, isPortfolios, isProjects } = useProjectParams();
  const { pathname } = useLocation();
  const { isSideNavigationOpen, setIsSideNavigationOpen } = useSettingContext();
  const bgColor = useColorModeValue("white", "gray.900");
  const isMobile = useBreakpointValue({ base: true, md: true, lg: false }, { fallback: "md", ssr: false });
  const isProject = isProjects || isPortfolios;
  const isMainAppLoading = useIsMainAppLoading();
  const appCountainerRef = useRef<HTMLDivElement>(null);
  const {
    onConversationOpen,
    onConversationClose,
    setConversationId,
    conversationId,
    isAnotherInputFocused,
    isEditing,
    isConversationOpen,
    setHideConversationButton,
    isUsingDefaultConversationDialog,
    setShowCommands,
    initialQuestionText,
  } = useContext(ConversationContext);
  const { currentSidebarType: reduxNavigationType, setSidebarType } = useSidebarNavigation();
  const saveNavigationType = useUserPreference("ui_save_navigation_type");
  const userNavigationType = useUserPreference("ui_navigation_type");
  const currentSidebarType = saveNavigationType ? userNavigationType : reduxNavigationType;
  const { isAdminPanelOpen, isAddNewProjectPanelOpen, isAddToCollectionModalOpen } = useAddToCharliContext();
  const { due_diligence_project: hasDueDiligence } = useProjectConfigEntitlements();
  const configMap = useConfigMap();
  const maybePortfolioProjectCollectionType = useCollectionKey(projectFilter, "collectionType");
  const maybePortfolioProjectConversationId = useCollectionKey(projectFilter, "conversationId");
  const portfolioCollectionType = useGetViewConfig("portfolioProjectType", maybePortfolioProjectCollectionType, configMap);
  const collectionType =
    portfolioCollectionType && portfolioCollectionType?.length > 0
      ? portfolioCollectionType
      : parentRoute === "home" && hasDueDiligence
      ? "due_diligence"
      : projectFilter;
  const mainContainerRef = useRef<HTMLInputElement>(null);
  const showQAinputProjectDetail = useGetViewConfig("projectDetailQAInput", collectionType, configMap);
  const { onDeleteCollection } = useDeleteCollection();
  const [isScrolling, setIsScrolling] = useState(false);
  const { show_maintenance_banner: showMaintanaceBanner } = useFeatureFlags();
  const copyMaintenanceBanner = useCopyValue("copy_maintenance_banner");
  const copyBgColor = useCopyValue("copy_maintenance_banner_bg_color");
  const copyTextColor = useCopyValue("copy_maintenance_banner_text_color");
  const [currentPathname, setCurrentPathname] = useState(pathname);

  const handleScroll = useCallback(() => {
    setIsScrolling(true);
    const scrollTimer = setTimeout(() => setIsScrolling(false), 400);
    return () => clearTimeout(scrollTimer);
  }, []);

  useEffect(() => {
    const container = appCountainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  useEffect(() => {
    const container = appCountainerRef.current;
    if (
      container &&
      !isConversationOpen &&
      !pathname.includes("/panel") &&
      (initialQuestionText.length > 0 || projectId || currentPathname !== pathname)
    ) {
      setCurrentPathname(pathname);
      container.scroll({ top: 0, behavior: "smooth" });
    }
  }, [projectId, initialQuestionText, isConversationOpen, pathname, currentPathname]);

  const hasTestEntityPreferenceEnabled = useUserPreference("ui_inject_test_entity") as boolean;
  useEffect(() => {
    const excludedPaths = ["delivery", "admin", "operations", "home"];
    const shouldHideConversationButton = excludedPaths.some((path) => pathname.includes(path)) && !hasTestEntityPreferenceEnabled;

    setHideConversationButton(shouldHideConversationButton);

    return () => {
      setHideConversationButton(false);
    };
  }, [setHideConversationButton, pathname, hasTestEntityPreferenceEnabled]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "/" && !isConversationOpen) {
        if (isAdminPanelOpen || isAddNewProjectPanelOpen || isAddToCollectionModalOpen || isAnotherInputFocused || isEditing) return;
        if (!conversationId) setConversationId(maybePortfolioProjectConversationId || contentId || uuid());
        setShowCommands(true);
        onConversationOpen();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    maybePortfolioProjectConversationId,
    conversationId,
    isAddToCollectionModalOpen,
    isAdminPanelOpen,
    isAnotherInputFocused,
    isConversationOpen,
    isEditing,
    isAddNewProjectPanelOpen,
    onConversationClose,
    onConversationOpen,
    setConversationId,
    projectFilter,
    contentId,
    isUsingDefaultConversationDialog,
    setShowCommands,
  ]);

  const debouncedSetWidth = useDebouncedCallback(() => {
    if (saveNavigationType) return;
    if (isMobile) {
      setSidebarType("hidden");
    } else {
      setSidebarType("desktop");
    }
  }, 30);
  useEffect(() => {
    window.addEventListener("resize", debouncedSetWidth);
    debouncedSetWidth();

    return () => {
      window.removeEventListener("resize", debouncedSetWidth);
    };
  }, [debouncedSetWidth]);

  const projectNewInputHeight = useMemo(() => {
    if (parentRoute === "home") return;
    if (!showQAinputProjectDetail?.showInput && projectId) return "0";
    if (isMobile) return projectId && !pathname.includes("/panel") ? "9rem" : "13rem";
    return projectId && !pathname.includes("/panel") ? "9.3rem" : "16rem";
  }, [isMobile, parentRoute, pathname, projectId, showQAinputProjectDetail?.showInput]);

  return (
    <Box ref={mainContainerRef} m="0!important" width="100vw" height="100vh">
      <Stack direction="row" spacing="0" height="100%" width="100%" justifyContent={"space-between"}>
        <Box height={"100%"}>
          {currentSidebarType === "hidden" ? (
            <Drawer isOpen={isSideNavigationOpen} placement="left" onClose={() => setIsSideNavigationOpen(false)}>
              <DrawerContent>
                <DrawerBody p="0" paddingInline={"0"} height="100vh" overflow={"hidden"} width={["100vw", "100%", "100%"]}>
                  <SidebarNavigation />
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          ) : (
            <SidebarNavigation />
          )}
        </Box>
        <Stack spacing="0" width="100%" height="100%" mt="0!important">
          <HeaderInteractionBar />
          {showMaintanaceBanner && (
            <Center width="100%" height="3rem" bgColor={copyBgColor}>
              <Stack direction="row" alignItems="center" justifyContent="center" spacing="1rem">
                <InfoOutlineIcon color={copyTextColor} height="1.5em" />
                <Text fontSize={"md"} fontWeight={"semibold"} color={copyTextColor}>
                  {copyMaintenanceBanner}
                </Text>
              </Stack>
            </Center>
          )}
          <Box
            className="ch-landing"
            height="100%"
            ref={appCountainerRef}
            overflowX="hidden"
            mt="0!important"
            backgroundColor={bgColor}
            overflowY="scroll"
            paddingRight="0px"
            css={{
              "&::-webkit-scrollbar": {
                width: "10px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: isScrolling ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0.1)",
                borderRadius: "5px",
                borderLeft: "3px solid transparent",
                backgroundClip: "padding-box",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "rgba(0, 0, 0, 0.2)",
                borderRadius: "5px",
                borderLeft: "3px solid transparent",
                backgroundClip: "padding-box",
              },
            }}>
            <>
              {isMainAppLoading ? (
                <Center backgroundColor={bgColor} zIndex={100} width="100%" height={"calc(100vh - 5rem)"}>
                  <TypingIndicator />
                </Center>
              ) : (
                <Box pt="1rem">
                  {isProject && (
                    <Box pl={isMobile ? ".5rem" : "1rem"} pr={isMobile ? ".5rem" : "6px"}>
                      {projectId && projectId?.length > 0 && !pathname.includes("/panel") && (
                        <Box className={`${PROJECT_DETAIL_SECTION_SELECTOR}`} height="4rem">
                          <ProjectDetailDefaultViewHeader collectionId={projectId} />
                        </Box>
                      )}
                      <Center width="100%" height={projectNewInputHeight} px="0">
                        <ProjectNewInput collectionType={collectionType} collectionId={projectId} />
                      </Center>
                    </Box>
                  )}
                  <Outlet />
                </Box>
              )}
            </>
          </Box>
        </Stack>
      </Stack>
      <DeleteCollectionModal onDeleteCollection={onDeleteCollection} />
      <EquityFeedbackModal />
    </Box>
  );
};
