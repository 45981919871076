import { UTM_PARAMETERS } from "api/analytics";
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { loginWithRefreshToken } from "state/session/operations";
import type { RootState } from "../state/rootReducer";
import { useIsDeferNewUserHours } from "../hooks/useUserProfile";

const LOGIN_REDIRECT = "/login";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const { pathname: path, search: queryString } = useLocation();
  const { isAuthorized, isLoggedIn, isLoading, token, user } = useSelector((state: RootState) => state.session, shallowEqual);
  const remainingDeferHours = useIsDeferNewUserHours();

  useEffect(() => {
    if (!isLoading && !isLoggedIn && isAuthorized && token) {
      dispatch(loginWithRefreshToken(token));
    }
  }, [isLoading, isLoggedIn, isAuthorized, token, dispatch]);

  const shouldRedirectToVerify = useMemo(() => {
    return (
      isAuthorized &&
      remainingDeferHours === 0 &&
      user?.isEmailVerified !== true &&
      path !== "/verify" &&
      path !== "/logout" &&
      path !== "/home"
    );
  }, [isAuthorized, remainingDeferHours, user?.isEmailVerified, path]);

  if (shouldRedirectToVerify) {
    return <Navigate to={"/verify"} />;
  }

  if (isAuthorized) {
    if (path === "/") {
      return <Navigate to={`home${queryString}`} />;
    }
    return children;
  }
  const incomingSearchParams = new URLSearchParams(queryString);
  const utmSearchParams = new URLSearchParams();

  UTM_PARAMETERS.forEach((utmParam) => {
    const maybeUtmValue = incomingSearchParams.get(utmParam);
    if (maybeUtmValue) {
      utmSearchParams.append(utmParam, maybeUtmValue);
      incomingSearchParams.delete(utmParam);
    }
  });

  const redirectQueryString = formatQueryString(utmSearchParams);
  const postLoginQueryString = formatQueryString(incomingSearchParams);

  const loginRedirect = incomingSearchParams.get("is_new_user") === "true" ? "/register" : LOGIN_REDIRECT;

  return <Navigate to={`${loginRedirect}${redirectQueryString ?? ""}`} state={{ path, queryString: postLoginQueryString }} />;
};

const formatQueryString = (searchParams: URLSearchParams): string | undefined => searchParams.toString() && `?${searchParams.toString()}`;
