import React from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import { CommonModal } from "./CommonModal";
import { Wizard } from "react-use-wizard";
import { OnboardingNewProjectStep } from "./OnboardingNewProjectStep";
import { OnboardingLearnMoreStep } from "./OnboardingLearnMoreStep";

export const ONBOARDING_MODEL_HEIGHT = "35.5rem";

export const OnboardingModal = () => {
  const { isOnboardingModalOpen, onOnboardingModalClose } = useConversationContext();

  return (
    <CommonModal
      allowDismiss={false}
      borderColor="#4799d4"
      isOpen={isOnboardingModalOpen}
      onClose={onOnboardingModalClose}
      modalBody={
        <Wizard>
          <OnboardingNewProjectStep />
          <OnboardingLearnMoreStep />
        </Wizard>
      }
    />
  );
};
