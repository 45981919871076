import type { MessageType } from "./MessageType";
import StompWebsocketClient from "./StompWebsocketClient";
import type { CharliUI } from "types/charliui";
import type { TopicSubscription } from "./TopicSubscription";

export type NewMessage = {
  senderId: string;
  viewId?: string;
  createdDate: string;
  data?: Array<CharliUI>;
  entities?: { entity: string; value: unknown }[];
  conversationId?: string;
  parentMessageId?: string;
  id: string;
  intent?: string;
  content?: string;
};

export interface IRealTimeClient {
  connect(
    clientId: string,
    onConnect: () => void,
    onDisconnect: () => void,
    onMessage: (message: string, messageType?: MessageType) => void
  ): Promise<IRealTimeClient>;

  send(message: NewMessage): Promise<void>;
  subscribeToEvent<TPayloadBody>(
    topic: string,
    handler: (messageBody: TPayloadBody, destination: string) => void,
    isEventTargetedToUser?: boolean
  ): TopicSubscription;
  close(code?: number): void;
}

let realTimeClient: IRealTimeClient | null = null;

export const getWebsocketClient = (): IRealTimeClient => {
  if (realTimeClient != null) {
    return realTimeClient;
  }

  realTimeClient = new StompWebsocketClient();

  return realTimeClient;
};
