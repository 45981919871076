import type { ChangeEvent } from "react";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, FormControl, FormLabel, Select, Stack, Switch, Text, Tooltip, useOutsideClick } from "@chakra-ui/react";
import { useEntitlementKey, useEntitlements, useTileProps, useUserPreference, useUserProfile, useUserSubscriptionDetails } from "hooks";
import { fetchUserMarketingPreference, updateTypedUserPreference } from "state/userPreference/operations";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";
import type { UserPreferenceKey } from "types/userPreferences";
import { Popover } from "react-tiny-popover";
import { reportTypeTileSelectors } from "screens/panels/researchReport/ProjectReportPanelNewReportModal";
import { ReportTemplateGroupingSection } from "screens/panels/researchReport/ReportSelector";
import { useReportTemplates } from "hooks/useReportTemplates";

export const Config = () => {
  const dispatch = useDispatch();
  const { isDeletePending, deleteUser } = useUserProfile();
  const [willDeleteUser, setWillDeleteUser] = useState(false);
  const injectDebugEntityPreference = useUserPreference("ui_inject_debug_entity");
  const injectTestEntityPreference = useUserPreference("ui_inject_test_entity");
  const showEnvBadgePreference = useUserPreference("ui_show_env_badge");
  const userDefaultReportTemplateType = useUserPreference("default_report_template_type");
  const defaultReportTemplate = useUserPreference("default_report_template");
  const reportTemplateGroupings = useReportTemplates({ refreshFromNetwork: true });
  const hideCommandLineButton = useUserPreference("ui_hide_command_line_button");
  const hasHiddenOnboardingVideos = useUserPreference("ui_hide_onboarding_videos") as boolean;
  const { allow_debug_mode: isUserEntitledToDebugMode, ui_allow_env_badge: canEnableEnvBadge } = useEntitlements();
  const [popoverIndex, setPopoverIndex] = useState(-1);
  const mainRef = useRef<HTMLDivElement | null>(null);
  const commonTileProps = useTileProps();
  const hasCommandLine = useEntitlementKey("ui_enable_command_suggestions");
  const hasTestEntityPreferenceEnabled = useUserPreference("ui_inject_test_entity") as boolean;
  const subscriptionInfo = useUserSubscriptionDetails();
  const reportTypeTileSelectorsMemo = useMemo(
    () => reportTypeTileSelectors(subscriptionInfo?.internalPlanName || ""),
    [subscriptionInfo?.internalPlanName]
  );
  const hasActiveSubscription = useMemo(() => {
    if (hasTestEntityPreferenceEnabled) {
      return false;
    }

    return subscriptionInfo.internalPlanName === "business";
  }, [hasTestEntityPreferenceEnabled, subscriptionInfo.internalPlanName]);

  useEffect(() => {
    dispatch(fetchUserMarketingPreference());
  }, [dispatch]);

  const onSwitchChange = (key: UserPreferenceKey) => async (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    dispatch(updateTypedUserPreference({ preferenceKey: key, value: isChecked }));
  };

  const handleOnChange = (key: UserPreferenceKey, value: string) => {
    dispatch(
      updateTypedUserPreference({
        preferenceKey: key,
        value: value,
      })
    );
  };

  const toggleWillDeleteUser = () => setWillDeleteUser(!willDeleteUser);

  useOutsideClick({
    ref: mainRef!,
    handler: () => {
      setPopoverIndex(-1);
    },
  });

  return (
    <Stack spacing="2rem">
      <Stack width="100%" spacing="1rem">
        <Stack spacing="0">
          <SectionHeader title="Default Report Template" />
          <Text fontSize="xs" color="gray.500">
            Choose the default report template to be used when creating reports for projects.
          </Text>
        </Stack>
        <Stack justify="center">
          <FormControl display="flex" alignItems="center">
            <Select
              className="ch-config-default-report"
              maxWidth={["unset", "unset", "50%"]}
              borderRadius="lg"
              size="sm"
              value={String(defaultReportTemplate)}
              onChange={(e: { target: { value: string } }) => {
                handleOnChange("default_report_template", e.target.value);
              }}>
              <option value="">No Default Selected</option>
              {reportTemplateGroupings &&
                reportTemplateGroupings.map((grouping) => (
                  <ReportTemplateGroupingSection key={`${grouping.organizationName}`} grouping={grouping}></ReportTemplateGroupingSection>
                ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Stack width="100%" spacing="1rem">
        <Stack spacing="0">
          <SectionHeader title="Default Report Type" />
          <Text fontSize="xs" color="gray.500">
            Choose the default report template to be used when creating reports for projects.
          </Text>
        </Stack>
        <Stack justify="center">
          <FormControl display="flex" alignItems="center">
            <Select
              className="ch-config-default-report"
              maxWidth={["unset", "unset", "50%"]}
              borderRadius="lg"
              size="sm"
              lineHeight={"1.7rem"}
              value={String(userDefaultReportTemplateType)}
              onChange={(e: { target: { value: string } }) => {
                handleOnChange("default_report_template_type", e.target.value);
              }}>
              <option value="">No Default Selected</option>
              {reportTypeTileSelectorsMemo &&
                reportTypeTileSelectorsMemo.map((reportType, index) => (
                  <option key={index} value={reportType.value} disabled={reportType.disabled}>
                    {reportType.label}
                  </option>
                ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Stack width="100%" spacing="1rem">
        <Stack spacing="0">
          <SectionHeader title="Hide Onboarding Videos in sidebar" />
          <Text fontSize="xs" color="gray.500">
            This will show or hide the onboarding videos in the sidebar.
          </Text>
        </Stack>
        <Stack justify="center">
          <FormControl display="flex" alignItems="center">
            <Switch
              size="sm"
              className="ch-settings-toggle-onboarding-videos"
              id="ch-settings-advanced-hide-onboarding-videos"
              isChecked={Boolean(hasHiddenOnboardingVideos)}
              onChange={onSwitchChange("ui_hide_onboarding_videos")}
            />
            <FormLabel size="md" htmlFor="ch-settings-advanced-show-command-line" ml="0.5rem" mb="0">
              Toggle Onboarding Videos
            </FormLabel>
          </FormControl>
        </Stack>
      </Stack>
      {hasCommandLine && (
        <Stack width="100%" spacing="1rem">
          <Stack spacing="0">
            <SectionHeader title="Hide Command Line Suggestions" />
            <Text fontSize="xs" color="gray.500">
              This will show or hide the command suggestions on the Charli command line.
            </Text>
          </Stack>
          <Stack justify="center">
            <FormControl display="flex" alignItems="center">
              <Switch
                size="sm"
                id="ch-settings-advanced-show-command-line"
                isChecked={Boolean(hideCommandLineButton)}
                onChange={onSwitchChange("ui_hide_command_line_button")}
              />
              <FormLabel size="md" htmlFor="ch-settings-advanced-show-command-line" ml="0.5rem" mb="0">
                Toggle Command Line Suggestions
              </FormLabel>
            </FormControl>
          </Stack>
        </Stack>
      )}
      <Stack spacing="2rem">
        {isUserEntitledToDebugMode && (
          <Stack width="100%" spacing="1rem">
            <Stack spacing="0">
              <SectionHeader title="Enable Debugging" />
              <Text fontSize="xs" color="gray.500">
                When enabled, every command will include the debug entity, which will cause handlers to output debugging information.
              </Text>
            </Stack>
            <Stack justify="center">
              <FormControl display="flex" alignItems="center">
                <Switch
                  size="sm"
                  id="ch-settings-advanced-inject-debug-entity"
                  isChecked={Boolean(injectDebugEntityPreference)}
                  onChange={onSwitchChange("ui_inject_debug_entity")}
                />
                <FormLabel size="md" htmlFor="ch-settings-advanced-inject-debug-entity" ml="0.5rem" mb="0">
                  Toggle Debugging
                </FormLabel>
              </FormControl>
            </Stack>
          </Stack>
        )}
        {isUserEntitledToDebugMode && (
          <Stack width="100%" spacing="1rem">
            <Stack spacing="0">
              <SectionHeader title="Enable Test Mode" />
              <Text fontSize="xs" color="gray.500">
                When enabled, every command will include the test_mode entity, which will cause handlers to output run in test mode.
              </Text>
            </Stack>
            <Stack justify="center">
              <FormControl display="flex" alignItems="center">
                <Switch
                  size="sm"
                  id="ch-settings-advanced-inject-test-entity"
                  isChecked={Boolean(injectTestEntityPreference)}
                  onChange={onSwitchChange("ui_inject_test_entity")}
                />
                <FormLabel size="md" htmlFor="ch-settings-advanced-inject-test-entity" ml="0.5rem" mb="0">
                  Toggle Test Mode
                </FormLabel>
              </FormControl>
            </Stack>
          </Stack>
        )}
        {canEnableEnvBadge && (
          <Stack width="100%" spacing="1rem">
            <Stack spacing="0">
              <SectionHeader title="Show Environment Badge" />
              <Text fontSize="xs" color="gray.500">
                When enabled, the environment the application is currently connected to will be displayed in the top left corner.
              </Text>
            </Stack>
            <Stack justify="center">
              <FormControl display="flex" alignItems="center">
                <Switch
                  size="sm"
                  id="ch-settings-ui_show_env_badge"
                  isChecked={Boolean(showEnvBadgePreference)}
                  onChange={onSwitchChange("ui_show_env_badge")}
                />
                <FormLabel size="md" htmlFor="ch-settings-ui_show_env_badge" ml="0.5rem" mb="0">
                  Toggle Environment Badge
                </FormLabel>
              </FormControl>
            </Stack>
          </Stack>
        )}
        <Stack width="100%" spacing="1rem">
          <Stack spacing="0">
            <SectionHeader title="Delete All User Data" />
            <Text fontSize="xs" color="gray.500">
              This button will delete all your Charli data. This can not be reversed.
            </Text>
          </Stack>
          <Box ref={mainRef}>
            <Popover
              parentElement={mainRef?.current || undefined}
              isOpen={popoverIndex > 0}
              positions={["top"]}
              align="start"
              padding={10}
              reposition={false}
              onClickOutside={() => setPopoverIndex(-1)}
              content={() => (
                <Box {...commonTileProps} width="20rem" p="1.5rem">
                  <FormControl>
                    <Text pb="1rem" fontSize={"sm"}>
                      {willDeleteUser
                        ? `WARNING: This will delete all your Charli data AND delete your account.`
                        : `WARNING: This will delete all your Charli data. This can not be reversed.`}
                    </Text>
                    <Stack pb="2rem" direction="row" justify="space-between" fontSize={"sm"}>
                      <Text>Also delete account</Text>
                      <Tooltip
                        label={
                          hasActiveSubscription ? "You have an active subscription. Please contact support to delete your account" : ""
                        }
                        maxWidth="14rem"
                        aria-label="A tooltip">
                        <Switch isDisabled={hasActiveSubscription} size="sm" isChecked={willDeleteUser} onChange={toggleWillDeleteUser} />
                      </Tooltip>
                    </Stack>
                    <Button
                      variant="link"
                      colorScheme="red"
                      onClick={() => {
                        setPopoverIndex(-1);
                        deleteUser({ dataOnly: !willDeleteUser });
                      }}>
                      {`Confirm Delete Data${willDeleteUser ? " and Account" : ""}`}
                    </Button>
                  </FormControl>
                </Box>
              )}>
              <Button
                onClick={() => setPopoverIndex(popoverIndex > 0 ? -1 : 1)}
                colorScheme="red"
                isDisabled={isDeletePending}
                isLoading={isDeletePending}
                mb="2rem">
                Delete Data
              </Button>
            </Popover>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};
