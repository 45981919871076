import type { PlanName } from "api/subscription/models/PlanName";

export const CopiesKeys = [
  "copy_subscription_features",
  "copy_subscription_title",
  "copy_subscription_panel_list",
  "copy_subscription_plan",
  "copy_upgrade_modal_title",
  "copy_upgrade_modal_list",
  "copy_upgrade_modal_link",
  "copy_upgrade_modal_link_label",
  "copy_onboarding_modal_step1_title",
  "copy_onboarding_modal_step1_list",
  "copy_onboarding_modal_step1_footer_text",
  "copy_onboarding_modal_step1_button_label",
  "copy_onboarding_modal_step2_title",
  "copy_onboarding_modal_step2_subtitle_1",
  "copy_onboarding_modal_step3_title",
  "copy_onboarding_modal_step3_subtitle",
  "copy_login_list",
  "copy_maintenance_banner",
  "copy_maintenance_banner_bg_color",
  "copy_maintenance_banner_text_color",
] as const;
export type CopyTypedKey = typeof CopiesKeys[number];

export interface CopiesTypeMapping {
  copy_subscription_features: string[];
  copy_subscription_title: string;
  copy_subscription_panel_list: string[];
  copy_subscription_plan: PlanName;
  copy_upgrade_modal_title: string;
  copy_upgrade_modal_list: string[];
  copy_upgrade_modal_link: string;
  copy_upgrade_modal_link_label: string;
  copy_onboarding_modal_step1_title: string;
  copy_onboarding_modal_step1_list: string[];
  copy_onboarding_modal_step1_footer_text: string;
  copy_onboarding_modal_step1_button_label: string;
  copy_onboarding_modal_step2_title: string;
  copy_onboarding_modal_step2_subtitle_1: string;
  copy_onboarding_modal_step3_title: string;
  copy_onboarding_modal_step3_subtitle: string;
  copy_login_list: string[];
  copy_maintenance_banner: string;
  copy_maintenance_banner_bg_color: string;
  copy_maintenance_banner_text_color: string;
}

export type CopyValueMap = {
  [key in CopyTypedKey]: CopiesTypeMapping[key];
};

export type CopyValueType<K extends keyof CopyValueMap> = CopyValueMap[K];

export const defaultCopiesValues: CopiesTypeMapping = {
  copy_subscription_features: [
    "Supercharge analysis with FactSet trusted data including street accounts, fundamentals, M&A, transcripts and so much more | true | true",
    "Automatically fetch, read, and analyze complex EDGAR and SEDAR global filing information | true | true",
    "Augment research with sentiment analysis from verified news and internet content | true | true",
    "Full Autopilot analysis from ticker to report with a comprehensive editable report delivered right to your desk | true | true",
    "Number of Analysis (per ticker) | 3 | 20 per month",
    "Number of Private Q&A questions | 15 | 200 per month",
    "Data Archiving and Record Keeping | 30 Days | 180 Days",
  ],
  copy_subscription_title: "Upgrade to continue receiving the benefits of Autopilot",
  copy_subscription_panel_list: [
    "Trusted and verified AI results with data direct from FactSet",
    "Deeper insights with Multidimensional AI for better analysis and decisions",
  ],
  copy_subscription_plan: "professional",
  copy_upgrade_modal_title: "Upgrade to analyze your next equity!",
  copy_upgrade_modal_list: [
    "Allow AI to continue to put the market and data research on Autopilot so you can spend more time analyzing",
    "Avoid the endless hours sitting in front of a prompt and get AI to do the work while you commute, meet, call or even sleep",
  ],
  copy_upgrade_modal_link: "https://portal.productboard.com/charli/1-product-portal/tabs/2-planned",
  copy_upgrade_modal_link_label: "Click here to learn about new features coming soon!",
  copy_onboarding_modal_step1_title: "Welcome to The Automated Analyst",
  copy_onboarding_modal_step1_list: [
    "Analyze up to 3 different equities",
    "Get automated reports delivered right to your inbox",
    "Ask up to 15 questions to dive deeper into the analysis and discover new insights",
  ],
  copy_onboarding_modal_step1_footer_text: "Unlock $225 in value",
  copy_onboarding_modal_step1_button_label: "Get Started",
  copy_onboarding_modal_step2_title: "STEP 1 of 2: Enter a Ticker",
  copy_onboarding_modal_step2_subtitle_1:
    "Watch Charli going through thousands of pages of content, generating critical insights to improve the speed and effectiveness of investment decisions.",
  copy_onboarding_modal_step3_title: "STEP 2 of 2: Ask More Questions",
  copy_onboarding_modal_step3_subtitle:
    "Watch this short video to see how much more help you can get from Charli. Once Charli's analysis is complete, dig deeper by asking more questions.",
  copy_login_list: [
    "SlideImage1 | Equity Reports in Minutes | Automation that works while you commute, take a business meeting, or discuss the market with clients.",
    "SlideImage2 | Rapid Access to Trusted Data | Partnered with FactSet, TMX and Nasdaq to deliver comprehensive analysis of quantitative and qualitative data.",
    "SlideImage3 | Secure, Private and Compliant AI Research and Analysis | Purpose built for the financial services industry to ensure your data and interactions are secure and private, and can be fully audited.",
    "SlideImage4 | Fact-based Interactive Q&A | Not a prompt engineer?! That's ok, ask Charli questions and experience the power and capabilities of an interactive question and answer that is concentrated on your portfolio company.",
  ],
  copy_maintenance_banner: "A new admin panel for Delivery Copy is now available",
  copy_maintenance_banner_bg_color: "orange",
  copy_maintenance_banner_text_color: "gray.800",
};

export function isDefinedCopyKey(key: string): key is CopyTypedKey {
  return (CopiesKeys as readonly string[]).includes(key);
}
