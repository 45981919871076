import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Text,
  Stack,
  Center,
  InputLeftAddon,
  Box,
} from "@chakra-ui/react";
import type { PropsWithChildren } from "react";
import React, { useRef, useImperativeHandle, useContext, useEffect } from "react";
import { InputBarAttachmentButton } from "./InputBarAttachmentButton";
import { InputBarContext } from "./InputBarContext";
import { InputBarCommandLineSuggestions, InputBarInlineSuggestions } from "./suggestions";
import type { FollowUpIntentSource } from "./suggestions";
import { useInputBar } from "./useInputBar";
import { InputBarDropzoneContext } from "./InputBarDropzoneContext";
import { useCollection, useConfigMap, useEntitlementKey, useGetViewConfig, useProjectParams, useUserPreference } from "hooks";
import { useConversationContext } from "screens/thread/ConversationContext";
import { CharliLogoCircle } from "screens/thread/CharliLogoCircle";
import { ConversationDialogInput } from "screens/thread/ConversationDialogInput";

interface Props {
  conversationId?: string;
  collectionId?: string;
  size?: "md" | "lg";
  disableAttachments?: boolean;
  followUpIntentSource?: FollowUpIntentSource;
  inlineSuggestions?: string[];
  placeholderText?: string;
  showConversationModal?: boolean;
  onClick?: (inputText?: string) => void;
  showCharliIcon?: boolean;
}

export type InputBarRef = {
  setInputBarText: (text: string) => void;
};

export const InputBar = React.forwardRef<InputBarRef, PropsWithChildren<Props>>(
  (props: PropsWithChildren<Props>, ref: React.Ref<InputBarRef>) => {
    const {
      conversationId,
      collectionId,
      size = "md",
      disableAttachments: areAttachmentsDisabled = false,
      followUpIntentSource = { type: "list", intents: [] },
      inlineSuggestions,
      placeholderText,
      showConversationModal = false,
      onClick,
      children,
      showCharliIcon = false,
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);
    const {
      setMessageText,
      sendMessage,
      onSuggestionClick,
      onKeyDown,
      focusAndMoveCursorToEnd,
      messageText,
      isSendDisabled,
      isConnected,
      showCommandLineSuggestions,
    } = useInputBar({
      inputRef,
      conversationId,
      collectionId,
      areAttachmentsDisabled,
      showConversationModal,
    });

    useImperativeHandle(ref, () => ({
      setInputBarText: (text: string) => {
        setMessageText(text);
        focusAndMoveCursorToEnd();
      },
    }));
    const { parentRoute, projectFilter, projectId } = useProjectParams();
    const { isConversationOpen, onConversationClose, onConversationOpen, setIsUsingDefaultConversationDialog } = useConversationContext();
    const hideCommandLineButton = useUserPreference("ui_hide_command_line_button") || false;
    const hasCommandLine = useEntitlementKey("ui_enable_command_suggestions");
    const configMap = useConfigMap();
    const maybePortfolioProject = useCollection(projectFilter);
    const portfolioCollectionType = useGetViewConfig("portfolioProjectType", maybePortfolioProject?.collectionType, configMap);
    const collectionType =
      portfolioCollectionType && portfolioCollectionType?.length > 0
        ? portfolioCollectionType
        : parentRoute === "home"
        ? "due_diligence"
        : projectFilter;
    const { files } = useContext(InputBarDropzoneContext);
    const inputBgColor = useColorModeValue("white", "gray.800");
    const submitColor = useColorModeValue("primary.default", "gray.800");
    const inputBorderColor = useColorModeValue("gray.200", "gray.800");
    const bgInputColor = useColorModeValue("charli.lightBlue", "gray.800");
    const textColor = useColorModeValue("gray.500", "gray.800");

    useEffect(() => {
      setIsUsingDefaultConversationDialog(true);
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100);
    }, [inputRef, setIsUsingDefaultConversationDialog]);

    const onHandleConversation = () => {
      if (isConversationOpen) {
        onConversationClose();
      } else {
        onConversationOpen();
      }
    };

    return (
      <Stack position={"relative"}>
        {hasCommandLine && showCommandLineSuggestions && !hideCommandLineButton && (
          <Box pl={showCharliIcon ? "3rem" : "0"}>
            <InputBarCommandLineSuggestions
              messageText={messageText}
              onSuggestionClick={onSuggestionClick}
              followUpIntentSource={followUpIntentSource}
            />
          </Box>
        )}
        {hasCommandLine && inlineSuggestions && (
          <Box pl={showCharliIcon ? "3rem" : "0"}>
            <InputBarInlineSuggestions messageText={messageText} onSuggestionClick={onSuggestionClick} suggestions={inlineSuggestions} />
          </Box>
        )}
        <>
          {collectionType === "due_diligence" ? (
            <>
              <Box width="100%" className="conversation-dialog-autocomplete-input">
                <ConversationDialogInput
                  conversationId={conversationId}
                  inputId={"conversation-input"}
                  className="ch-question-input-conversation-dialog"
                  initialText={
                    projectId ? "Ask me a question about this project" : "Enter the ticker of a company you'd like to do due diligence on"
                  }
                />
              </Box>
              {!showCommandLineSuggestions && !hideCommandLineButton && hasCommandLine && (
                <Box
                  backgroundColor={bgInputColor}
                  position={"absolute"}
                  ml="3.1rem"
                  bottom={projectId ? "-0.3rem" : "3rem"}
                  pt="5px"
                  borderRadius={"md"}
                  px="10px">
                  <Text color={textColor} fontSize="xs" fontWeight="light">
                    enter / to show commands
                  </Text>
                </Box>
              )}
            </>
          ) : (
            <InputGroup>
              <InputLeftAddon
                backgroundColor={inputBgColor}
                height={size === "lg" ? "3rem" : "2.5rem"}
                paddingInline={"0"}
                cursor="pointer"
                borderRadius={showCharliIcon ? "30px 0px 0px 30px!important" : "5px 0px 0px 5px!important"}
                children={
                  <Stack direction="row">
                    {showCharliIcon && <CharliLogoCircle style={{ zIndex: 10 }} onClick={onHandleConversation} />}
                    {!areAttachmentsDisabled && (
                      <Center width="100%" borderRadius={showCharliIcon ? "30px 0px 0px 30px!important" : "5px 0px 0px 5px!important"}>
                        <InputBarAttachmentButton size={size} />
                      </Center>
                    )}
                  </Stack>
                }
              />
              <Input
                paddingInlineStart={".5rem"}
                borderRadius={showCharliIcon ? "0px 30px 30px 0px!important" : "0px 5px 5px 0px!important"}
                aria-autocomplete="both"
                className={"charli-input " + (files.length > 0 ? "ch-input-bar-with-attachments" : "ch-input-bar-without-attachments")}
                ref={inputRef}
                backgroundColor={inputBgColor}
                placeholder={placeholderText ? placeholderText : `Reply to Charli...`}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setMessageText(event.target.value)}
                value={messageText}
                onKeyDown={onKeyDown}
                size={size}
                borderColor={inputBorderColor}
                borderWidth={size === "lg" ? "3px" : "1px"}
                borderLeftWidth="0"
              />
              <InputRightElement
                width={size === "lg" ? "3rem" : "2.5rem"}
                height={size === "lg" ? "3rem" : "2.5rem"}
                children={
                  <IconButton
                    aria-label="submit"
                    color={submitColor}
                    size="sm"
                    backgroundColor="transparent"
                    _hover={{ fontWeight: "bold" }}
                    icon={<ArrowForwardIcon width="1.5rem" height="1.5rem" />}
                    isDisabled={!isConnected || isSendDisabled}
                    onClick={() => {
                      if (onClick) onClick(messageText);
                      sendMessage(messageText);
                    }}
                  />
                }
              />
            </InputGroup>
          )}
        </>

        <InputBarContext.Provider value={{ setMessageText, focusAndMoveCursorToEnd, showTips: isSendDisabled, messageText }}>
          {children}
        </InputBarContext.Provider>
      </Stack>
    );
  }
);
