import { Button, Center, Stack, Text, Image, Icon, Tbody, Td, Tr, Table, HStack, useBreakpointValue, Box } from "@chakra-ui/react";
import { useButtonProps, useUserProfile, useUserSubscriptionDetails, useUserSubscriptionDetailsDaysTillNextBilling } from "hooks";
import React, { useMemo } from "react";
import verifiedShield from "screens/common/static/misc/verified_shield.svg";
import soc2logo from "screens/common/static/logos/soc2_logo.png";
import { FaCheck } from "react-icons/fa";
import { useWizard } from "react-use-wizard";
import { track } from "api/analytics";
import { USER_UPGRADE_PLAN_CONTINUE } from "api/analytics/events";
import { useFeatureUsage } from "hooks/useFeatureUsage";
import { Intent } from "types/intent";
import { useLocation } from "react-router-dom";
import { useCopyValue } from "hooks/useCopies";
import nasdaqLogo from "screens/common/static/logos/nasdaq_logo_white.svg";
import tmxLogo from "screens/common/static/logos/tmx_logo_white.svg";

export interface Feature {
  name: string;
  trial: boolean | string;
  business: boolean | string;
}

export const SubscriptionUpgradeFeatures = () => {
  const buttonStyle = useButtonProps("lg", "subscribe");
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { nextStep } = useWizard();
  const { email, fullName } = useUserProfile();
  const subscriptionInfo = useUserSubscriptionDetails();
  const daysTillPlanExpiry = useUserSubscriptionDetailsDaysTillNextBilling();
  const answerUsage = useFeatureUsage(Intent.generateAnswer);
  const projectUsage = useFeatureUsage(Intent.createDueDiligenceProject);
  const { pathname } = useLocation();
  const rawCopySubscriptionFeatures = useCopyValue("copy_subscription_features");
  const featureList = useMemo(
    () =>
      rawCopySubscriptionFeatures?.flatMap((feature) => {
        const [name, trial, business] = feature.split("|").map((part) => part.trim());
        return name && trial && business ? [{ name, trial, business }] : [];
      }),
    [rawCopySubscriptionFeatures]
  );

  const handleOnClick = () => {
    track(USER_UPGRADE_PLAN_CONTINUE, {
      pageViewed: pathname,
      userName: fullName,
      userEmail: email,
      answersUsed: answerUsage?.used,
      dueDiligenceUsed: projectUsage?.used,
      currentPlan: subscriptionInfo.plan,
      planStatus: subscriptionInfo.status,
      nextBillingDate: subscriptionInfo.nextBillingDate,
      subscriptionId: subscriptionInfo.subscriptionId,
      daysTillPlanExpiry: daysTillPlanExpiry.daysTillNextBilling,
    });
    nextStep();
  };

  return (
    <Stack justifyContent={"space-between"} height="100%" minHeight={"40rem"} spacing="0" width="100%">
      <Stack spacing="0" height="100%" justifyContent={"space-between"}>
        <Stack height="100%" spacing="1rem" bgColor={"primary.default"} py="2rem" color="white">
          <Center px={isMobile ? "1rem" : "5rem"}>
            <Text fontWeight={"semibold"} fontSize="xl">
              Step 1 of 2: Upgrade To Professional
            </Text>
          </Center>
          <Text fontSize="md" width="100%" textAlign="center" px={isMobile ? "1rem" : "5rem"}>
            Get started with the Automated Analyst for Only{" "}
            <Text fontWeight={"semibold"} as="span" color="#ffc070">
              USD $125
            </Text>{" "}
            /month.
          </Text>
          <Text fontSize="md" width="100%" textAlign="center" px={isMobile ? "1rem" : "5rem"}>
            For our Business, Premium and Enterprise subscriptions, including access to FactSet data, please press{" "}
            <Text
              cursor="pointer"
              fontWeight={"semibold"}
              as="span"
              color="#ffc070"
              onClick={() => window.open("https://charliai.com/pricing/", "_blank")}>
              here
            </Text>{" "}
            for more details.
          </Text>
          <Stack direction={"row"} justifyContent="end" px={isMobile ? ".5rem" : "1rem"}>
            <HStack align="center" spacing="1rem">
              <Image src={nasdaqLogo} height="2rem" alt="NASDAQ Logo" />
              <Image src={tmxLogo} height="2.5rem" alt="TMX Logo" />
            </HStack>
          </Stack>
        </Stack>
        <Box p="2rem">
          <Table variant="simple" size="sm">
            <Tbody>
              {featureList.map((feature, index) => (
                <Tr fontSize={"sm"} key={index}>
                  <Td paddingInline={"0"}>{feature.name}</Td>
                  <Td textAlign={"end"} width="8rem" color="green.600">
                    {feature.business === "true" ? <Icon as={FaCheck} /> : feature.business}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Stack>
      <Stack>
        <Center pb="2rem">
          <Button
            {...buttonStyle}
            color="white"
            fontWeight={"normal"}
            bgColor={"#81c34b"}
            borderColor={"#81c34b"}
            width="16rem"
            borderRadius="full"
            onClick={handleOnClick}>
            Continue
          </Button>
        </Center>
      </Stack>
      {!isMobile && (
        <HStack position="absolute" bottom="2rem" right="2rem" align={"center"} spacing="1rem">
          <Image src={soc2logo} height="2.7rem" />
          <Image src={verifiedShield} height="2.5rem" />
        </HStack>
      )}
    </Stack>
  );
};
