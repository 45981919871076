import React, { useEffect, useMemo, useState } from "react";
import type { FunctionComponent } from "react";
import { CustomStat } from "screens/landing/tabs/collections/chartComponents/CustomStat";
import { useCollectionCancelWorkflowId, useMainCollectionWorkflowId } from "hooks";
import { Text, Stack, Icon, Tooltip } from "@chakra-ui/react";
import { TfiTimer } from "react-icons/tfi";
import { IoCalendarClearOutline } from "react-icons/io5";
import { useWorkflowKey, useWorkflowStatus } from "hooks/useWorkflows";
import { calculateTimeDifference } from "./utils";

interface Props {
  collectionId: string;
  size?: "xxs" | "xs" | "sm" | "md";
  hideIcon?: boolean;
  showTimeDetails?: boolean;
  onClick?: () => void;
}

export const CollectionStatProgress: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  collectionId,
  size = "md",
  hideIcon,
  showTimeDetails,
  onClick,
}) => {
  const workflowId = useMainCollectionWorkflowId(collectionId);
  const canceledWorkflowId = useCollectionCancelWorkflowId(collectionId);
  const creationDate = useWorkflowKey(workflowId, "creationDate");
  const completionDate = useWorkflowKey(workflowId, "completionDate");
  const workflowStatus = useWorkflowStatus(workflowId);

  const currentWorkflowCreatedDate = useWorkflowKey(workflowId, "creationDate");
  const canceledWorkflowDate = useWorkflowKey(canceledWorkflowId, "completionDate");

  const [workflowDuration, setWorkflowDuration] = useState<string>("--:--");

  const workflowStatusLabel = useMemo(() => {
    if (!workflowStatus) {
      return "-";
    } else if (workflowStatus === "failed_checkstop") {
      return "Paused".toUpperCase();
    } else {
      return workflowStatus.replace(/_/g, " ").toUpperCase();
    }
  }, [workflowStatus]);

  useEffect(() => {
    if (!creationDate) return;

    if (workflowStatus !== "in_progress") {
      const endDate = completionDate ? new Date(completionDate) : canceledWorkflowDate ? new Date(canceledWorkflowDate) : new Date();
      setWorkflowDuration(calculateTimeDifference(new Date(creationDate), endDate));
      return;
    }

    const initDate = (() => {
      if (new Date(creationDate).getTime() > new Date().getTime()) {
        return new Date();
      } else {
        return new Date(creationDate);
      }
    })();

    const interval = setInterval(() => {
      setWorkflowDuration(calculateTimeDifference(initDate, new Date()));
    }, 1000);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [completionDate, canceledWorkflowDate, creationDate, workflowStatus]);

  return (
    <Stack spacing="3px" className="ch-project-workflow-status-indicator">
      <CustomStat {...(onClick && { onClick })} label={workflowStatusLabel} value={workflowStatus} size={size} hideIcon={hideIcon} />
      {showTimeDetails && (
        <Stack fontWeight={"light"} fontSize="11px" width="100%" align="center" spacing="0px">
          <Stack direction="row" spacing="3px" align="center">
            <Icon as={TfiTimer} /> <Text>{workflowDuration}</Text>
          </Stack>
          <Tooltip
            label={`Project completed${currentWorkflowCreatedDate ? ` at ${new Date(currentWorkflowCreatedDate).toLocaleString()}` : "."}`}
            aria-label="Full date and time"
            maxWidth={"11rem"}
            placement="top">
            <Stack direction="row" spacing="3px" align="center">
              <Icon as={IoCalendarClearOutline} />
              {currentWorkflowCreatedDate && <Text>{new Date(currentWorkflowCreatedDate).toLocaleDateString()}</Text>}
            </Stack>
          </Tooltip>
        </Stack>
      )}
    </Stack>
  );
};
