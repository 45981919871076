import React from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import { CommonModal } from "./CommonModal";
import { Wizard } from "react-use-wizard";
import { SubscriptionUpgradeConfirm } from "screens/panels/settings/tabs/subscription/SubscriptionUpgradeConfirm";
import { SubscriptionUpgradeFeatures } from "screens/panels/settings/tabs/subscription/SubscriptionUpgradeFeatures";

export const SubscriptionModal = () => {
  const { isSubscriptionModalOpen, onSubscriptionModalClose } = useConversationContext();

  return (
    <CommonModal
      borderColor="#4799d4"
      minWidth={["90%", "60rem", "47rem"]}
      isOpen={isSubscriptionModalOpen}
      onClose={onSubscriptionModalClose}
      showCloseButton
      modalBody={
        <Wizard>
          <SubscriptionUpgradeFeatures />
          <SubscriptionUpgradeConfirm upgradePlan={"business"} />
        </Wizard>
      }
    />
  );
};
